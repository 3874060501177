import { Box, Typography } from '@mui/material';
import { Orders } from '@hellohair/types';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../../../../theme/styles.helpers';
import { OrderDetailsSurveyStyles } from './OrderDetailsSurvey.Styles';

interface MyProps {
  templateId: string;
  assets: Orders.SurveyAsset[];
  qanda: Orders.SurveyAnswer[];
}

export const OrderDetailsSurvey: FC<MyProps> = ({ assets, qanda }) => {
  const { t } = useTranslation();

  const survey = (_qanda: Orders.SurveyAnswer[]) => {
    const _filteredQanda = _qanda.filter(
      (item) => item.type !== 'UploadImages' && item.type !== 'Finish' && item.type !== 'Welcome'
    );

    return (
      <Box>
        {_filteredQanda.map((q, id) => {
          return (
            <Typography key={id} variant="body2" marginBottom={4} sx={{ wordWrap: 'break-word' }}>
              <strong>{q.question}</strong>
              <br />
              {q.answer}
              {/* {q.question.substring(0,20)}::{q.answer.substring(0,20)}::{q.type.substring(0,20)} */}
            </Typography>
          );
        })}
      </Box>
    );
  };
  const styles = useStyles(OrderDetailsSurveyStyles, {});
  return (
    <Box sx={styles.container}>
      <Box sx={styles.survey}>
        <Typography variant="h5" marginBottom={3}>
          {t('Survey')}
        </Typography>
        {survey(qanda)}
      </Box>

      <Box>
        <Typography variant="h5" marginY={3}>
          {t('Uploads')}
        </Typography>

        <Box sx={styles.imgContainer}>
          {assets
            .filter((f) => f.category == 'IMAGE')
            .map(
              (m) =>
                m && (
                  <Box key={m.key}>
                    <Typography variant="body2">
                      {m.category}:{m.key.substring(0, 5)}
                    </Typography>
                    {m.thumbnail && <img width={'100%'} style={{ objectFit: 'contain' }} src={m.thumbnail} />}
                  </Box>
                )
            )}
        </Box>
      </Box>
    </Box>
  );
};
