import { Box, Typography } from '@mui/material';
import { FC, createRef, useContext, useState } from 'react';
import { CustomModal } from '../../components/modal/CustomModal';
import { AuthContext, AuthState } from '../../aws/AuthProvider';
import { ROUTE } from '../routes';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../../theme/styles.helpers';
import { ConsultationsStyles } from './Consultations.Styles';
import { useRedirectOnAuth } from '../../aws/AuthHooks';
import { DataContext } from '../../aws/DataProvider';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import deLocale from '@fullcalendar/core/locales/de';
import enLocale from '@fullcalendar/core/locales/en-gb';
import { EventClickArg, EventInput } from '@fullcalendar/core';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import ConsultationForm from '../../components/modals/consultation-form/Consultation-Form';
import { placeConsultation } from '../../aws/ConsultationApi';
import ConsultationDeleteForm from '../../components/modals/consultation-form/ConsultationDelete-Form';

const Consultations: FC = () => {
  useRedirectOnAuth([AuthState.UNAUTHORIZED], ROUTE.LOGIN);
  const { apiClient } = useContext(AuthContext);

  const [isCreateModalOpen, setIsCreateModalOpen] = useState<DateClickArg | undefined>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<EventClickArg | undefined>();
  const { t, i18n } = useTranslation();
  const styles = useStyles(ConsultationsStyles, {});

  // data
  const {
    consultationsState: [consultations],
  } = useContext(DataContext);

  // events
  const [events, setEvents] = useState<EventInput[]>(
    consultations.map((c) => ({
      id: c.id,
      title: c.description,
      start: c.start.dateTime,
      end: c.end.dateTime,
      summary: c.summary,
      backgroundColor: c.summary === 'C:FREE' ? 'green' : c.summary === 'C:LOCKED' ? 'black' : 'red',
    }))
  );

  const addEvent = (e: EventInput) => {
    setEvents((prev) => [...prev, e]);
  };

  const removeEvent = (id: string) => {
    setEvents((prev) => prev.filter((e) => e.id !== id));
  };

  const calendar = createRef<FullCalendar>();

  return (
    <Box sx={styles.container}>
      <Box sx={styles.header}>
        <Typography variant="h3">{t('Consultations')}</Typography>
      </Box>
      <Box sx={styles.calendar}>
        <FullCalendar
          ref={calendar}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          locale={i18n.language === 'de' ? deLocale : enLocale}
          weekends={false}
          eventClick={setIsDeleteModalOpen}
          dateClick={setIsCreateModalOpen}
          events={events}
          eventTimeFormat={{
            hour: '2-digit',
            minute: '2-digit',
            meridiem: false,
          }}
        />
      </Box>

      <CustomModal
        variant="medium"
        isModalOpen={isCreateModalOpen !== undefined}
        onModalClose={() => setIsCreateModalOpen(undefined)}
      >
        <ConsultationForm
          date={isCreateModalOpen!} // eslint-disable-line @typescript-eslint/no-non-null-assertion
          onSubmit={async (e) => {
            const _e = await placeConsultation(new Date(Number(e.date?.valueOf() ?? 0)).toISOString(), 15, apiClient);
            if (_e) {
              addEvent({
                ...e,
                id: _e.id,
                summary: 'C:FREE',
                backgroundColor: 'yellow',
              });
              setIsCreateModalOpen(undefined);
            }
          }}
          onModalClose={() => setIsCreateModalOpen(undefined)}
        />
      </CustomModal>
      <CustomModal
        variant="small"
        isModalOpen={isDeleteModalOpen !== undefined}
        onModalClose={() => setIsDeleteModalOpen(undefined)}
      >
        <ConsultationDeleteForm
          event={isDeleteModalOpen!}
          removeEvent={removeEvent}
          onModalClose={() => setIsDeleteModalOpen(undefined)}
        />
      </CustomModal>
    </Box>
  );
};

export default Consultations;
