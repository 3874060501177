import { Components } from '@mui/material/styles';
import { palette } from '../palette';

const MuiFormLabel: Components = {
  MuiFormLabel: {
    styleOverrides: {
      root: {
        '&.Mui-focused': {
          color: palette.text.secondary,
        },
      },
    },
  },
};

export default MuiFormLabel;
