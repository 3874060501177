import { TypographyOptions } from '@mui/material/styles/createTypography';
import { palette } from './palette';

export const typography: TypographyOptions = {
  fontFamily: ['Outfit', 'Roboto', 'Helvetica'].join(','),
  h1: {
    fontSize: '64px',
    fontWeight: '500',
  },
  h2: {
    fontSize: '32px',
    fontWeight: '500',
    lineHeight: '40px',
  },
  h3: {
    fontSize: '15px',
    fontWeight: '700',
    lineHeight: '100%',
    letterSpacing: '1.2pt',
    textTransform: 'uppercase',
  },
  h4: {
    fontSize: '19px',
    fontWeight: '500',
    lineHeight: '26px',
  },
  h5: {
    fontSize: '16px',
    lineHeight: '26px',
    fontWeight: '500',
  },
  h6: {
    fontSize: '9px',
    lineHeight: '9px',
    fontWeight: '400',
  },
  body1: {
    fontSize: '16px',
  },
  body2: {
    fontSize: '16px',
    color: palette.text.secondary,
  },
  caption: {
    fontSize: '9px',
    lineHeight: '12px',
    color: palette.text.secondary,
  },
};
