import { Box, CircularProgress, Typography } from '@mui/material';
import { CustomDataGrid } from '../../data-grid/CustomDataGrid';
import React, { useContext, useEffect, useState } from 'react';
import { GridRowParams } from '@mui/x-data-grid';
import { Orders } from '@hellohair/types';
import { getOrderHistoryDetails, listOrderRequestsForUser } from '../../../aws/RequestsApi';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../aws/AuthProvider';
import { useStyles } from '../../../theme/styles.helpers';
import { UserRequestHistoryStyles } from './UserRequestHistory.Styles';

interface ListRow {
  id: string;
  product: string;
  date: Date;
  order: Orders.UserOrder;
}

type Props = {
  customerId: string;
};

const BillbeeOrderStates: { [key: number]: string } = {
  1: 'Bestellt',
  2: 'Bestätigt',
  3: 'Bezahlt',
  4: 'Versandt',
  5: 'Reklamation',
  6: 'Gelöscht',
  7: 'Abgeschlossen',
  8: 'Storniert',
  9: 'Archiviert',
  11: 'Mahnung 1',
  12: 'Mahnung 2',
  13: 'Verpackt',
  14: 'Angeboten',
  15: 'Zahlungserinnerung',
};

const OrderHistory = ({ customerId }: Props) => {
  const { t } = useTranslation();
  const { apiClient } = useContext(AuthContext);
  const styles = useStyles(UserRequestHistoryStyles, {});
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [details, setDetails] = useState<Orders.UserOrderHistory>();
  const [orders, setOrders] = useState<Orders.UserOrder[]>([]);
  const [loadingOrderHistory, setLoadingOrderHistory] = useState(true);

  useEffect(() => {
    listOrderRequestsForUser(customerId, apiClient, 'network-only').then((res) => {
      setLoadingOrderHistory(false);
      setOrders(res);
    });
  }, []);

  const rows = orders.map((r) => ({
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    id: (r as any).orderId,
    product: r.items.map((i) => i.description).join(', '),
    date: r.createdAt ? new Date(r.createdAt) : new Date(r.created),
    order: r,
  }));

  const handleRowClicked = (params: GridRowParams) => {
    const { order } = params.row as ListRow;
    setLoadingDetails(true);
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    getOrderHistoryDetails(customerId, (order as any).orderId, apiClient, 'network-only')
      .then(setDetails)
      .finally(() => setLoadingDetails(false));
  };

  const columns = [
    { field: 'product', headerName: t('Order'), filterable: false, flex: 1 },
    { field: 'date', headerName: t('Date'), type: 'date', filterable: false, flex: 1 },
  ];

  if (loadingOrderHistory) {
    return (
      <Box sx={styles.grid} className="OrderDetails-grid">
        <CircularProgress size={32} sx={styles.spinner} thickness={5} />
      </Box>
    );
  }

  return (
    <Box sx={styles.grid} className="OrderDetails-grid">
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, width: '50%' }}>
        <CustomDataGrid
          columns={columns}
          rows={rows}
          onRowClick={handleRowClicked}
          getRowId={(row) => row.id}
          isCellEditable={() => false}
          pagination
          autoPageSize
        />
      </Box>
      <Box sx={styles.detailsPane}>
        {loadingDetails ? (
          <CircularProgress size={32} sx={styles.spinner} thickness={5} />
        ) : (
          details && (
            <>
              <Box marginBottom={4}>
                <Box display={'flex'} justifyContent={'space-between'}>
                  <Typography fontWeight="bold">{details.isSubscription ? 'Abonnement:' : 'Bestellung'}</Typography>
                  <Typography textAlign="right">{details.subscriptionStatus}</Typography>
                </Box>

                <Box display={'flex'} justifyContent={'space-between'}>
                  <Typography fontWeight="bold">{t('Preis')}:</Typography>
                  <Typography textAlign="right">{details.amount.toFixed(2)}€</Typography>
                </Box>

                <Box display={'flex'} justifyContent={'space-between'}>
                  <Typography fontWeight="bold">DHL Sendungsnummer:</Typography>
                  <Typography textAlign="right">{details.shippingIds.map((s) => s.shippingId).join(', ')}</Typography>
                </Box>

                <Box display={'flex'} justifyContent={'space-between'}>
                  <Typography fontWeight="bold">Status:</Typography>
                  <Typography textAlign="right">{BillbeeOrderStates[details.billbeeState]}</Typography>
                </Box>
              </Box>

              {details.history.map((h, i) => (
                <Box key={i} display={'flex'} justifyContent={'space-between'}>
                  <Typography fontWeight="bold">{t(h.event)}</Typography>
                  <Typography textAlign="right">{new Date(h.time).toLocaleString()}</Typography>
                </Box>
              ))}
            </>
          )
        )}
      </Box>
    </Box>
  );
};

export default OrderHistory;
