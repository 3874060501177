import { Box, Typography } from '@mui/material';
import { Orders } from '@hellohair/types';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface MyProps {
  product: Orders.OrderProduct;
  orderDate: number;
  isSubscription?: boolean;
}

export const OrderDetailsProduct: FC<MyProps> = (props) => {
  const { product, orderDate, isSubscription = false } = props;
  const { t } = useTranslation();
  return (
    <Box marginBottom={4}>
      <Typography variant="h5" marginBottom={3}>
        {isSubscription ? 'Abonnement' : 'Bestellung'}
      </Typography>
      <Typography variant="body2" component="span">
        {t('Product')}:
      </Typography>{' '}
      <Typography variant="body2" fontWeight="bold" component="span">
        {product.productName}
      </Typography>
      <br />
      <Typography variant="body2" component="span">
        {t('OrderReceived')}:
      </Typography>{' '}
      <Typography variant="body2" fontWeight="bold" component="span">
        {new Date(orderDate).toLocaleString()}
      </Typography>
    </Box>
  );
};
