import { Components } from '@mui/material/styles';

const MuiCheckboxOverrides: Components = {
  MuiCheckbox: {
    styleOverrides: {
      root: {
        '&.Mui-checked': {
          color: 'black',
        },
      },
    },
  },
};

export default MuiCheckboxOverrides;
