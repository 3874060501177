import { Box, Modal } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { useStyles } from '../../theme/styles.helpers';
import { CustomModalStyles } from './CustomModal.Styles';

interface CustomModalProps {
  variant: 'small' | 'medium' | 'large';
  isModalOpen: boolean;
  onModalClose: () => void;
}

export const CustomModal: FC<PropsWithChildren<CustomModalProps>> = ({
  children,
  variant = 'large',
  isModalOpen,
  onModalClose,
}) => {
  const styles = useStyles(CustomModalStyles, { variant });

  return (
    <Modal
      open={isModalOpen}
      onClose={onModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      componentsProps={{
        root: {
          style: {
            display: 'flex',
          },
        },
        backdrop: {
          style: {
            background: 'rgba(0,0,0,0.75)',
          },
        },
      }}
    >
      <Box sx={styles.container}>{children}</Box>
    </Modal>
  );
};
