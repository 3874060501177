import { ComponentStylesWithFns, StyleStates } from '../../theme/styles.helpers';

interface CustomModalStylesProps extends StyleStates {
  variant: 'small' | 'medium' | 'large';
}

export const CustomModalStyles: ComponentStylesWithFns<CustomModalStylesProps> = {
  container: ({ variant }) => [
    {
      margin: 'auto',
      height: '100%',
      width: '100%',
      overflowX: 'hidden',
    },
    variant === 'small' && {
      minHeight: '200px',
      height: '30vh',
      width: '40vw',
      maxWidth: '400px',
    },
    variant === 'medium' && {
      minHeight: '400px',
      height: '50vh',
      width: '60vw',
      maxWidth: '600px',
    },
    variant === 'large' && {
      minHeight: '500px',
      height: '80vh',
      width: '80vw',
      maxWidth: '950px',
    },
  ],
};
