import { Components } from '@mui/material/styles';

const MuiOutlinedInput: Components = {
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        '.MuiOutlinedInput-input': {
          background: '#ffffff',
          border: '0.5px solid #e0e0e0',
          boxShadow: '0px 0px 29px rgba(0, 0, 0, 0.05)',
          borderRadius: '30px !important',
          height: '60px',
          lineHeight: '60px',
          padding: '0 20px',
          boxSizing: 'border-box',
          fontSize: '18px',
          color: 'black',
        },
        '.MuiOutlinedInput-notchedOutline': {
          border: 0,
        },
      },
    },
  },
};

export default MuiOutlinedInput;
