import { Auth } from 'aws-amplify';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';

// FIXME: Missing library export
interface IdValue {
  type: 'id';
  id: string;
  generated: boolean;
  typename: string | undefined;
}
interface JsonValue {
  type: 'json';
  json: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}
declare type ListValue = Array<null | IdValue>;
declare type StoreValue =
  | number
  | string
  | string[]
  | IdValue
  | ListValue
  | JsonValue
  | null
  | undefined
  | void
  | Object; //eslint-disable-line @typescript-eslint/ban-types
interface StoreObject {
  __typename?: string;
  [storeFieldKey: string]: StoreValue;
}
export interface NormalizedCacheObject {
  [dataId: string]: StoreObject;
}

/**
 * AWS Client
 *
 */

export async function configureAWS(): Promise<void> {
  const config = {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_WEB_CLIENT_ID,
  };

  Auth.configure(config);
}

export async function initGraphQLApiClient(): Promise<AWSAppSyncClient<NormalizedCacheObject>> {
  const graphQlClient = new AWSAppSyncClient({
    url: process.env.REACT_APP_AWS_GRAPHQL_ENDPOINT as string,
    region: process.env.REACT_APP_AWS_REGION as string,
    auth: {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: async () => (await Auth.currentSession()).getAccessToken().getJwtToken(),
    },
    disableOffline: true,
  });

  return await graphQlClient.hydrated();
}

export async function initGraphQLApiClientExtern(): Promise<AWSAppSyncClient<NormalizedCacheObject>> {
  const graphQlClient = new AWSAppSyncClient({
    url: process.env.REACT_APP_AWS_GRAPHQL_ENDPOINT as string,
    region: process.env.REACT_APP_AWS_REGION as string,
    auth: {
      type: AUTH_TYPE.API_KEY,
      apiKey: process.env.REACT_APP_AWS_API_KEY as string,
    },
    disableOffline: true,
  });

  return await graphQlClient.hydrated();
}
