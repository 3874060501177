import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { FC, useContext, useState } from 'react';
import './SurveySingle.styles';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import styles from './SurveySingle.styles';
import Confirmation from '../../../../components/legacy/Questions/Confirmation/Confirmation';
import Finish from '../../../../components/legacy/Questions/Finish/Finish';
import MultiSelectAnswer from '../../../../components/legacy/Questions/MultiSelectAnswer/MultiSelectAnswer';
import SingleSelectAnswer from '../../../../components/legacy/Questions/SingleSelectAnswer/SingleSelectAnswer';
import Welcome from '../../../../components/legacy/Questions/Welcome/Welcome';
import { Templates, Utils } from '@hellohair/types';
import { DataContext } from '../../../../aws/DataProvider';
import { useTranslation } from 'react-i18next';
import Intro from '../../../../components/legacy/Questions/Intro/Intro';
import Legal from '../../../../components/legacy/Questions/Legal/Legal';
import SelectFinish from '../../../../components/legacy/Questions/SelectFinish/SelectFinish';

interface SurveySingleProps {
  className?: string;
}

const SurveySingle: FC<SurveySingleProps> = () => {
  const {
    formDetailsState: [surveyState, setCurrentSurvey],
  } = useContext(DataContext);

  const { t } = useTranslation();

  const [newType, setNewType] = useState<string>('Welcome');
  const [localQuestion, setLocalQuestion] = useState('');

  const addQuestion = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newId = surveyState!.steps.length > 0 ? Math.max(...surveyState!.steps.map((o: any) => o.id)) + 1 : 1;
    const newData = [...surveyState!.steps];
    newData.push({
      id: newId,
      question: localQuestion,
      type: newType,
    } as Templates.StepData);
    setCurrentSurvey({ ...surveyState, steps: newData } as Utils.DBItem<Templates.SurveyTemplate>);
    setLocalQuestion('');
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return [...result];
  };

  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;
    const newItems = reorder(surveyState!.steps, source.index, destination.index);

    setCurrentSurvey({ ...surveyState, steps: newItems } as Utils.DBItem<Templates.SurveyTemplate>);
  };

  return (
    <Box className="SingleSurveyContent-root" sx={styles.root}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-list">
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any*/}
          {(provided: any) => (
            <Box ref={provided.innerRef} {...provided.droppableProps} sx={{ overflowY: 'auto' }}>
              {surveyState &&
                surveyState.steps &&
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                surveyState?.steps.map((step: any, i: number) =>
                  step?.type === 'Welcome' ? (
                    <Welcome key={step.id} survey={surveyState} index={i} />
                  ) : step?.type === 'SingleSelectAnswer' ? (
                    <SingleSelectAnswer key={step.id} survey={surveyState} index={i} />
                  ) : step?.type === 'MultiSelectAnswer' ? (
                    <MultiSelectAnswer key={step.id} survey={surveyState} index={i} />
                  ) : step?.type === 'Confirmation' ? (
                    <Confirmation key={step.id} survey={surveyState} index={i} />
                  ) : step?.type === 'Finish' ? (
                    <Finish key={step.id} survey={surveyState} index={i} />
                  ) : step?.type === 'SelectFinish' ? (
                    <SelectFinish key={step.id} survey={surveyState} index={i} />
                  ) : step?.type === 'Intro' ? (
                    <Intro key={step.id} survey={surveyState} index={i} />
                  ) : step?.type === 'Legal' ? (
                    <Legal key={step.id} survey={surveyState} index={i} />
                  ) : null
                )}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      <Box>
        <TextField
          label="Question"
          fullWidth
          value={localQuestion}
          onChange={(e) => setLocalQuestion(e.target.value)}
          size="small"
          margin="dense"
        />
        <FormControl fullWidth margin="dense">
          <InputLabel id="labelType">Type</InputLabel>
          <Select
            labelId="labelType"
            value={newType}
            label="Type"
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(e: any) => setNewType(e.target.value)}
            fullWidth
            size="small"
          >
            <MenuItem value="Intro">{t('Intro')}</MenuItem>
            <MenuItem value="Legal">{t('Legal')}</MenuItem>
            <MenuItem value="Welcome">{t('Welcome')}</MenuItem>
            <MenuItem value="SingleSelectAnswer">{t('SingleSelectAnswer')}</MenuItem>
            <MenuItem value="MultiSelectAnswer">{t('MultiSelectAnswer')}</MenuItem>
            <MenuItem value="Confirmation">{t('Confirmation')}</MenuItem>
            <MenuItem value="Finish">{t('Finish')}</MenuItem>
            <MenuItem value="SelectFinish">{t('SelectFinish')}</MenuItem>
          </Select>
        </FormControl>
        <Button onClick={addQuestion} fullWidth>
          <Typography variant="h3">{t('Add Question')}</Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default SurveySingle;
