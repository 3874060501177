import { GraphQLResult } from '@aws-amplify/api';
import FetchPolicy from 'apollo-client/core/watchQueryOptions';
import AWSAppSyncClient from 'aws-appsync';
import { NormalizedCacheObject } from './aws.config';
import {
  CREATE_CUSTOMER,
  DELETE_CUSTOMER,
  DISABLE_CUSTOMER,
  ENABLE_CUSTOMER,
  UPDATE_CUSTOMER,
} from './graphql/mutations';
import { LIST_CUSTOMERS } from './graphql/queries';

export type CustomerAddInput = {
  name: string;
  description: string;
  type: string;
  source?: string;
};

export type CustomerUpdateInput = {
  customerId: string;
  name: string;
  description: string;
  type: string;
  source?: string;
};

export async function listCustomers(
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>,
  fetchPolicy: FetchPolicy.FetchPolicy = 'cache-first'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any[]> {
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    // FIXME: type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const _customers = await apiClient.query<{ listCustomers: any[] }>({
      query: LIST_CUSTOMERS,
      fetchPolicy: fetchPolicy,
    });
    // console.log('[CustomersApi] listCustomers ', _customers);
    return _customers?.data?.listCustomers;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.log('[CustomersApi] listCustomers errors', error);
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }
    throw error;
  }
}

export async function createCustomer(
  customerInput: CustomerAddInput,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>
): Promise<string> {
  console.log('[CustomersApi] - createCustomer: ', customerInput);
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    const result = (await apiClient.mutate({
      mutation: CREATE_CUSTOMER,
      variables: { customerInput },
    })) as GraphQLResult<{ createCustomer: '{}' }>;
    console.log('[CustomerApi] - customerInput - result:', customerInput, result);

    return result?.data?.createCustomer || '';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }
    throw error;
  }
}

export async function updateCustomer(
  customerUpdate: CustomerUpdateInput,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>
): Promise<string> {
  console.log('[CustomersApi] - updateCustomer: ', customerUpdate);
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    const result = (await apiClient.mutate({
      mutation: UPDATE_CUSTOMER,
      variables: { customerUpdate },
    })) as GraphQLResult<{ updateCustomer: '{}' }>;
    console.log('[CustomerApi] - customerUpdate - result:', customerUpdate, result);

    return result?.data?.updateCustomer || '';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }
    throw error;
  }
}

export async function enableCustomer(
  customerId: string,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>
): Promise<string> {
  console.log('[DemosAPI] - enableCustomer: ', customerId);
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    const result = (await apiClient.mutate({
      mutation: ENABLE_CUSTOMER,
      variables: { action: 'ENABLE', customerId },
    })) as GraphQLResult<{ enableCustomer: string }>;
    console.log('[DemosAPI] - enableCustomer - result:', customerId, result);

    return result?.data?.enableCustomer || '';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }
    throw error;
  }
}

export async function disableCustomer(
  customerId: string,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>
): Promise<string> {
  console.log('[DemosAPI] - disableCustomer: ', customerId);
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    const result = (await apiClient.mutate({
      mutation: DISABLE_CUSTOMER,
      variables: { action: 'DISABLE', customerId },
    })) as GraphQLResult<{ disableCustomer: string }>;
    console.log('[DemosAPI] - disableCustomer - result:', customerId, result);

    return result?.data?.disableCustomer || '';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }
    throw error;
  }
}

export async function deleteCustomer(
  customerId: string,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>
): Promise<string> {
  console.log('[CustomersApi] - deleteCustomer: ', customerId);
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    const result = (await apiClient.mutate({
      mutation: DELETE_CUSTOMER,
      variables: { id: customerId },
    })) as GraphQLResult<{ deleteCustomer: '{}' }>;
    console.log('[CustomerApi] - deleteCustomer - result:', customerId, result);

    return result?.data?.deleteCustomer || '';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }
    throw error;
  }
}
