import { ComponentStylesWithFns } from '../../../theme/styles.helpers';

export const AddButtonStyles: ComponentStylesWithFns = {
  button: {
    backgroundColor: 'text.primary',
    color: 'primary.contrastText',
    height: '51px',
    width: '51px',
    minWidth: '38px',
    ':hover': {
      backgroundColor: 'secondary.dark',
    },
  },
};
