import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useRedirectOnAuth } from '../../aws/AuthHooks';
import { AuthContext, AuthState } from '../../aws/AuthProvider';
import { DataContext } from '../../aws/DataProvider';
import { Templates as TemplatesTypes, Utils } from '@hellohair/types';
import FormInput from '../../components/form-components/form-input/FormInput';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { createTemplate, listTemplates, updateTemplate } from '../../aws/TemplatesApi';
import { LayoutProvider } from '../../legacy/provider/layoutProvider/LayoutProvider';
import SurveySingle from '../legacy/Survey/Single/SurveySingle';
import { useStyles } from '../../theme/styles.helpers';
import { ROUTE } from '../routes';
import { TemplateStyles } from './Template.Styles';
import { useNavigate } from 'react-router-dom';
import { CloseButton } from '../../components/button-components/close-button/CloseButton';
import { SaveButton } from '../../components/button-components/save-button/SaveButton';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Template: FC = () => {
  const { t } = useTranslation();
  const styles = useStyles(TemplateStyles, {});

  const {
    formsState: [, setFormsState],
    formDetailsState: [formDetails],
  } = useContext(DataContext);
  const { apiClient } = useContext(AuthContext);

  useRedirectOnAuth([AuthState.UNAUTHORIZED], ROUTE.LOGIN);

  const { handleSubmit, control } = useForm<Utils.DBItem<TemplatesTypes.SurveyTemplate>>({
    defaultValues: formDetails ?? {},
  });

  const navigate = useNavigate();

  // onSubmit
  const onSubmit: SubmitHandler<Utils.DBItem<TemplatesTypes.SurveyTemplate>> = async (data) => {
    if (!formDetails) {
      const updateLoad: TemplatesTypes.TemplateAddInput = {
        name: data?.name || 'name',
        description: data?.description || 'description',
      };

      console.log('[ProductsDetailView] - onSubmit - create', data, updateLoad);

      try {
        await createTemplate(updateLoad, apiClient);
        console.log('[ProductsDetailView]  - successfully created new product');
        const _products = await listTemplates(apiClient, 'network-only');
        setFormsState(_products);
        navigate(ROUTE.FORMS);
      } catch (error) {
        console.log('[ProductDetailView] - error:', error);
      }
    } else {
      const updateLoad: TemplatesTypes.TemplateUpdateInput = {
        templateId: data?.templateId,
        name: data?.name,
        description: data?.description,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        steps: JSON.stringify(formDetails?.steps ?? []) as any,
      };
      //FIXME: Remove this for live
      console.log('DEV: TO UPDATE', formDetails.steps);

      try {
        await updateTemplate(updateLoad, apiClient);
        const _products = await listTemplates(apiClient, 'network-only');
        setFormsState(_products);
        navigate(ROUTE.FORMS);
        console.log('[ProductDetailView]  - successfully updated product with id ', data.templateId);
      } catch (error) {
        console.log('[ProductDetailView] - error:', error);
      }
    }
  };

  const onError: SubmitErrorHandler<Utils.DBItem<TemplatesTypes.SurveyTemplate>> = (errors) => {
    console.log('[ProductDetailView] - onError', errors);
  };

  return (
    <Box className="Products-root" sx={styles.root}>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Box className="Products-heading">
          <FormInput name="name" label={t('Name')} control={control} />
          <Box className="Buttons">
            <CloseButton type="button" onClick={() => navigate(ROUTE.FORMS)} />
            <SaveButton type="submit" />
          </Box>
        </Box>

        <Box className="Form-form">
          <Box sx={{ flexGrow: 1, width: 0 }}>
            <LayoutProvider>
              <SurveySingle />
            </LayoutProvider>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              width: 0,
              backgroundColor: 'primary.light',
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography variant="body2">Reserved for live preview if possible</Typography>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default Template;
