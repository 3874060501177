import CheckIcon from '@mui/icons-material/Check';
import { IconButton } from '@mui/material';
import { FC, MouseEventHandler } from 'react';
import { useStyles } from '../../../theme/styles.helpers';
import { SaveButtonStyles } from './SaveButton.Styles';

interface SaveButtonProps {
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

export const SaveButton: FC<SaveButtonProps> = ({ type, className, onClick, disabled }) => {
  const styles = useStyles(SaveButtonStyles, {});

  return (
    <IconButton disabled={disabled} type={type} className={className} sx={styles.button} onClick={onClick} size="small">
      <CheckIcon />
    </IconButton>
  );
};
