import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  Icon,
  FormControl,
} from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FC, useCallback, useContext, useState } from 'react';
import { MultiSelectAnswerStyles } from './MultiSelectAnswer.styles';
import AnswerChoice from '../../AnswerChoice/AnswerChoice';
import { Draggable } from 'react-beautiful-dnd';
import { TabPanel } from '../../Functions/TabPanel';
import CheckBoxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone';
import { useStyles } from '../../../../theme/styles.helpers';
import { useLayout, useUpdateLayout } from '../../../../legacy/provider/layoutProvider/LayoutProvider';
import { Templates, Utils } from '@hellohair/types';
import { DataContext } from '../../../../aws/DataProvider';
import { CloseButton } from '../../../button-components/close-button/CloseButton';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface MultiSelectAnswerProps {
  survey: Templates.SurveyTemplate;
  index: number;
  className?: string;
}

const MultiSelectAnswer: FC<MultiSelectAnswerProps> = ({ survey, index }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const styles = useStyles(MultiSelectAnswerStyles, {});
  const formattedIndex = index.toLocaleString('de-DE', { minimumIntegerDigits: 2 });

  const expanded = useLayout();
  const setCurrentExpanded = useUpdateLayout();

  const {
    formDetailsState: [, setCurrentSurvey],
  } = useContext(DataContext);

  const step = survey.steps[index] as Templates.StepDataMulti;
  const { type, question, next, id } = step;
  const questions = survey.steps;
  const [localQuestion, setLocalQuestion] = useState<string>(question);
  const [localNext, setLocalNext] = useState<string>(next);
  const [localOptions, setLocalOptions] = useState(step.options);

  const [tab, setTab] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelectChange = (i: any, event: SelectChangeEvent) => {
    const newOptions = [...localOptions];
    newOptions[i].followerId = Number(event.target.value);
    setLocalOptions(newOptions);
  };

  const handleImageChange = (i: number, url: string) => {
    const newOptions = [...localOptions];
    (newOptions[i] as Templates.SingleImageOption).imageURL = url;
    setLocalOptions(newOptions);
  };

  const handleAnswerChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (i: number, event: any) => {
      const newOptions = [...localOptions];
      newOptions[i].option = event.target.value;
      setLocalOptions(newOptions);
    },
    [localOptions]
  );

  const addChoice = () => {
    if (localOptions) {
      setLocalOptions((oldOptions) => [
        ...oldOptions,
        {
          type: 'SingleSelectOptionText',
          option: '',
          followerId: -1,
        },
      ]);
    } else {
      setLocalOptions([
        {
          type: 'SingleSelectOptionText',
          option: '',
          followerId: -1,
        },
      ]);
    }
  };

  const saveElement = () => {
    const newState = [...survey.steps];
    newState[index] = {
      id: id,
      type: type,
      question: localQuestion,
      options: localOptions,
      next: localNext,
    } as Templates.StepDataMulti;
    setCurrentSurvey({ ...survey, steps: newState } as Utils.DBItem<Templates.SurveyTemplate>);
  };

  const deleteElement = () => {
    const newState = [...survey.steps];
    newState.splice(index, 1);
    setCurrentSurvey({ ...survey, steps: newState } as Utils.DBItem<Templates.SurveyTemplate>);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const removeChoice = (_id: any) => {
    localOptions.splice(_id, 1);
    setLocalOptions([...localOptions]);
  };

  return (
    <Draggable draggableId={id.toString()} index={index}>
      {/*eslint-disable-next-line @typescript-eslint/no-explicit-any*/}
      {(provided: any) => (
        <Accordion
          expanded={expanded === index}
          onChange={setCurrentExpanded(index)}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          elevation={0}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Box className="AccordionSummary-index" display="flex" alignItems="center">
              <Typography variant="body2" sx={{ width: '30px' }} fontWeight="bold">
                {formattedIndex}
              </Typography>
              <Icon sx={{ width: '30px', marginRight: '10px' }}>
                <CheckBoxTwoToneIcon />
              </Icon>
              <Box className="AccordionSummary-question" sx={{ width: 'calc(100% - 70px)' }}>
                <Typography
                  variant="body2"
                  sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    display: '-webkit-box',
                    '-webkit-line-clamp': '2',
                    lineClamp: '2',
                    '-webkit-box-orient': 'vertical',
                  }}
                >
                  {localQuestion}
                </Typography>
              </Box>
            </Box>
          </AccordionSummary>

          <AccordionDetails>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
                <Tab label={`Edit (ID: ${id})`} {...a11yProps(0)} />
                <Tab label="Logic" {...a11yProps(1)} />
                <Tab label="Settings" {...a11yProps(2)} />
              </Tabs>
            </Box>

            <TabPanel key="tab_1" value={tab} index={0}>
              <TextField
                fullWidth
                value={localQuestion}
                label="Question"
                onChange={(e) => setLocalQuestion(e.target.value)}
                multiline
                size="small"
                margin="dense"
              />
              <br />
              <Typography variant="body2">Answer choices</Typography>
              {localOptions &&
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                localOptions.map((option: any, i: number) => (
                  <Box
                    key={i}
                    sx={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap', justifyContent: 'space-between' }}
                  >
                    <AnswerChoice
                      i={i}
                      option={option}
                      changeAnswer={handleAnswerChange}
                      changeImage={handleImageChange}
                    />
                    <CloseButton onClick={() => removeChoice(i)} />
                  </Box>
                ))}
              <Button onClick={addChoice} fullWidth variant="outlined">
                <Typography variant="h3">Add Answer</Typography>
              </Button>
            </TabPanel>

            <TabPanel value={tab} index={1}>
              {localOptions &&
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                localOptions.map((option: any, i: number) => (
                  <Box key={`div_${i}`}>
                    <Typography variant="body2">{option.option}</Typography>
                    <FormControl fullWidth margin="dense">
                      <InputLabel id={`labelType_${i}`}>Next Question</InputLabel>
                      <Select
                        className="selectNextQuestion"
                        labelId={`labelType_${i}`}
                        id="`selectType_${i}`"
                        value={option.followerId}
                        label="Next Question"
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        onChange={(e: any) => handleSelectChange(i, e)}
                        name={`sel_${i}`}
                        fullWidth
                        size="small"
                      >
                        <MenuItem id="select_-1" key="-1" value="-1">
                          -1 Bitte Auswahl treffen
                        </MenuItem>
                        {/*eslint-disable-next-line @typescript-eslint/no-explicit-any*/}
                        {questions.map((item: any, _i: number) => {
                          return (
                            <MenuItem id={`select_${_i}`} key={_i} value={item.id}>
                              {item.question}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                ))}
            </TabPanel>

            <TabPanel value={tab} index={2}>
              <TextField
                value={localNext}
                label="Button label"
                fullWidth
                onChange={(e) => setLocalNext(e.target.value)}
                size="small"
                margin="dense"
              ></TextField>
            </TabPanel>

            <Box sx={{ display: 'grid', gap: 1 }}>
              <Button onClick={saveElement} fullWidth>
                <Typography variant="h3">save element</Typography>
              </Button>
              <Button onClick={deleteElement} fullWidth sx={{ background: '#FF3030!important' }}>
                <Typography variant="h3">delete element</Typography>
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
    </Draggable>
  );
};

export default MultiSelectAnswer;
