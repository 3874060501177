import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Auth } from 'aws-amplify';
import { FC, useContext, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../aws/AuthProvider';
import FormInput from '../../components/form-components/form-input/FormInput';
import { useStyles } from '../../theme/styles.helpers';
import { setPasswordStyles } from './SetPassword.Styles';
import Logo from '../../assets/logo.svg';
import { Link, Typography } from '@mui/material';
import { ROUTE } from '../routes';
import { useNavigate } from 'react-router-dom';

const SetPassword: FC = () => {
  const [error, setError] = useState<unknown>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const styles = useStyles(setPasswordStyles, {});
  const { control, handleSubmit } = useForm<FieldValues>();
  const {
    cognitoUserState: [cognitoUser],
  } = useContext(AuthContext);
  const onValid: SubmitHandler<FieldValues> = async ({ password, passwordConfirmation }) => {
    try {
      if (!cognitoUser) {
        console.log('[SetPassword] onValid CognitoUserUnknown');
        return;
      }

      if (password !== passwordConfirmation) {
        console.log('[SetPassword] onValid wrongPasswordConfirmation');
        setError(t('PWMissmatch'));
        return;
      }

      console.log('[SetPassword] onValid complete');

      const response = await Auth.completeNewPassword(cognitoUser, password);

      console.log('[SetPassword] onValid response', response);
      navigate(ROUTE.LOGIN);
    } catch (_error) {
      console.log('[SetPassword] onValid error', _error);
    }
  };

  return (
    <Box className="SetPassword-root" sx={styles.root}>
      <Box sx={styles.header}>
        <Box sx={styles.logoContainer}>
          <Box component="img" className="Dummy-Logo" sx={styles.dummyLogo} src={Logo} />
        </Box>
      </Box>

      <Box sx={styles.content}>
        <Box className="Login-heading" sx={styles.heading}>
          <Typography variant="h2"> {t('Set Password')}</Typography>
        </Box>

        <Box sx={styles.form}>
          <form onSubmit={handleSubmit(onValid)}>
            <FormInput
              name="password"
              type="password"
              placeholder={t('Set Password')}
              control={control}
              className="w_100 mb-2"
            ></FormInput>
            <FormInput
              name="passwordConfirmation"
              type="password"
              placeholder={t('Confirm Password')}
              control={control}
              className="w_100 mb-2"
            ></FormInput>
            <Button type="submit" className="mb-3" fullWidth>
              {t('Set Password')}
            </Button>
          </form>
        </Box>
        {!!error && (
          <>{typeof error === 'string' ? error : (error as Record<string, unknown>).name ?? 'Unbekannter Fehler'}</>
        )}
        <Link onClick={() => navigate(ROUTE.LOGIN)} sx={styles.link} color="inherit" fontSize={16}>
          {t('Return to login')}
        </Link>
      </Box>
    </Box>
  );
};

export default SetPassword;
