import { BreakpointsOptions } from '@mui/material';

export const breakpoints: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: 0,
    md: 601,
    lg: 985,
    xl: 1471,
  },
};
