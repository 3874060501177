import { ComponentStylesWithFns } from '../../theme/styles.helpers';

export const userPanelStyles: ComponentStylesWithFns = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 2,
    backgroundColor: 'black',
    borderRight: '0.5px solid',
    borderColor: 'black',
  },
  avatar: {
    width: 32,
    height: 32,
    backgroundColor: 'grey.800',
    borderRadius: '50%',
    marginLeft: 3,
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    marginY: 4,
    width: 160,
  },
  userInfoDisplay: {
    blackSpace: 'nowrap',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  logout: {
    marginRight: 3,
  },
  logoutIcon: {
    opacity: '50%',
    cursor: 'pointer',
    margin: 'auto',
    color: 'white',
  },
};
