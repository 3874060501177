import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { DBItem } from '@hellohair/types/dist/utils';
import { OrderRequest } from '@hellohair/types/dist/orders';
import { calculateOrderEarning } from '../../helpers/requests';

ChartJS.register(LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend, Filler);

const LineChartStudioMonth = ({ requestsByDays }: { requestsByDays: Record<string, DBItem<OrderRequest>[]> }) => {
  const { t } = useTranslation();
  const data = Object.keys(requestsByDays).map((day) =>
    requestsByDays[day]
      .filter((r) => r.orderState === 'ALLOWED')
      .map(calculateOrderEarning)
      .reduce((c, a) => c + a, 0)
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Hide x-axis grid
        },
      },
      y: {
        beginAtZero: true, // Ensure the y-axis starts at 0
        min: 0, // Explicitly set the minimum value
        max: Math.max(...data) + Math.max(...data) * 0.25, // Explicitly set the maximum value
        ticks: {
          display: false, // Hide x-axis labels
        },
      },
    },
  };

  const lineData = {
    labels: Object.keys(requestsByDays),
    datasets: [
      {
        label: t('Earning'),
        data: data.map((amount) => amount.toFixed(2)),
        borderColor: 'rgb(72, 129, 215)',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        backgroundColor: (ctx: any) => {
          const chart = ctx.chart;
          const { ctx: canvasCtx, chartArea } = chart;
          if (!chartArea) return null;
          const gradient = canvasCtx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
          gradient.addColorStop(0, 'rgba(72, 129, 215, 1)');
          gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');
          return gradient;
        },
        borderWidth: 1,
        tension: 0.3, // Smooth line
        pointRadius: 0, // Remove bullets
        fill: true, // Fill the area under the line
      },
    ],
  };

  return (
    <div>
      <Line options={options} data={lineData} />
    </div>
  );
};

export default LineChartStudioMonth;
