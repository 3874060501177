const SidebarStyles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    borderRight: '0.5px solid',
    borderColor: 'primary.light',

    '.sidebar-appLinksList': {
      flexGrow: 1,
    },
    '.logo': {
      marginY: '36px',
      marginX: 5,
      width: '150px',
    },
  },
};

export default SidebarStyles;
