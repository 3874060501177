import { SvgIcon, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../../../theme/styles.helpers';
import { Styles } from './styles';
import { Studio, Users } from '@hellohair/types';
import { Close } from '@mui/icons-material';
import MemberForm from './member-form';
import StudioForm from './studio-form';

interface UserFormProps {
  onModalClose: () => void;
  selectedStudio: Studio | null;
  isStudioManager: boolean;
}

const StudioModal: FC<UserFormProps> = ({ onModalClose, selectedStudio, isStudioManager }) => {
  const { t } = useTranslation();
  const styles = useStyles(Styles, {});
  const [selectedMember, setSelectedMember] = useState<Users.BackendUser | null>(null);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 5000);
    }
  }, [copied]);

  const renderTitle = () => {
    if (selectedMember) {
      return `${selectedMember.firstName} ${selectedMember.lastName}`;
    }
    if (selectedStudio) {
      return selectedStudio.name;
    }
    return t('Add Studio');
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.container}>
        <Box sx={styles.header}>
          <Typography variant="h4">{renderTitle()}</Typography>
          {selectedStudio && !selectedMember && (
            <Box
              onClick={() => {
                navigator.clipboard.writeText('https://hellohair.com/?s_source=' + selectedStudio.id);
                setCopied(true);
              }}
              sx={{
                position: 'absolute',
                right: 40,
                cursor: 'pointer',
              }}
            >
              <Typography>{copied ? 'Kopiert' : 'Deeplink kopieren'}</Typography>
            </Box>
          )}
        </Box>

        {!selectedMember && (
          <StudioForm
            onModalClose={onModalClose}
            selectedStudio={selectedStudio}
            isStudioManager={isStudioManager}
            onSelectMember={(d) => setSelectedMember(d)}
          />
        )}

        {selectedMember && (
          <MemberForm
            member={selectedMember}
            isManager={selectedMember.sub === selectedStudio?.managerId}
            onClose={() => setSelectedMember(null)}
          />
        )}
      </Box>

      {/* Closing Icon */}
      <Box onClick={onModalClose} sx={{ marginLeft: 2, cursor: 'pointer' }}>
        <SvgIcon sx={{ color: 'primary.main' }} component={Close}></SvgIcon>
      </Box>
    </Box>
  );
};

export default StudioModal;
