import { Box, Typography } from '@mui/material';
import { Orders } from '@hellohair/types';
import React, { FC } from 'react';

interface MyProps {
  customer: Orders.OrderCustomerDetails;
}

export const OrderDetailsCustomer: FC<MyProps> = (props) => {
  const { customer } = props;
  return (
    <Box>
      <Typography variant="h5" marginBottom={3}>
        Kunde: {customer.customerName}
      </Typography>
      <Typography variant="h5" marginBottom={3}>
        Geburtsdatum: {new Date(customer.birthDate).toLocaleDateString('de-DE')}
      </Typography>
    </Box>
  );
};
