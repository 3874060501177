import { Components } from '@mui/material';

const MuiButtonOverrides: Components = {
  MuiButton: {
    defaultProps: {
      variant: 'contained',
      disableElevation: true,
    },
    styleOverrides: {
      root: ({ ownerState: { variant } }) => ({
        flexShrink: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        borderRadius: '40px',
        backgroundColor: '#000',
        textAlign: 'center',
        fontWeight: '500',
        border: 'none',
        display: 'inline-block',
        color: 'white',
        transition: 'color 0.3s',
        lineHeight: '70px',
        height: '70px',
        fontSize: '20px',
        padding: '0 1.5em',
        textTransform: 'none',
        '&:hover': {
          backgroundColor: '#000',
          color: 'rgba(255, 255, 255, 0.5)',
        },
        ...(variant === 'contained' && {
          backgroundColor: 'black',
        }),
        ...(variant === 'outlined' && {
          border: '1px solid rgba(0,0,0,0.5)',
          background: 'transparent',
          color: 'black',
          transition: 'background 0.3s border-color 0.3s',
          '&:hover': {
            borderColor: 'black',
            backgroundColor: 'rgba(0,0,0,0.04)',
          },
        }),
      }),
    },
  },
  MuiListItemButton: {
    styleOverrides: {
      root: {
        flexShrink: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        borderRadius: '40px',
        textAlign: 'center',
        fontWeight: '500',
        border: 'none',
        color: 'black',
        transition: 'color 0.3s',
        lineHeight: '70px',
        height: '70px',
        fontSize: '20px',
        padding: '1.2rem',
        textTransform: 'none',
        display: 'flex',
      },
    },
    variants: [
      {
        props: { color: 'dark' },
        style: {
          color: 'white',
          backgroundColor: 'black',
          '&:hover': {
            backgroundColor: 'black',
          },
        },
      },
      {
        props: { color: 'light' },
        style: {
          color: '#aaa',
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    ],
  },
};

export default MuiButtonOverrides;
