import FetchPolicy from 'apollo-client/core/watchQueryOptions';
import AWSAppSyncClient from 'aws-appsync';
import { NormalizedCacheObject } from './aws.config';
import { LIST_STATISTICS } from './graphql/queries';

export async function listStatistics(
  year: number,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>,
  fetchPolicy: FetchPolicy.FetchPolicy = 'cache-first'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const _statistics = await apiClient.query<{ getStatsByYear: any }>({
      query: LIST_STATISTICS,
      variables: { year },
      fetchPolicy: fetchPolicy,
    });
    return _statistics?.data?.getStatsByYear;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.log('[StatisticsApi] listStatistics errors', error);
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }
    throw error;
  }
}
