import { Button, SvgIcon, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { CSSProperties, FC } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../../../theme/styles.helpers';
import FormInput from '../../form-components/form-input/FormInput';
import { ConsultationFormStyles } from './ConsultationForm.Styles';
import { EventInput } from '@fullcalendar/core';
import { DateClickArg } from '@fullcalendar/interaction';
import { Close } from '@mui/icons-material';

interface ConsultationFormProps {
  date: DateClickArg;
  onSubmit: (event: EventInput) => void;
  onModalClose: () => void;
}

const ConsultationForm: FC<ConsultationFormProps> = ({ date, onSubmit: _onSubmit, onModalClose }) => {
  const { t } = useTranslation();

  //MUI Styles
  const styles = useStyles(ConsultationFormStyles, {});

  // Modal UserForm
  const { handleSubmit, control } = useForm<EventInput & { time: string }>({
    defaultValues: { title: 'Sprechstunde', allDay: false, time: '' },
  });

  // onSubmit
  const onSubmit: SubmitHandler<EventInput & { time: string }> = async (data) => {
    data.date = date.date;
    if (data.time !== '' && !data.allDay) {
      const time = new Date(`0001-01-01 ${data.time}`);
      date.date.setMinutes(time.getMinutes());
      date.date.setHours(time.getHours());
    }
    _onSubmit({
      title: data.title,
      allDay: data.allDay ?? false,
      date: date.date,
    });
    onModalClose();
  };

  const onError: SubmitErrorHandler<EventInput & { time: string }> = (errors) => {
    console.log('[ConsultationDetailView] - onError', errors);
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.container}>
        <Box sx={styles.header}>
          <Typography variant="h4">
            {date.date.toLocaleDateString('de', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </Typography>
        </Box>

        <form onSubmit={handleSubmit(onSubmit, onError)} style={styles.form as CSSProperties}>
          <FormInput className="w_100 mg_h_20" name="title" label={t('Title')} control={control} />
          <FormInput
            className="w_100 mg_h_20"
            name="time"
            label={t('Time')}
            control={control}
            type="time"
            step={`${15 * 60}`}
          />

          <Box style={{ flexGrow: 1 }} />

          <Button
            fullWidth
            type="submit"
            sx={{
              marginTop: 3,
            }}
          >
            <Typography variant="h3">{t('Done')}</Typography>
          </Button>
        </form>
      </Box>

      {/* Closing Icon */}
      <Box onClick={onModalClose} sx={{ marginLeft: 2, cursor: 'pointer' }}>
        <SvgIcon sx={{ color: 'primary.main' }} component={Close}></SvgIcon>
      </Box>
    </Box>
  );
};

export default ConsultationForm;
