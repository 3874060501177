import ThemeProvider from '@mui/system/ThemeProvider';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import AuthProvider from './aws/AuthProvider';
import DataProvider from './aws/DataProvider';
import i18n from './i18-next/i18-next';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { theme } from './theme/theme';
import { CssBaseline } from '@mui/material';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <>
    <React.Suspense fallback="Loading...">
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <AuthProvider>
            <DataProvider>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <App />
              </ThemeProvider>
            </DataProvider>
          </AuthProvider>
        </BrowserRouter>
      </I18nextProvider>
    </React.Suspense>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
