import { createContext, useContext, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LayoutContext = createContext<any>({});
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LayoutUpdateContext = createContext<any>({});

export function useLayout() {
  return useContext(LayoutContext);
}

export function useUpdateLayout() {
  return useContext(LayoutUpdateContext);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function LayoutProvider({ children }: { children: any }) {
  const [expanded, setExpanded] = useState<number | false>(false);
  const setCurrentExpanded = (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <LayoutContext.Provider value={expanded}>
      <LayoutUpdateContext.Provider value={setCurrentExpanded}>{children}</LayoutUpdateContext.Provider>
    </LayoutContext.Provider>
  );
}
