import AddIcon from '@mui/icons-material/Add';
import { IconButton } from '@mui/material';
import { FC, MouseEventHandler } from 'react';
import { useStyles } from '../../../theme/styles.helpers';
import { AddButtonStyles } from './AddButton.Styles';

interface AddButtonProps {
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const AddButton: FC<AddButtonProps> = ({ type, className, onClick }) => {
  const styles = useStyles(AddButtonStyles, {});

  return (
    <IconButton type={type} className={className} sx={styles.button} onClick={onClick}>
      <AddIcon />
    </IconButton>
  );
};
