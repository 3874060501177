/**
 * This File holds interface declarations for the objects send to brevo in the Params of the api call
 *
 * in the doc comment for each interface we reference the corresponding "Email Typ" from https://hellohair.atlassian.net/wiki/spaces/~712020d3530e22a524489bb927468dd5a1b8a1/pages/7438381/Email+Templates
 */

/**
 * provide mapping between template usage case and brevo template id
 */

/**
 *
 */
export interface BrevoAttachment {
  content: string;
  name: string;
}

export interface BrevoEmailAttributes {
  email: string;
  name: string;
}

export interface BrevoSendTemplateMailParams {
  to: BrevoEmailAttributes[];
  templateId: BrevoTemplateIds;
  params: BrevoEmailParams;
  // headers?: Record<string, string>;
  sender?: BrevoEmailAttributes;
  attachment?: BrevoAttachment[];
}

/**
 * Article Definition
 */
export interface BrevoParamsArticle {
  /**ArtikelNummer */
  number: string;
  amount: number;
  /** as Brevo is a bit limited in processing Integer, therefore we use a string: "nn,nn" */
  priceSingle: string;
  priceTotal: string;
  /** url to the product image */
  image: string;
  name: string;
}

/**
 * Address Definition
 */
export interface BrevoParamsAddress {
  /** eg. "Max Musterfrau" */
  name: string;
  /** eg. "Musterstr. 16" */
  street: string;
  postcode: string;
  city: string;
  country: string;
}

export enum BrevoTemplateIds {
  VERIFY_REGISTER = 14,
  RESET_PASSWORD = 25,
  DOC_APPROVAL_NECESSARY = 18,
  DOC_REAPPROVAL_NECESSARY = 40,
  TREATMENT_DENIED = 19,
  TREATMENT_APPROVED = 20,
  FOLLOWUP_DENIED = 38,
  ORDER_SENT_FOLLOWUP_SUBSCRIPTION = 23,
  SUBSCRIPTION_CANCELED = 24,
  SUBSCRIPTION_PAUSED = 36,
  SUBSCRIPTION_PAUSED_FOLLOWUP = 41,
  TREATMENT_FOLLOWUP_FORMULAR = 26,
  ORDER_CANCELLED = 34,
  ORDER_RECEIVED = 21,
  ORDER_SENT = 22,
  NEWSLETTER_OPTIN = 32,
  NEWSLETTER_CONFIRMED = 33,
  RETURN_ITEMS = 37,
  FOLLOWUP_READY = 39,
}

export type BrevoSendEmailParams =
  | BrevoSendVerifyRegister
  | BrevoSendResetPassword
  | BrevoSendDocApprovalNecessary
  | BrevoSendTreatmentDenied
  | BrevoSendFollowupDenied
  | BrevoSendOrderReceived
  | BrevoSendFollowupOrderSent
  | BrevoSendSubscriptionCanceled
  | BrevoSendSubscriptionPaused
  | BrevoSendTreatmentFollowUpFormular
  | BrevoSendTreatmentApproved
  | BrevoSendNewsletterConfirmed
  | BrevoSendNewsletterOptIn
  | BrevoSendOrderSent
  | BrevoSendOrderCanceled
  | BrevoSendReturnItems
  | BrevoSendDocReapprovalNecessary
  | BrevoSendFollowupReady;

export type BrevoEmailParams =
  | EmailParamsRegistration
  | EmailParamsResetPassword
  | EmailParamsDocApprovalNecessary
  | EmailParamsTreatmentDenied
  | EmailParamsOrder
  | EmailParamsTreatment
  | EmailParamsFollowUpShipment
  | EmailParamsShipment
  | EmailParamsOrderCanceled
  | EmailParamsSubscriptionCanceled
  | EmailParamsSubscriptionPaused
  | EmailParamsTreatmentFollowUpFormular
  | EmailParamsNewsletterConfirmed
  | EmailParamsNewsletterOptIn;

/**
 *  base always needed
 */
export interface BrevoEmailParamsBase {
  firstName: string;
  email: string;
}

/**
 * Registrieren
 */
export interface BrevoSendVerifyRegister extends BrevoSendTemplateMailParams {
  templateId: BrevoTemplateIds.VERIFY_REGISTER;
  params: EmailParamsRegistration;
}
export interface EmailParamsRegistration extends BrevoEmailParamsBase {
  code: string;
  redirectUri: string;
}

/**
 * Passwort vergessen
 */
export interface BrevoSendResetPassword extends BrevoSendTemplateMailParams {
  templateId: BrevoTemplateIds.RESET_PASSWORD;
  params: EmailParamsResetPassword;
}
export interface EmailParamsResetPassword extends BrevoEmailParamsBase {
  /** url with parameters code and username eg. "https://hellohair.com/reset?code=T3STC0D3&username=test%40freundederinteraktion.de" */
  resetPasswordUrl: string;
}

/**
 * 	Prüfung durch Arzt erforderlich
 */
export interface EmailParamsDocApprovalNecessary extends BrevoEmailParamsBase {}

export interface BrevoSendDocApprovalNecessary extends BrevoSendTemplateMailParams {
  templateId: BrevoTemplateIds.DOC_APPROVAL_NECESSARY;
  params: EmailParamsDocApprovalNecessary;
}

export interface BrevoSendDocReapprovalNecessary extends BrevoSendTemplateMailParams {
  templateId: BrevoTemplateIds.DOC_REAPPROVAL_NECESSARY;
  params: EmailParamsDocApprovalNecessary;
}

export interface BrevoSendFollowupReady extends BrevoSendTemplateMailParams {
  templateId: BrevoTemplateIds.FOLLOWUP_READY;
  params: EmailParamsDocApprovalNecessary;
}

/**
 * 	Deine Behandlung wurde abgelehnt
 */
export interface BrevoSendTreatmentDenied extends BrevoSendTemplateMailParams {
  templateId: BrevoTemplateIds.TREATMENT_DENIED;
  params: EmailParamsTreatmentDenied;
}
export interface EmailParamsTreatmentDenied extends BrevoEmailParamsBase {}
export interface BrevoSendFollowupDenied extends BrevoSendTemplateMailParams {
  templateId: BrevoTemplateIds.FOLLOWUP_DENIED;
  params: EmailParamsTreatmentDenied;
}

/**
 * 	Deine Behandlung wurde bestätigt
 * 	Danke für Bestellung
 */
export interface BrevoSendOrderReceived extends BrevoSendTemplateMailParams {
  templateId: BrevoTemplateIds.ORDER_RECEIVED;
  params: EmailParamsOrder;
}
export interface BrevoSendTreatmentApproved extends BrevoSendTemplateMailParams {
  templateId: BrevoTemplateIds.TREATMENT_APPROVED;
  params: EmailParamsTreatment;
}

export interface EmailParamsOrder extends BrevoEmailParamsBase {
  orderNumber: string;
  /** eg. "8.11.2024" */
  orderDate: string;
  /**eg. "250,59" */
  totalPrice: string;
  paymentMethod: string;
  articles: BrevoParamsArticle[];
  billingAddress: BrevoParamsAddress;
  shippingAddress?: BrevoParamsAddress;
}
export interface EmailParamsTreatment extends BrevoEmailParamsBase {
  orderDate: string;
}

/**
 * Bestellung versendet - Folgemail bei Abo
 */
export interface BrevoSendFollowupOrderSent extends BrevoSendTemplateMailParams {
  templateId: BrevoTemplateIds.ORDER_SENT_FOLLOWUP_SUBSCRIPTION;
  params: EmailParamsShipment;
}
export interface EmailParamsFollowUpShipment extends EmailParamsShipment {}

/** Abonnement storniert */
export interface BrevoSendSubscriptionCanceled extends BrevoSendTemplateMailParams {
  templateId: BrevoTemplateIds.SUBSCRIPTION_CANCELED;
  params: EmailParamsSubscriptionCanceled;
}
export interface EmailParamsSubscriptionCanceled extends BrevoEmailParamsBase {}

/** Abo läuft aus */
export interface BrevoSendTreatmentFollowUpFormular extends BrevoSendTemplateMailParams {
  templateId: BrevoTemplateIds.TREATMENT_FOLLOWUP_FORMULAR;
  params: EmailParamsTreatmentFollowUpFormular;
}
export interface EmailParamsTreatmentFollowUpFormular extends BrevoEmailParamsBase {
  orderNumber: string;
}

/** Newsletter Bestätigung */
export interface BrevoSendNewsletterConfirmed extends BrevoSendTemplateMailParams {
  templateId: BrevoTemplateIds.NEWSLETTER_CONFIRMED;
  params: EmailParamsNewsletterConfirmed;
}
export interface EmailParamsNewsletterConfirmed extends BrevoEmailParamsBase {}

/** Newsletter OptIn */
export interface BrevoSendNewsletterOptIn extends BrevoSendTemplateMailParams {
  templateId: BrevoTemplateIds.NEWSLETTER_OPTIN;
  params: EmailParamsNewsletterOptIn;
}
export interface EmailParamsNewsletterOptIn extends BrevoEmailParamsBase {
  doubleoptin: string;
}

/**
 * 	Deine Bestellung hat unser Lager verlassen
 */
export interface BrevoSendOrderSent extends BrevoSendTemplateMailParams {
  templateId: BrevoTemplateIds.ORDER_SENT;
  params: EmailParamsShipment;
}
export interface EmailParamsShipment extends EmailParamsOrder {
  trackingNumber: string;
  trackingUrl: string;
}

/**
 * 	Deine Bestellung wurde storniert
 */
export interface BrevoSendOrderCanceled extends BrevoSendTemplateMailParams {
  templateId: BrevoTemplateIds.ORDER_CANCELLED;
  params: EmailParamsOrderCanceled;
}
export interface EmailParamsOrderCanceled extends BrevoEmailParamsBase {
  orderNumber: string;
}

/** Zahlung fehlgeschlagen */
export interface BrevoSendSubscriptionPaused extends BrevoSendTemplateMailParams {
  templateId: BrevoTemplateIds.SUBSCRIPTION_PAUSED;
  params: EmailParamsSubscriptionPaused;
}
export interface EmailParamsSubscriptionPaused extends BrevoEmailParamsBase {
  orderDate: string;
}

/** Artikel retourniert */
export interface BrevoSendReturnItems extends BrevoSendTemplateMailParams {
  templateId: BrevoTemplateIds.RETURN_ITEMS;
  params: EmailParamsOrder;
}
