import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { FC, MouseEventHandler } from 'react';
import { useStyles } from '../../../theme/styles.helpers';
import { CloseButtonStyles } from './CloseButton.Styles';

interface CloseButtonProps {
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const CloseButton: FC<CloseButtonProps> = ({ type, className, onClick }) => {
  const styles = useStyles(CloseButtonStyles, {});

  return (
    <IconButton type={type} className={className} sx={styles.button} onClick={onClick}>
      <CloseIcon />
    </IconButton>
  );
};
