import { createTheme } from '@mui/material';
import { paletteOptions } from './palette';
import { deDE } from '@mui/x-data-grid-pro';
import { breakpoints } from './breakpoints';
import MuiButtonOverrides from './stylesOverrides/MuiButton';
import { typography } from './typography';
import MuiTextFieldOverrides from './stylesOverrides/MuiTextField';
import MuiOutlinedInput from './stylesOverrides/MuiOutlinedInput';
import MuiFormLabel from './stylesOverrides/MuiFormLabel';
import MuiTab from './stylesOverrides/MuiTab';
import MuiAccordion from './stylesOverrides/MuiAccordion';
import MuiCheckbox from './stylesOverrides/MuiCheckbox';

export const theme = createTheme(
  {
    components: {
      ...MuiButtonOverrides,
      ...MuiTextFieldOverrides,
      ...MuiOutlinedInput,
      ...MuiFormLabel,
      ...MuiTab,
      ...MuiAccordion,
      ...MuiCheckbox,
    },
    palette: paletteOptions,
    shape: {
      borderRadius: 10,
    },
    spacing: [0, 4, 8, 16, 24, 32, 48, 64, 72, 112],
    typography,
    breakpoints,
  },
  deDE
);
