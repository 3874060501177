import { ComponentStylesWithFns } from './theme/styles.helpers';

export const appStyles: ComponentStylesWithFns = {
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    width: '260px',
    position: 'fixed',
    height: '100vh',
  },
  content: {
    flexGrow: 1,
    marginLeft: '260px',
    backgroundColor: 'primary.main',
  },
  spinner: {
    color: 'primary.dark',
    zIndex: 100,
  },
  auth: {
    backgroundColor: 'primary.main',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: "100%",
    height: "100%",
  },
  row: {
    '& .MuiDataGrid-row': {
      // backgroundColor: 'rgb(42, 44, 46)',
      // height: '45px',
      // maxHeight: '45px !important',
      // minHeight: '45px !important',
      fontWeight: 'normal',
      fontSize: '14px',
      borderTop: '1px solid #e0e0e0',
      cursor: 'pointer',
      ':hover': {
        bgcolor: 'secondary.light',
        color: 'primary.contrastText',
      },
      '& .MuiDataGrid-cell': {
        border: 'none',
        outline: 'none',
      },
    },
  },
};
