import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Logo from '../../assets/logo.svg';
import Footer from '../../components/footer/Footer';
import { useStyles } from '../../theme/styles.helpers';
import { NotFoundStyles } from './NotFound.Styles';

const NotFound: FC = () => {
  const { t } = useTranslation();

  const styles = useStyles(NotFoundStyles, {});

  return (
    <Box sx={styles.root}>
      <Box component="img" className="Dummy-Logo" sx={styles.dummyLogo} src={Logo} />

      <Box sx={styles.spacer} />

      <Box sx={styles.box}>
        <Typography variant="h1">{t('Site not Found')}</Typography>
      </Box>

      <Box sx={styles.spacer} />

      <Footer />
    </Box>
  );
};

export default NotFound;
