import { Button, SvgIcon, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { CSSProperties, FC, useContext, useEffect } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../aws/AuthProvider';
import { DataContext } from '../../../aws/DataProvider';
import {
  addUserToGroup,
  createUser,
  listUsers,
  removeUserFromGroup,
  updateUser,
  UserAddInput,
  UserUpdateInput,
} from '../../../aws/UsersApi';
import { useStyles } from '../../../theme/styles.helpers';
import FormInput from '../../form-components/form-input/FormInput';
import FormMultiSelect from '../../form-components/form-multiselect/FormMultiSelect';
import { UserFormStyles } from './UserForm.Styles';
import { Users } from '@hellohair/types';
import { Close } from '@mui/icons-material';
import FormSelect from '../../form-components/form-select/FormSelect';

interface UserFormProps {
  onModalClose: () => void;
}

const UserForm: FC<UserFormProps> = ({ onModalClose }) => {
  const { t } = useTranslation();

  //Data & AuthContext
  const {
    userDetailsState: [userDetails, setUserDetails],
    usersState: [, setUsers],
    userGroupsState: [userGroups],
    studiosState: [studios],
  } = useContext(DataContext);
  const { apiClient } = useContext(AuthContext);
  const { userAttributes } = useContext(AuthContext);

  const isStudioManager =
    userAttributes?.roles.includes('doctor') && !!studios.find((studio) => studio.managerId === userAttributes?.id);
  const selectedStudio = studios.find((studio) => studio.managerId === userAttributes?.id);

  console.log('isStudioManager: ', isStudioManager);
  console.log('studio: ', selectedStudio);
  //MUI Styles
  const styles = useStyles(UserFormStyles, {});

  // Modal UserForm
  const { handleSubmit, control } = useForm<Users.BackendUser>({ defaultValues: userDetails });

  // useEffects
  useEffect(() => {
    return () => {
      setUserDetails(undefined);
    };
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  // onSubmit
  const onSubmit: SubmitHandler<Users.BackendUser> = async (data) => {
    if (!userDetails) {
      const createLoad: UserAddInput = {
        email: data?.email,
        job: data?.job,
        firstName: data?.firstName,
        title: data?.title,
        gender: data?.gender,
        lastName: data?.lastName,
        phoneNumber: data?.phoneNumber,
        address: data?.address,
        hasSproof: false, // this is set in the next step
        role: '', // this is set in the next step
      };

      // if is selected a studio, then studio manager is creating a new user which can only be a doctor
      if (selectedStudio) {
        createLoad.role = 'doctor';
        createLoad.factor = '1';
        createLoad.factor2 = '1';
        createLoad.studio = selectedStudio.id;
      } else {
        createLoad.role = data?.groups[0] || '';
        createLoad.hasSproof = data?.groups.includes('doctor') ?? false;
        if (data.factor !== undefined) {
          createLoad.factor = data.factor;
        }
        if (data.factor2 !== undefined) {
          createLoad.factor2 = data.factor2;
        }
        if (data.studio !== undefined) {
          createLoad.studio = data.studio;
        }
      }

      console.log('[UsersDetailView] - onSubmit - create', data, createLoad);

      try {
        const res = await createUser(createLoad, apiClient);
        if (res) console.log('[UsersDetailView]  - successfully created new user:');
        else console.log('[UsersDetailView]  - error on create new user, check logs');
      } catch (error) {
        console.log('[UserDetailView] - error:', error);
        return; // EXIT
      }

      setTimeout(async () => {
        // Studio manager can create only users with doctor role
        if (selectedStudio) {
          await addGroup(data.email || 'email', 'doctor');
        } else {
          data?.groups.shift();
          await Promise.all(data?.groups.map(async (role) => await addGroup(data?.email || 'email', role)));
        }

        const _users = await listUsers(apiClient, 'network-only');
        setUsers(_users);

        onModalClose();
      }, 2000);
    } else {
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      delete (data?.address as any)['__typename'];
      const updateLoad: UserUpdateInput = {
        sub: data?.sub,
        job: data?.job,
        gender: data?.gender,
        firstName: data?.firstName,
        title: data?.title,
        lastName: data?.lastName,
        phoneNumber: data?.phoneNumber,
        address: data?.address,
      };

      if (data.factor !== undefined) {
        updateLoad.factor = data.factor;
      }
      if (data.factor2 !== undefined) {
        updateLoad.factor2 = data.factor2;
      }
      if (data.studio !== undefined) {
        updateLoad.studio = data.studio;
      }

      console.log('[UsersDetailView] - onSubmit - update', data, updateLoad);

      try {
        const res = await updateUser(updateLoad, apiClient);
        console.log('[UsersDetailView]  - successfully updated user:', res);
      } catch (error) {
        console.log('[UserDetailView] - error:', error);
        return; // EXIT
      }

      console.log('Group changes from', userDetails?.groups, 'to', data.groups, 'having', userGroups);

      await Promise.all(
        userGroups.map(async (group) => {
          const currentStack = userDetails?.groups.find((_group) => _group === group.name);
          const newStack = data.groups.find((_group) => _group === group.name);

          if (currentStack && newStack) console.log('No Change', group.name);
          else if (!currentStack && newStack) await addGroup(data?.email || 'email', group.name);
          else if (currentStack && !newStack) await removeGroup(data?.email || 'Remove stack', group.name);
          else console.log('No Change, never was', group.name);
        })
      );

      const _users = await listUsers(apiClient, 'network-only');
      setUsers(_users);

      onModalClose();
    }
  };

  const onError: SubmitErrorHandler<Users.BackendUser> = (errors) => {
    console.log('[UserDetailView] - onError', errors);
  };

  const addGroup = async (email: string, group: string) => {
    const groupLoad = {
      name: email,
      group,
    };

    try {
      await addUserToGroup(groupLoad, apiClient);

      console.log('[UserDetailView]  - successfully added user to group');
    } catch (error) {
      console.log('[UserDetailView] - error:', error);
    }
  };

  const removeGroup = async (email: string, group: string) => {
    const groupLoad = {
      name: email,
      group,
    };

    try {
      await removeUserFromGroup(groupLoad, apiClient);

      console.log('[UserDetailView]  - successfully removed user from group');
    } catch (error) {
      console.log('[UserDetailView] - error:', error);
    }
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.container}>
        <Box sx={styles.header}>
          <Typography variant="h4">
            {userDetails
              ? `${userDetails?.firstName} ${userDetails?.lastName}`
              : selectedStudio
                ? `${t('Add User To')} ${selectedStudio.name}`
                : t('Add User')}
          </Typography>
        </Box>

        <form onSubmit={handleSubmit(onSubmit, onError)} style={styles.form as CSSProperties}>
          <Box>
            <Box sx={styles.grid}>
              <FormInput
                className="w_100 mg_h_20"
                name="gender"
                label={t('Gender')}
                control={control}
                style={styles.gridItem13 as CSSProperties}
              />
              <FormInput
                className="w_100 mg_h_20"
                name="title"
                label={t('Title')}
                control={control}
                style={styles.gridItem13 as CSSProperties}
              />
              <FormInput
                className="w_100 mg_h_20"
                name="firstName"
                label={t('Firstname')}
                control={control}
                style={styles.gridItem23 as CSSProperties}
                rules={{ required: true }}
              />
              <FormInput
                className="w_100 mg_h_20"
                name="lastName"
                label={t('Lastname')}
                control={control}
                style={styles.gridItem23 as CSSProperties}
                rules={{ required: true }}
              />
            </Box>

            <Box sx={styles.grid}>
              <FormInput
                className="w_100 mg_h_20"
                name="job"
                label={t('Job description')}
                control={control}
                style={styles.gridItemFull as CSSProperties}
              />
            </Box>

            <Box sx={styles.grid}>
              <FormInput
                className="w_100 mg_h_20"
                name="email"
                label={t('E-Mail')}
                control={control}
                style={styles.gridItem as CSSProperties}
                rules={{ required: true }}
                disabled={!!userDetails}
              />
              <FormInput
                className="w_100 mg_h_20"
                name="phoneNumber"
                label={t('Phonenumber')}
                control={control}
                style={styles.gridItem as CSSProperties}
              />
            </Box>

            <Box sx={styles.grid}>
              <FormInput
                className="w_100 mg_h_20"
                name="address.street"
                label={t('Street')}
                control={control}
                style={styles.gridItem32 as CSSProperties}
              />
              <FormInput
                className="w_100 mg_h_20"
                name="address.houseNumber"
                label={t('No.')}
                control={control}
                style={styles.gridItem13 as CSSProperties}
              />
            </Box>

            <Box sx={styles.grid}>
              <FormInput
                className="w_100 mg_h_20"
                name="address.zip"
                label={t('ZIP')}
                control={control}
                style={styles.gridItem13 as CSSProperties}
              />
              <FormInput
                className="w_100 mg_h_20"
                name="address.city"
                label={t('City')}
                control={control}
                style={styles.gridItem32 as CSSProperties}
              />
            </Box>

            {/*Studio manager can't change groups, factor and studio*/}
            {!isStudioManager && (
              <>
                <FormMultiSelect
                  className="w_100 mg_h_20"
                  name="groups"
                  label={t('Groups')}
                  control={control}
                  options={userGroups.map((group) => ({ id: group.name, value: group.name }))}
                  defaultValue={[]}
                />
                <Box sx={styles.grid}>
                  <FormInput
                    className="w_100 mg_h_20"
                    type={'number'}
                    name="factor"
                    label={t('Factor')}
                    control={control}
                    style={styles.gridItem13 as CSSProperties}
                  />
                  <FormInput
                    className="w_100 mg_h_20"
                    type={'number'}
                    name="factor2"
                    label={t('Factor') + ' 2'}
                    control={control}
                    style={styles.gridItem13 as CSSProperties}
                  />
                  <FormSelect
                    className="w_100 mg_h_20"
                    name="studio"
                    label={t('Studio')}
                    control={control}
                    style={styles.gridItem32 as CSSProperties}
                    defaultValue={'-'}
                    options={[
                      { id: '-', value: 'Keine Praxis' },
                      ...studios.map((studio) => ({ id: studio.id || '', value: studio.name })),
                    ]}
                  />
                </Box>
              </>
            )}
          </Box>
          <Button
            fullWidth
            type="submit"
            sx={{
              marginTop: 3,
            }}
          >
            <Typography variant="h3">{t('Done')}</Typography>
          </Button>
        </form>
      </Box>

      {/* Closing Icon */}
      <Box onClick={onModalClose} sx={{ marginLeft: 2, cursor: 'pointer' }}>
        <SvgIcon sx={{ color: 'primary.main' }} component={Close}></SvgIcon>
      </Box>
    </Box>
  );
};

export default UserForm;
