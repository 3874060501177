import { SxProps, Theme, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { FC, Key } from 'react';
import { HistoryListStyles } from './HistoryList.styles';
import { useStyles } from '../../../theme/styles.helpers';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { CheckCircle, HighlightOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface HistoryListProps {
  // TODO: fix type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  historyData?: any;
  onItemClick: (itemId: string) => void;

  /** styles */
  sx?: SxProps<Theme>;
}

export enum OrderStateType {
  ALLOWED = 'ALLOWED',
  ALLOW = 'ALLOW',
  IDLE = 'IDLE',
  DENIED = 'DENIED',
}

const HistoryList: FC<HistoryListProps> = ({ historyData, onItemClick }) => {
  const styles = useStyles(HistoryListStyles, {});
  const { t } = useTranslation();

  return (
    <Box sx={styles.root} className="HistoryList-root">
      <Paper
        elevation={0}
        variant="outlined"
        sx={{
          maxHeight: '100%',
          overflowY: 'auto',
          borderRadius: 4,
          marginTop: 2,
          backgroundColor: 'text.primary',
          padding: '20px',
        }}
      >
        <Box>
          <Typography sx={styles.header} variant="h4" color="white">
            {t('History')}
          </Typography>

          <MenuList disablePadding>
            {/* TODO: fix types */}
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any*/}
            {historyData.map((data: any, id: Key | null | undefined) => (
              <MenuItem key={id} onClick={() => onItemClick(data.requestId)} sx={styles.item}>
                {data.orderState === (OrderStateType.ALLOWED || OrderStateType.ALLOW) ? (
                  <ListItemIcon>
                    <CheckCircle color="success" />
                  </ListItemIcon>
                ) : (
                  <ListItemIcon>
                    <HighlightOff color="error" />
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={`${t('Request')} ${new Date(data.createdAt).toLocaleDateString()}`}
                  primaryTypographyProps={{ color: 'white' }}
                />
                {/*<ListItemIcon>*/}
                {/*  <ChevronRight />*/}
                {/*</ListItemIcon>*/}
              </MenuItem>
            ))}
          </MenuList>
        </Box>
      </Paper>
    </Box>
  );
};

export default HistoryList;
