import { ComponentStylesWithFns } from '../../theme/styles.helpers';

export const CustomDataGridStyles: ComponentStylesWithFns = {
  dataGrid: {
    height: '100vh',
    width: '90%',
    border: 'none',
    margin: 'auto',
    '& .MuiDataGrid-overlay': {
      backgroundColor: 'transparent',
    },
    '& .MuiDataGrid-row': {
      borderTop: '1px solid',
      borderColor: 'primary.main',
      fontWeight: '900',
      fontSize: '14px',
      cursor: 'pointer',
      '&.inactive': {
        opacity: '.5',
      },
      '&.Mui-selected': {
        backgroundColor: 'rgba(0,0,0,0.08)',
        '&.Mui-hovered': {
          backgroundColor: 'rgba(0,0,0,0.12)',
        },
      },
      '& .MuiDataGrid-cell': {
        border: 'none',
        outline: 'none',
        fontSize: '16px',
      },
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      textTransform: 'uppercase',
      fontWeight: '900',
      fontSize: '12px',
      color: 'black',
    },
    '& .MuiDataGrid-columnHeaders': {
      border: 'none',
    },
    '& .MuiDataGrid-columnSeparator': {
      opacity: '0 !important',
    },
    '& .MuiDataGrid-columnHeader': {
      outline: 'none !important',
    },
    '& .MuiDataGrid-footerContainer': {
      borderTop: 'none',
    },
  },
};
