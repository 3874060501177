import { Components } from '@mui/material/styles';

const MuiTextFieldOverrides: Components = {
  MuiTextField: {
    defaultProps: {
      variant: 'standard',
    },
    styleOverrides: {
      root: {
        '&.Mui-focused': {
          outline: 'none',
        },
      },
    },
  },
};

export default MuiTextFieldOverrides;
