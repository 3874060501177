import { ComponentStylesWithFns } from '../../../theme/styles.helpers';

export const FormMultiSelectStyles: ComponentStylesWithFns = {
  'FormSelect-root': {
    display: 'inline-block',
  },
  pillGrid: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 0.5,
  },
};
