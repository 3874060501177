import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { ROUTE } from '../../pages/routes';
import { useStyles } from '../../theme/styles.helpers';
import { footerStyles } from './Footer.styles';

const Footer: FC<unknown> = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const styles = useStyles(footerStyles, {});

  return (
    <Box sx={styles.footer}>
      <Link to={'https://hellohair.com/impressum/'}>
        <Typography variant="h4">{t('Imprint')}</Typography>
      </Link>
      |
      <Link to={'https://hellohair.com/datenschutzerklaerung/'}>
        <Typography variant="h4">{t('Privacy Policy')}</Typography>
      </Link>
      {location.pathname !== ROUTE.LOGIN && (
        <>
          |
          <Link to={ROUTE.LOGIN}>
            <Typography variant="h4">{t('Login')}</Typography>
          </Link>
        </>
      )}
    </Box>
  );
};

export default Footer;
