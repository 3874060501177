import { Components } from '@mui/material/styles';
import { palette } from '../palette';

const MuiAccordionOverrides: Components = {
  MuiAccordion: {
    defaultProps: {
      elevation: 0,
    },
    styleOverrides: {
      root: {
        '::before': {
          display: 'none',
        },
        '&:not(.Mui-expanded):not(:last-of-type)': {
          borderBottom: `1px solid ${palette.primary.light}`,
        },
      },
    },
  },
};

export default MuiAccordionOverrides;
