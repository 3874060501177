import { Orders, Utils, Users, Studio } from '@hellohair/types';
import { IUser } from '../aws/AuthApi';
import { DBItem } from '@hellohair/types/dist/utils';
import { OrderRequest } from '@hellohair/types/dist/orders';

export const filterRequestsByDoctor = (
  requests: Utils.DBItem<Orders.OrderRequest>[],
  currentUser: IUser | undefined,
  studioDoctors: Users.BackendUser[],
  isStudioManager: boolean,
  daysInPast: number
) => {
  // TODO: provide default doctor id from environment variable
  const isProduction = window.location.hostname === 'doc.hellohair.com';
  const defaultDoctorId = isProduction
    ? '0394e8a2-a031-7035-cd05-38c3cfa9ade5' // info@ifue-haartransplantation.de (prod)
    : 'f314d822-f011-705b-6b37-4cc79561dc71'; // andreas@hellohair.com (dev)

  let ret: Utils.DBItem<Orders.OrderRequest>[] = [];

  if (currentUser?.roles?.includes('admin')) {
    ret = requests;
    console.log('ret admin: ', ret);
  } else if (currentUser?.id === defaultDoctorId) {
    ret = requests.filter((r) => (r.customer.doctorId || defaultDoctorId) === defaultDoctorId);
    console.log('ret default: ', ret);
  } else if (isStudioManager) {
    ret = requests.filter((r) => studioDoctors.some((doctor) => doctor.sub === r.customer.doctorId));
    console.log('ret manager: ', ret);
  } else {
    ret = requests.filter((r) => r.customer.doctorId === currentUser?.id);
    console.log('ret other: ', ret);
  }

  return ret.filter((r) => new Date(r.createdAt) >= new Date(new Date().setDate(new Date().getDate() - daysInPast)));
};

export const getAllDoctorsFromStudio = (users: Users.BackendUser[], selectedStudio: Studio | null) => {
  return users.filter((user) => {
    const isDoctor = user.groups.includes('doctor');
    const isManager = user.sub === selectedStudio?.managerId;
    const belongsToStudio = user.studio === selectedStudio?.id;
    return isDoctor && (isManager || belongsToStudio);
  });
};

export const getRequestsStats = (requests: Utils.DBItem<Orders.OrderRequest>[]) => {
  return requests.reduce(
    (a, c) => {
      switch (c.orderState) {
        case Orders.OrderRequestState.IDLE:
          a.open++;
          break;
        case Orders.OrderRequestState.ALLOWED:
          a.allowed++;
          break;
        case Orders.OrderRequestState.DENIED:
          a.denied++;
          break;
      }
      return a;
    },
    {
      open: 0,
      allowed: 0,
      denied: 0,
    }
  );
};

export const groupByCurrentAndPreviousMonth = (objects: DBItem<OrderRequest>[]) => {
  const now = new Date();

  // Get the start of the current month
  const startOfCurrentMonth = new Date(now.getFullYear(), now.getMonth(), 1).getTime();

  // Get the start of the previous month
  const startOfPreviousMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1).getTime();

  // Get the end of the previous month (start of the current month)
  const endOfPreviousMonth = startOfCurrentMonth;

  // Initialize the result object
  const result: {
    currentMonth: DBItem<OrderRequest>[];
    previousMonth: DBItem<OrderRequest>[];
  } = {
    currentMonth: [],
    previousMonth: [],
  };

  // Populate the result with objects grouped by month
  objects.forEach((obj) => {
    const createdAt = obj.createdAt;

    if (createdAt >= startOfCurrentMonth) {
      result.currentMonth.push(obj); // Belongs to the current month
    } else if (createdAt >= startOfPreviousMonth && createdAt < endOfPreviousMonth) {
      result.previousMonth.push(obj); // Belongs to the previous month
    }
  });

  return result;
};

export const groupByDayInMonthWithEmpty = (objects: DBItem<OrderRequest>[]) => {
  // Initialize the result with all days as keys and empty arrays
  const result = Array.from({ length: 31 }, (_, i) => i + 1).reduce(
    (acc, day) => {
      acc[day] = [];
      return acc;
    },
    {} as Record<number, DBItem<OrderRequest>[]>
  );

  // Populate the result with objects grouped by day
  objects.forEach((obj) => {
    const date = new Date(obj.createdAt);
    const day = date.getDate();
    result[day].push(obj);
  });

  return result;
};

export const calculateOrderEarning = (request: DBItem<OrderRequest>) => {
  const factor = parseFloat(request.assignedDoctor?.factor || '1');
  const factor2 = parseFloat(request.assignedDoctor?.factor2 || '1');
  const fee = parseFloat(request.assignedDoctor?.fee || '0');
  const fee2 = parseFloat(request.assignedDoctor?.fee2 || '0');
  return factor * fee + factor2 * fee2;
};

export const calculateTotalEarnings = (reqs: DBItem<OrderRequest>[]) => {
  return reqs.reduce((acc, request) => {
    if (request.orderState !== 'ALLOWED') return acc;
    acc += calculateOrderEarning(request);
    return acc;
  }, 0);
};
