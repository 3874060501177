const SurveySingleStyles = {
  root: {
    height: 'calc(90vh - 100px)',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
};

export default SurveySingleStyles;
