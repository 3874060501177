import { ComponentStylesWithFns } from '../../../theme/styles.helpers';

const styles: ComponentStylesWithFns = {
  root: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    marginRight: '5px',
    flexDirection: 'column',
  },
};

export { styles as AnswerChoiceStyles };
