import { JsonDataValue } from '../utils';

export enum StepType {
  Welcome = 'Welcome',
  SingleSelectAnswer = 'SingleSelectAnswer',
  UploadImages = 'UploadImages',
  MultiSelectAnswer = 'MultiSelectAnswer',
  Finish = 'Finish',
  SelectFinish = 'SelectFinish',
  Confirmation = 'Confirmation',
}

export type StepDataBase = {
  id: number;
  // type: string
  title: string;
  question: string;
  next: string;
};

export type StepDataWelcome = StepDataBase & {
  type: StepType.Welcome | string;
  followerId: number;
};

export type StepDataFinish = StepDataBase & {
  type: StepType.Finish | string;
  succeed: boolean;
};

export type StepDataSelectFinish = StepDataBase & {
  type: StepType.SelectFinish | string;
  yes: string;
  no: string;
};

export type SingleTextOption = {
  type: string; //'SingleSelectOptionText',
  option: string;
  followerId: number;
};

export type SingleImageOption = SingleTextOption & {
  imageLabel: string;
  imageURL: string;
};

export type SingleOption = SingleTextOption | SingleImageOption;

export type StepDataSingle = StepDataBase & {
  type: StepType.SingleSelectAnswer | string;
  options: SingleOption[];
};

export type StepDataConfirmation = StepDataBase & {
  type: StepType.Confirmation | string;
  followerId: number;
};

export type StepDataMulti = StepDataBase & {
  type: StepType.MultiSelectAnswer | string;
  options: SingleOption[];
};

export type StepData =
  | StepDataWelcome
  | StepDataSingle
  | StepDataMulti
  | StepDataConfirmation
  | StepDataFinish
  | StepDataSelectFinish
  | JsonDataValue;

export type SurveyTemplate = {
  templateId: string;
  name: string;
  description: string;
  version: string;
  draft: boolean;
  tags: string[];
  steps: StepData[];
};

export type TemplateAddInput = {
  name: string;
  description: string;
};

export type TemplateUpdateInput = {
  templateId: string;
  name?: string;
  description?: string;
  draft?: boolean;
  tags?: string[];
  steps?: StepData[];
};
