import { HubCapsule } from '@aws-amplify/core';
import { Hub } from 'aws-amplify';
import { useContext, useEffect, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTE } from '../pages/routes';
import { AuthContext, AuthState } from './AuthProvider';

/**
 * Redirect app if app state is or changed to one of give {@link AuthState}
 * Redirect app if user does not have the required group
 * @param authStates - {@link AuthState}s to redirect on
 * @param roles - String array for
 * @param to - Route to Redirect to
 */
export function useRedirectOnAuth(authStates: AuthState[], to: ROUTE): AuthState {
  const { authState } = useContext(AuthContext);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (authStates.includes(authState)) {
      console.debug(`[AuthHooks] auth redirect to ${to}`);
      navigate(to);
    }
  }, [authState]); // eslint-disable-line react-hooks/exhaustive-deps

  return authState;
}

/** Event send by AWS Auth Hub */
export enum AuthEvent {
  SIGN_IN = 'signIn',
  SIGN_UP = 'signUp',
  SIGN_OUT = 'signOut',
  SIGN_IN_FAILURE = 'signIn_failure',
  TOKEN_REFRESH = 'tokenRefresh',
  TOKEN_REFRESH_FAILURE = 'tokenRefresh_failure',
  CONFIGURED = 'configured',
}

/**
 * Listen to a specific aws {@link AuthEvent}s
 * Callback is called when given event appears.
 * @param events
 * @param callback - is called if one of given event appears
 */
export function useAuthEvents(callback: (data: HubCapsule) => void, events: AuthEvent[]): Promise<void> {
  /**
   * Has to be named useInPromise to follow {@link https://reactjs.org/docs/hooks-rules.html Rules of Hooks}.
   * If we would call an anonym arrow function eslint-plugin-react-hooks would throw an build error
   */
  const useInPromise = (resolve: (value: void | PromiseLike<void>) => void) => {
    useEffect(() => {
      const subscriptionId = new Date().getTime();
      console.debug(`[AuthHooks] (${subscriptionId}) subscribe to  AuthEvents: `, events);

      const unsubscribe = Hub.listen('auth', (data) => {
        if (events.includes(data.payload.event as AuthEvent)) {
          callback(data);
        }
      });

      resolve();

      return () => {
        console.debug(`[AuthHooks] (${subscriptionId}) unsubscribe from AuthEvents: `, events);
        unsubscribe();
      };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
  };

  return new Promise(useInPromise);
}
