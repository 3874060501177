import { Button, Typography, Link } from '@mui/material';
import Box from '@mui/material/Box';
import { FC, useContext, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/logo.svg';
import { signIn, verifyTOTP } from '../../aws/AuthApi';
import { useRedirectOnAuth } from '../../aws/AuthHooks';
import { AuthContext, AuthState } from '../../aws/AuthProvider';
// import Footer from '../../components/footer/Footer';
import FormInput from '../../components/form-components/form-input/FormInput';
import { useStyles } from '../../theme/styles.helpers';
import { ROUTE } from '../routes';
import { loginStyles } from './login.styles';
import QRCodeCanvas from 'qrcode.react';
import { Auth } from 'aws-amplify';

const Login: FC = () => {
  const [setupMfa, setSetupMfa] = useState<string | undefined>();
  const [loginMfa, setLoginMfa] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const { t } = useTranslation();
  const { control, handleSubmit, getValues } = useForm<FieldValues>();
  const { control: controlMFALogin, handleSubmit: handleSubmitMFALogin, reset: resetMFALogin } = useForm<FieldValues>();
  const { control: controlMFASetup, handleSubmit: handleSubmitMFASetup, reset: resetMFASetup } = useForm<FieldValues>();
  const styles = useStyles(loginStyles, {});

  useRedirectOnAuth([AuthState.AUTHORIZED], ROUTE.DASHBOARD);

  const navigate = useNavigate();
  const {
    cognitoUserState: [user, setCognitoUser],
  } = useContext(AuthContext);

  const onValid: SubmitHandler<FieldValues> = async (data) => {
    try {
      const [_user, code] = await signIn(data.email, data.password);
      setCognitoUser(_user);
      setSetupMfa(code);

      if (_user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        navigate(ROUTE.SET_PASSWORD);
      }
      if (_user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        setLoginMfa(true);
      }
    } catch (_error) {
      console.log('[Login] onValid error', _error);
    }
  };

  const onValidMFALogin: SubmitHandler<FieldValues> = async (data) => {
    try {
      await Auth.confirmSignIn(user, data.code, 'SOFTWARE_TOKEN_MFA');
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (_error: any) {
      if (_error.code) setError(`AWS_MFA_${_error.code}`);
      console.log('[Login] onValidMFALogin error', _error);
    }
  };

  const onValidMFASetup: SubmitHandler<FieldValues> = async (data) => {
    if (user) {
      try {
        await verifyTOTP(user, data.code!);
        await Auth.confirmSignIn(user, data.code, 'SOFTWARE_TOKEN_MFA');
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (_error: any) {
        if (_error.code) setError(`AWS_MFA_${_error.code}`);
        console.log('[Login] onValidMFALogin error', _error);
      }
    } else {
      setError('No user');
    }
  };

  return (
    <Box className="Login-root" sx={styles.root}>
      <Box sx={styles.header}>
        <Box sx={styles.logoContainer}>
          <Box component="img" className="Dummy-Logo" sx={styles.dummyLogo} src={Logo} />
        </Box>
      </Box>

      <Box sx={styles.content}>
        <Box className="Login-heading" sx={styles.heading}>
          <Typography variant="h2"> {t('Login')}</Typography>
        </Box>

        {!setupMfa && !loginMfa && (
          <>
            <Box className="Login-form" sx={styles.form}>
              <form onSubmit={handleSubmit(onValid)}>
                <FormInput name="email" placeholder="E-Mail" control={control} className="w_100 mb-2" />
                <FormInput
                  name="password"
                  type="password"
                  placeholder={t('Password')}
                  control={control}
                  className="w_100 mb-2"
                />
                <Button type="submit" className="mb-3" fullWidth>
                  {t('Login')}
                </Button>
              </form>
            </Box>
            <Link onClick={() => navigate(ROUTE.FORGOT_PASSWORD)} sx={styles.link} color="inherit" fontSize={16}>
              {t('Forgot Password')}
            </Link>
          </>
        )}
        {loginMfa && (
          <>
            <Box className="Login-form" sx={styles.form}>
              <form onSubmit={handleSubmitMFALogin(onValidMFALogin)}>
                <FormInput
                  name="code"
                  placeholder="2FA Code"
                  control={controlMFALogin}
                  className="w_100 mb-2"
                  type="number"
                  inputMode="numeric"
                  pattern="[0-9]{6}"
                  autocomplete="one-time-code"
                  maxLength={6}
                  minLength={6}
                  rules={{ required: true, maxLength: 6, minLength: 6 }}
                  autoFocus
                />
                <Button type="submit" className="mb-3" fullWidth>
                  {t('Confirm')}
                </Button>
              </form>
            </Box>
            {!!error && <Typography color="error.main">{t(error)}</Typography>}
            <Link
              onClick={() => {
                setLoginMfa(false);
                setError(undefined);
                resetMFALogin();
              }}
              sx={styles.link}
              color="inherit"
              fontSize={16}
            >
              {t('Return to login')}
            </Link>
          </>
        )}
        {setupMfa && (
          <>
            <Typography>{t('Scann Code')}</Typography>
            <Box sx={styles.qrCode}>
              <QRCodeCanvas
                value={'otpauth://totp/AWSCognito:' + getValues('email') + '?secret=' + setupMfa + '&issuer=HelloHair'}
              />
            </Box>
            <Box className="Login-form" sx={styles.form}>
              <form onSubmit={handleSubmitMFASetup(onValidMFASetup)}>
                <FormInput
                  name="code"
                  placeholder="2FA Code"
                  control={controlMFASetup}
                  className="w_100 mb-2"
                  type="number"
                  inputMode="numeric"
                  pattern="[0-9]{6}"
                  autocomplete="one-time-code"
                  maxLength={6}
                  minLength={6}
                  rules={{ required: true, maxLength: 6, minLength: 6 }}
                  autoFocus
                />
                <Button type="submit" className="mb-3" fullWidth>
                  {t('Set up')}
                </Button>
              </form>
            </Box>
            {!!error && <Typography color="error.main">{t(error)}</Typography>}
            <Link
              onClick={() => {
                setSetupMfa(undefined);
                setError(undefined);
                resetMFASetup();
              }}
              sx={styles.link}
              color="inherit"
              fontSize={16}
            >
              {t('Return to login')}
            </Link>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Login;
