import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FC, useContext, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import MessageTwoToneIcon from '@mui/icons-material/MessageTwoTone';
import { TabPanel } from '../../Functions/TabPanel';
import { useLayout, useUpdateLayout } from '../../../../legacy/provider/layoutProvider/LayoutProvider';
import { DataContext } from '../../../../aws/DataProvider';
import { Templates, Utils } from '@hellohair/types';

interface WelcomeProps {
  survey: Templates.SurveyTemplate;
  index: number;
  className?: string;
}

const Welcome: FC<WelcomeProps> = ({ survey, index }) => {
  const formattedIndex = index.toLocaleString('de-DE', { minimumIntegerDigits: 2 });

  const expanded = useLayout();
  const setCurrentExpanded = useUpdateLayout();

  const {
    formDetailsState: [, setCurrentSurvey],
  } = useContext(DataContext);

  const { type, question, next, followerId, id } = survey.steps[index] as Templates.StepDataWelcome;
  const questions = survey.steps;
  const [localQuestion, setLocalQuestion] = useState<string>(question);
  const [localNext, setLocalNext] = useState<string>(next);
  const [localFollowerId, setLocalFollowerId] = useState<number>(followerId);

  const [tab, setTab] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  function a11yProps(_index: number) {
    return {
      id: `simple-tab-${_index}`,
      'aria-controls': `simple-tabpanel-${_index}`,
    };
  }

  const handleSelectChange = (event: SelectChangeEvent) => {
    setLocalFollowerId(Number(event.target.value));
  };

  const saveElement = () => {
    const newState = [...survey.steps];
    newState[index] = {
      id: id,
      type: type,
      question: localQuestion,
      next: localNext,
    } as Templates.StepDataWelcome;
    if (localFollowerId > -1) newState[index].followerId = localFollowerId;
    setCurrentSurvey({ ...survey, steps: newState } as Utils.DBItem<Templates.SurveyTemplate>);
  };

  const deleteElement = () => {
    const newState = [...survey.steps];
    newState.splice(index, 1);
    setCurrentSurvey({ ...survey, steps: newState } as Utils.DBItem<Templates.SurveyTemplate>);
  };

  return (
    <Draggable draggableId={id.toString()} index={index}>
      {/*eslint-disable-next-line @typescript-eslint/no-explicit-any*/}
      {(provided: any) => (
        <Accordion
          expanded={expanded === index}
          onChange={setCurrentExpanded(index)}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Box className="AccordionSummary-index" display="flex" alignItems="center">
              <Typography variant="body2" sx={{ width: '30px' }} fontWeight="bold">
                {formattedIndex}
              </Typography>
              <Icon sx={{ width: '30px', marginRight: '10px' }}>
                <MessageTwoToneIcon />
              </Icon>
              <Box className="AccordionSummary-question">
                <Typography
                  variant="body2"
                  sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    display: '-webkit-box',
                    '-webkit-line-clamp': '2',
                    lineClamp: '2',
                    '-webkit-box-orient': 'vertical',
                  }}
                >
                  {question}
                </Typography>
              </Box>
            </Box>
          </AccordionSummary>

          <AccordionDetails>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
                <Tab label={`Edit (ID: ${id})`} {...a11yProps(0)} />
                <Tab label="Logic" {...a11yProps(1)} />
                <Tab label="Settings" {...a11yProps(2)} />
              </Tabs>
            </Box>

            <TabPanel value={tab} index={0}>
              <TextField
                value={localQuestion}
                label="Welcome Message"
                fullWidth
                onChange={(e) => setLocalQuestion(e.target.value)}
                multiline
                size="small"
                margin="dense"
              ></TextField>
            </TabPanel>

            <TabPanel value={tab} index={1}>
              <FormControl fullWidth margin="dense">
                <InputLabel id="labelType">Next Question</InputLabel>
                <Select
                  labelId="labelType"
                  id="selectType"
                  value={`${followerId}`}
                  label="Next Question"
                  onChange={handleSelectChange}
                  fullWidth
                  size="small"
                >
                  {/*eslint-disable-next-line @typescript-eslint/no-explicit-any*/}
                  {questions.map((item: any, i: number) => {
                    return (
                      <MenuItem id={`select_${i}`} key={item.question} value={item.id}>
                        {item.question}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </TabPanel>

            <TabPanel value={tab} index={2}>
              <TextField
                value={localNext}
                label="Button label"
                fullWidth
                onChange={(e) => setLocalNext(e.target.value)}
                size="small"
                margin="dense"
              ></TextField>
            </TabPanel>

            <Box sx={{ display: 'grid', gap: 1 }}>
              <Button onClick={saveElement} fullWidth>
                <Typography variant="h3">save element</Typography>
              </Button>
              <Button onClick={deleteElement} fullWidth sx={{ background: '#FF3030!important' }}>
                <Typography variant="h3">delete element</Typography>
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
    </Draggable>
  );
};

export default Welcome;
