import { Box, Typography } from '@mui/material';
import { CSSProperties, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRedirectOnAuth } from '../../aws/AuthHooks';
import { AuthContext, AuthState } from '../../aws/AuthProvider';
import { useStyles } from '../../theme/styles.helpers';
import { ROUTE } from '../routes';
import { ProfileStyles } from './Profile.Styles';
import FormInput from '../../components/form-components/form-input/FormInput';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { CognitoUser } from '@aws-amplify/auth';
import { updateUser } from '../../aws/AuthApi';
import { SaveButton } from '../../components/button-components/save-button/SaveButton';

const ProfileForm = ({ user, attributes }: { user: CognitoUser; attributes: Record<string, string> }) => {
  const styles = useStyles(ProfileStyles, {});
  const { t } = useTranslation();
  const { handleSubmit, control } = useForm<Record<string, string>>({ defaultValues: attributes });
  const [inFlight, setInFlight] = useState(false);

  const onSubmit: SubmitHandler<Record<string, string>> = async (data) => {
    setInFlight(true);
    await updateUser(user!, data);
    setInFlight(false);
  };

  const onError: SubmitErrorHandler<Record<string, string>> = (errors) => {
    console.log('[Profile] - onError', errors);
  };

  return (
    <Box
      sx={{
        marginX: 'auto',
        width: '90%',
      }}
    >
      <form onSubmit={handleSubmit(onSubmit, onError)} style={styles.form as CSSProperties}>
        <Box display={'d-flex'} justifyContent={'space-between'} alignItems={'center'} height={112}>
          <Typography variant="h3">{t('Profile')}</Typography>
          <SaveButton type={'submit'} disabled={inFlight} />
        </Box>
        <Box
          width={['100%', '100%', '100%', '66.6%']}
          bgcolor={'white'}
          borderRadius={4}
          padding={'40px'}
          margin={'0 auto'}
        >
          <Box display={'grid'} gridTemplateColumns={'.5fr .5fr 1fr 1fr'} gap={3} className={'mb_40'}>
            <FormInput className="w_100" name="custom:title" label={t('Title')} control={control} />
            <FormInput className="w_100" name="gender" label={t('Gender')} control={control} />
            <FormInput
              className="w_100"
              name="given_name"
              label={t('Firstname')}
              control={control}
              rules={{ required: true }}
            />
            <FormInput
              className="w_100"
              name="family_name"
              label={t('Lastname')}
              control={control}
              rules={{ required: true }}
            />
          </Box>

          <Box display={'grid'} gridTemplateColumns={'1fr'} gap={3} className={'mb_40'}>
            <FormInput
              className="w_100"
              name="custom:job"
              label={t('Job description')}
              control={control}
              rules={{ required: true }}
            />
          </Box>

          <Box display={'grid'} gridTemplateColumns={'1fr 1fr'} gap={3} className={'mb_40'}>
            <FormInput
              className="w_100"
              name="email"
              label={t('E-Mail')}
              control={control}
              rules={{ required: true }}
              disabled
            />
            <FormInput className="w_100" name="custom:contact_phone" label={t('Phonenumber')} control={control} />
          </Box>

          <Box display={'grid'} gridTemplateColumns={'1fr .2fr'} gap={3} className={'mb_40'}>
            <FormInput className="w_100" name="custom:street" label={t('Street')} control={control} />
            <FormInput className="w_100" name="custom:house_number" label={t('No.')} control={control} />
          </Box>

          <Box display={'grid'} gridTemplateColumns={'.2fr 1fr'} gap={3}>
            <FormInput className="w_100" name="custom:zip" label={t('ZIP')} control={control} />
            <FormInput className="w_100" name="custom:city" label={t('City')} control={control} />
          </Box>
        </Box>
      </form>
    </Box>
  );
};

const Profile = () => {
  const styles = useStyles(ProfileStyles, {});

  useRedirectOnAuth([AuthState.UNAUTHORIZED], ROUTE.LOGIN);

  const { t } = useTranslation();

  const {
    cognitoUserState: [user],
  } = useContext(AuthContext);

  return (
    <Box sx={styles.container}>
      {!user && (
        <Box sx={styles.header}>
          <Typography variant="h3">{t('Profile')}</Typography>
        </Box>
      )}

      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      {user && <ProfileForm user={user} attributes={(user as any).attributes} />}
    </Box>
  );
};

export default Profile;
