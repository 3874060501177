import { CognitoUser } from '@aws-amplify/auth';
import { Auth, Hub } from 'aws-amplify';
import AWSAppSyncClient from 'aws-appsync';
import { createContext, Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { AuthEvent, formatUser, getCurrentAuthenticatedUser, IUser } from './AuthApi';
import { useAuthEvents } from './AuthHooks';
import { configureAWS, initGraphQLApiClient, NormalizedCacheObject } from './aws.config';

export enum AuthState {
  UNKNOWN,
  AUTHORIZED,
  UNAUTHORIZED,
}

interface AppUser {
  username: string;
  email: string;
}
interface AuthContextSchema {
  authState: AuthState;
  cognitoUserState: [CognitoUser | undefined, Dispatch<SetStateAction<CognitoUser | undefined>>];
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>;
  appUser?: AppUser;
  userAttributes?: IUser;
}

interface Props {
  children: React.ReactNode;
}

export const AuthContext = createContext<AuthContextSchema>({
  authState: AuthState.UNKNOWN,
  cognitoUserState: [
    undefined,
    () => {
      console.error('Should not have happened');
    },
  ],
});

const AuthProvider: FC<Props> = ({ children }) => {
  const [authState, setAuthState] = useState<AuthState>(AuthState.UNKNOWN);
  const cognitoUserState = useState<CognitoUser>();
  const [apiClient, setApiClient] = useState<AWSAppSyncClient<NormalizedCacheObject> | undefined>();
  const [, setCognitoUser] = cognitoUserState;
  const [appUser, setAppUser] = useState<AppUser>();
  const [userAttributes, setUserAttributes] = useState<IUser>();

  const getAppUser = async () => {
    const {
      username: _username,
      attributes: { email: _usermail },
    } = await Auth.currentUserInfo();
    const _appUser = { username: _username, email: _usermail };
    if (_appUser) {
      setAppUser(_appUser);
    }
  };

  const handleAuthSuccess = async (user: CognitoUser) => {
    setAuthState(user ? AuthState.AUTHORIZED : AuthState.UNAUTHORIZED);
    setCognitoUser(user);
    const _apiClient = await initGraphQLApiClient();
    setApiClient(_apiClient);
    getAppUser();
  };

  useEffect(() => {
    Hub.listen('auth', async (data) => {
      if (data.payload.event === 'configured') {
        console.log('[AuthProvider] - Configured with', data.payload);
      }
    });

    configureAWS();
  }, []);

  /**
   * Handle sign out event.
   * Set user state to null.
   * Navigate to login.
   */
  useAuthEvents(async () => {
    setCognitoUser(undefined);
    setAuthState(AuthState.UNAUTHORIZED);
    setApiClient(undefined);
    setAppUser(undefined);
    setUserAttributes(undefined);
  }, [AuthEvent.SIGN_OUT]);

  useAuthEvents(async () => {
    try {
      const cognitoUser = (await getCurrentAuthenticatedUser()) as CognitoUser;

      if (!cognitoUser) {
        setAuthState(AuthState.UNAUTHORIZED);
        return;
      }
      const attributes = await formatUser(cognitoUser);
      setUserAttributes(attributes);
      await handleAuthSuccess(cognitoUser);
    } catch (error) {
      console.log('[AuthProvider]', error);
    }
  }, [AuthEvent.CONFIGURED]).then(configureAWS); // wait for listener to be registered

  /** Handle sign in events */
  useAuthEvents(
    async (data) => {
      try {
        console.log('[AuthProvider] sign in event received', data.payload);
        const attributes = await formatUser(data.payload?.data);
        setUserAttributes(attributes);
        await handleAuthSuccess(data.payload?.data);
      } catch (error) {
        console.log('[AuthProvider]', error);
      }
    },
    [AuthEvent.SIGN_IN]
  );

  return (
    <AuthContext.Provider value={{ authState, cognitoUserState, apiClient, appUser, userAttributes }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
