import './App.css';
import './fonts/stylesheet.css';
import Box from '@mui/material/Box';
import { FC, useContext, useMemo } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { appStyles } from './App.styles';
import Sidebar from './components/side-bar/Sidebar';
import Login from './pages/login/Login';
import SetPassword from './pages/login/SetPassword';
import NotFound from './pages/notFound/NotFound';
import OrderRequests from './pages/order-requests/OrderRequests';
import Templates from './pages/templates/Templates';
import { ROUTE } from './pages/routes';
import Settings from './pages/settings/Settings';
import UserPanel from './pages/userPanel/UserPanel';
import Users from './pages/users/Users';
import { useStyles } from './theme/styles.helpers';
import Consultations from './pages/consultations/Consultations';
import Dashboard2 from './pages/dash-board/Dashboard2';
import Patients from './pages/patients/Patients';
import Template from './pages/template/Template';
import ForgotPassword from './pages/login/ForgotPassword';
import Profile from './pages/profile/Profile';
import { AuthContext, AuthState } from './aws/AuthProvider';
import { useRedirectOnAuth } from './aws/AuthHooks';
import { CircularProgress } from '@mui/material';
import Studios from './pages/studios/Studios';
import { DataContext } from './aws/DataProvider';
import Analytics from './pages/analytics/Analytics';

const Protected: FC<{ children: React.ReactNode; accessRoles: string[]; enabledForStudioManager?: boolean }> = ({
  children,
  accessRoles,
  enabledForStudioManager,
}) => {
  const styles = useStyles(appStyles, {});
  const auth = useRedirectOnAuth([AuthState.UNAUTHORIZED], ROUTE.LOGIN);
  const {
    studiosState: [studios],
  } = useContext(DataContext);
  const { userAttributes } = useContext(AuthContext);

  const isStudioManager =
    userAttributes?.roles.includes('doctor') &&
    !!studios.find((studio) => studio.managerId === userAttributes?.id) &&
    enabledForStudioManager;
  // if user is a studio manager and the link is for studio managers as well, return true

  if (!isStudioManager && !userAttributes?.roles?.some((role) => accessRoles.includes(role))) return null;

  if (auth !== AuthState.AUTHORIZED)
    return (
      <Box sx={styles.auth}>
        <CircularProgress size={64} sx={styles.spinner} thickness={2.5} />
      </Box>
    );
  return <>{children}</>;
};

const App: FC = () => {
  const styles = useStyles(appStyles, {});
  const { authState } = useContext(AuthContext);
  const location = useLocation();

  const hasSidebar = useMemo(
    () =>
      authState === AuthState.AUTHORIZED &&
      (location.pathname === ROUTE.REQUESTS ||
        location.pathname === ROUTE.PATIENTS ||
        location.pathname === ROUTE.DASHBOARD ||
        location.pathname === ROUTE.FORMS ||
        location.pathname === ROUTE.FORM ||
        location.pathname === ROUTE.CONSULTATIONS ||
        location.pathname === ROUTE.USERS ||
        location.pathname === ROUTE.PROFILE ||
        location.pathname === ROUTE.ANALYTICS ||
        location.pathname === ROUTE.STUDIOS ||
        location.pathname === ROUTE.SETTINGS),
    [location, authState]
  );

  return (
    <Box sx={styles.root} className="App-root">
      {hasSidebar && (
        <Box sx={styles.sidebar} className="App-sidebar">
          <Sidebar />
          <UserPanel />
        </Box>
      )}
      <Box sx={styles.content} className="App-content" style={{ marginLeft: !hasSidebar ? '0px' : '260px' }}>
        <Routes>
          <Route path="*" element={<NotFound />} />

          <Route path={ROUTE.LOGIN} element={<Login />} />
          <Route path={ROUTE.SET_PASSWORD} element={<SetPassword />} />
          <Route path={ROUTE.FORGOT_PASSWORD} element={<ForgotPassword />} />
          <Route
            path={ROUTE.DASHBOARD}
            element={
              <Protected accessRoles={['support', 'doctor', 'admin']}>
                <Dashboard2 />
              </Protected>
            }
          />
          <Route
            path={ROUTE.ANALYTICS}
            element={
              <Protected accessRoles={['admin']}>
                <Analytics />
              </Protected>
            }
          />
          <Route
            path={ROUTE.FORMS}
            element={
              <Protected accessRoles={['support', 'admin']}>
                <Templates />
              </Protected>
            }
          />

          <Route
            path={ROUTE.REQUESTS}
            element={
              <Protected accessRoles={['doctor', 'admin']}>
                <OrderRequests />
              </Protected>
            }
          />
          <Route
            path={ROUTE.PATIENTS}
            element={
              <Protected accessRoles={['support', 'admin']}>
                <Patients />
              </Protected>
            }
          />

          <Route
            path={ROUTE.FORM}
            element={
              <Protected accessRoles={['support', 'admin']}>
                <Template />
              </Protected>
            }
          />
          <Route
            path={ROUTE.CONSULTATIONS}
            element={
              <Protected accessRoles={['doctor', 'admin']}>
                <Consultations />
              </Protected>
            }
          />

          <Route
            path={ROUTE.USERS}
            element={
              <Protected accessRoles={['admin']} enabledForStudioManager={true}>
                <Users />
              </Protected>
            }
          />

          <Route
            path={ROUTE.STUDIOS}
            element={
              <Protected accessRoles={['admin']} enabledForStudioManager={false}>
                <Studios />
              </Protected>
            }
          />

          <Route
            path={ROUTE.PROFILE}
            element={
              <Protected accessRoles={['support', 'doctor', 'admin']}>
                <Profile />
              </Protected>
            }
          />
          <Route
            path={ROUTE.SETTINGS}
            element={
              <Protected accessRoles={['support', 'doctor', 'admin']}>
                <Settings />
              </Protected>
            }
          />
        </Routes>
      </Box>
    </Box>
  );
};

export default App;
