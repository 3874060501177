import PersonIcon from '@mui/icons-material/Person';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { GridRowParams } from '@mui/x-data-grid';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRedirectOnAuth } from '../../aws/AuthHooks';
import { AuthContext, AuthState } from '../../aws/AuthProvider';
import { DataContext } from '../../aws/DataProvider';
import { AddButton } from '../../components/button-components/add-button/AddButton';
import { CustomDataGrid } from '../../components/data-grid/CustomDataGrid';
import { CustomModal } from '../../components/modal/CustomModal';
import UserForm from '../../components/modals/user-form/UserForm';
import { useStyles } from '../../theme/styles.helpers';
import { ROUTE } from '../routes';
import { UsersStyles } from './Users.Styles';
import { Users as UsersTypes } from '@hellohair/types';
import { deleteUser, listUsers, updateUser } from '../../aws/UsersApi';
import DeleteIcon from '@mui/icons-material/Delete';

interface UserListRow {
  gender: string;
  name: string;
  email: string;
  phoneNumber: string;
  user: UsersTypes.BackendUser;
  roles: string;
  isActive?: boolean;
  statusWithTranslation: string;
  id: string;
}

const Users: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { t } = useTranslation();

  const styles = useStyles(UsersStyles, {});

  const {
    usersState: [users, setUsers],
    userDetailsState: [, setUserDetails],
    studiosState: [studios],
  } = useContext(DataContext);
  const { userAttributes } = useContext(AuthContext);
  const isStudioManager =
    userAttributes?.roles.includes('doctor') && !!studios.find((studio) => studio.managerId === userAttributes?.id);

  useRedirectOnAuth([AuthState.UNAUTHORIZED], ROUTE.LOGIN);
  const { apiClient } = useContext(AuthContext);

  // rows
  const [rows, setRows] = useState<UserListRow[]>();

  const closeModal = () => {
    setIsModalOpen(false);
    setUserDetails(undefined);
  };

  useEffect(() => {
    // if user is a studio manager, show only users from his studio and himself
    if (isStudioManager) {
      const studioUsers = users.filter(
        (user) => user.studio === studios.find((studio) => studio.managerId === userAttributes?.id)?.id
      );
      const studioManager = users.find((user) => user.sub === userAttributes?.id);
      const allStudioUsers = studioManager ? [studioManager, ...studioUsers] : [];
      const _rows = allStudioUsers.map(transformToRow);
      setRows(_rows);
    } else {
      const _rows = users.map(transformToRow);
      setRows(_rows);
    }
  }, [users, isStudioManager]);

  const transformToRow: (user: UsersTypes.BackendUser) => UserListRow = (user: UsersTypes.BackendUser) => {
    const row: UserListRow = {
      gender: user.gender,
      name: user.firstName + ' ' + user.lastName,
      phoneNumber: user.phoneNumber,
      email: user.email,
      user,
      roles: user.groups.join(', '),
      isActive: user.isActive,
      statusWithTranslation: user.isActive ? t('Active') : t('Inactive'),
      id: user.sub,
    };

    return row;
  };

  const handleRowClicked = (params: GridRowParams) => {
    const { user } = params.row as UserListRow;
    setUserDetails(user);
    setIsModalOpen(true);
  };

  const disableUser = useCallback(
    (_user: UsersTypes.BackendUser) => async () => {
      try {
        await updateUser(
          {
            sub: _user.sub,
            isActive: false,
          },
          apiClient
        );

        const _users = await listUsers(apiClient, 'network-only');
        setUsers(_users);
      } catch (error) {
        console.log('[DemosView] - error:', error);
      }
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const enableUser = useCallback(
    (_user: UsersTypes.BackendUser) => async () => {
      try {
        await updateUser(
          {
            sub: _user.sub,
            isActive: true,
          },
          apiClient
        );

        const _users = await listUsers(apiClient, 'network-only');
        setUsers(_users);
      } catch (error) {
        console.log('[DemosView] - error:', error);
      }
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const _deleteUser = useCallback(
    (id: string) => async () => {
      try {
        await deleteUser(id, apiClient);
        const _users = await listUsers(apiClient, 'network-only');
        setUsers(_users);
      } catch (error) {
        console.log('[Users] - error:', error);
      }
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const columns = useMemo(
    () => [
      { field: 'email', headerName: t('E-Mail'), filterable: true, flex: 1 },
      { field: 'gender', headerName: t('Gender'), filterable: false },
      { field: 'name', headerName: t('Name'), filterable: false, flex: 1 },
      { field: 'phoneNumber', headerName: t('Phone'), filterable: false, flex: 1 },
      { field: 'roles', headerName: t('Roles'), filterable: false, flex: 1 },
      { field: 'statusWithTranslation', headerName: t('Status'), filterable: false },
      {
        field: 'actions',
        type: 'actions',
        filterable: false,
        width: 40,
        getActions: (params: GridRowParams<UserListRow>) => [
          // eslint-disable-next-line react/jsx-key
          <GridActionsCellItem
            key="enable"
            icon={<PersonIcon />}
            label={params.row.isActive ? t('Disable User') : t('Enable User')}
            onClick={params.row.isActive ? disableUser(params.row.user) : enableUser(params.row.user)}
            showInMenu
            sx={styles.item}
          />,
          <GridActionsCellItem
            key="delete"
            icon={<DeleteIcon />}
            label={t('Delete User')}
            onClick={_deleteUser(params.row.user.sub)}
            showInMenu
            sx={styles.item}
          />,
        ],
      },
    ],
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Box sx={styles.container}>
      <Box sx={styles.header}>
        <Typography variant="h3">{t('Users')}</Typography>
        <AddButton onClick={() => setIsModalOpen(true)} />
      </Box>

      <CustomDataGrid
        columns={columns}
        rows={rows || []}
        onRowClick={handleRowClicked}
        isCellEditable={() => false}
        isRowSelectable={() => false}
        getRowId={(row) => row.email}
        pagination
        autoPageSize
        sx={styles.row}
        initialState={{
          sorting: {
            sortModel: [{ field: 'id', sort: 'desc' }],
          },
        }}
      />

      <CustomModal isModalOpen={isModalOpen} onModalClose={closeModal} variant="large">
        <UserForm onModalClose={closeModal} />
      </CustomModal>
    </Box>
  );
};

export default Users;
