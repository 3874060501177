import LogoutIcon from '@mui/icons-material/Logout';
import { Avatar, SvgIcon, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import avatar from '../../assets/dummy/avatar.png';
import { signOut } from '../../aws/AuthApi';
import { AuthContext } from '../../aws/AuthProvider';
import { useStyles } from '../../theme/styles.helpers';
import { ROUTE } from '../routes';
import { userPanelStyles } from './userPanel.styles';

const UserPanel: FC = () => {
  const styles = useStyles(userPanelStyles, {});
  const { appUser, userAttributes } = useContext(AuthContext);
  const navigate = useNavigate();
  const [userAbbreviation, setUserAbbreviation] = useState<string>();

  const handleLogout = async () => {
    if (await signOut()) {
      navigate(ROUTE.LOGIN);
    }
  };

  useEffect(() => {
    if (!userAttributes) return;
    setUserAbbreviation(
      userAttributes.firstName.slice(0, 1).toUpperCase() + userAttributes.lastName.slice(0, 1).toUpperCase()
    );
  }, [userAttributes]);

  return (
    <Box className="UserPanel-root" sx={styles.root}>
      <Avatar className="UserPanel-avatar" sx={styles.avatar} src={avatar}>
        {userAbbreviation}
      </Avatar>
      <Box className="UserPanel-userInfo" sx={styles.userInfo}>
        <Typography sx={styles.userInfoDisplay} variant="h4" color="white">
          {userAttributes?.firstName + ' ' + userAttributes?.lastName}
        </Typography>
        <Typography sx={styles.userInfoDisplay} variant="h5" color="primary.light">
          {appUser?.email}
        </Typography>
      </Box>
      <Box className="UserPanel-logout" sx={styles.logout}>
        <SvgIcon component={LogoutIcon} sx={styles.logoutIcon} onClick={handleLogout}></SvgIcon>
      </Box>
    </Box>
  );
};

export default UserPanel;
