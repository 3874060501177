import { ComponentStylesWithFns } from '../../theme/styles.helpers';

export const SettingsStyles: ComponentStylesWithFns = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    overscrollBehavior: 'none',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '112px',
    marginX: 'auto',
    width: '90%',
  },
  main: {
    marginX: 'auto',
    width: '90%',
  },
  buildDate: {
    color: 'primary.contrastText',
    marginBottom: '10px',
  },
  group: {
    button: {
      borderColor: 'text.primary',
    },
    'button.Mui-selected': {
      backgroundColor: 'text.primary',
      color: 'white',
      '&:hover': {
        backgroundColor: 'text.primary',
      },
    },
  },
  setting: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
};
