import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { CSSProperties, useEffect, useState } from 'react';
import { useStyles } from '../../../theme/styles.helpers';
import { Styles } from './styles';
import { useForm } from 'react-hook-form';
import FormInput from '../../form-components/form-input/FormInput';
import FormTextArea from '../../form-components/form-textarea/FormTextArea';
import { Users } from '@hellohair/types';

type Props = {
  member: Users.BackendUser;
  isManager: boolean;
  onClose: () => void;
};

const MemberForm = ({ member, isManager, onClose }: Props) => {
  const { t } = useTranslation();
  const styles = useStyles(Styles, {});
  const { handleSubmit, control, setValue } = useForm<{
    firstName: string;
    lastName: string;
    content: string;
    testimonial_author: string;
    testimonial: string;
  }>();
  const [loading, setLoading] = useState(true);

  // const aa = {
  //   id: 594,
  //   content: 'this is a content',
  //   title: 'this is a title',
  //   post_type: 'doctor',
  //   position: 'chefarzt',
  //   testimonial_author: 'this is a testimonial author',
  //   testimonial: 'this is a testimonial',
  // };

  useEffect(() => {
    if (member) {
      setValue('firstName', member.firstName);
      setValue('lastName', member.lastName);
      setValue('content', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.');
      setValue('testimonial_author', 'this is a testimonial author');
      setValue('testimonial', 'this is a testimonial');
      setLoading(false);
    }
  }, [member]);

  console.log('isManager: ', isManager);

  const onSubmit = () => {
    console.log('submit');
  };

  const onError = () => {
    console.log('error');
  };

  console.log('member: ', member);

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)} style={styles.form as CSSProperties}>
      <Box>
        <Box display={'grid'} gridTemplateColumns={'1fr 1fr'} gap={3}>
          <FormInput
            disabled={loading}
            className="w_100 mg_h_20"
            name="firstName"
            label={t('Firstname')}
            control={control}
          />
          <FormInput
            disabled={loading}
            className="w_100 mg_h_20"
            name="lastName"
            label={t('Lastname')}
            control={control}
          />
        </Box>
        <FormTextArea
          disabled={loading}
          className="w_100 mg_h_20"
          name="content"
          label={t('Content')}
          control={control}
          maxRows={2}
        />
        <FormTextArea
          disabled={loading}
          className="w_100 mg_h_20"
          name="testimonial"
          label={t('Testimonial')}
          control={control}
          maxRows={2}
        />
        <FormInput
          disabled={loading}
          className="w_100 mg_h_20"
          name="testimonial_author"
          label={t('Testimonial author')}
          control={control}
        />
      </Box>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          <Typography variant="h3">{t('Close')}</Typography>
        </Button>
        <Button type="submit">
          <Typography variant="h3">{t('Done')}</Typography>
        </Button>
      </Box>
    </form>
  );
};

export default MemberForm;
