import { Box, CircularProgress, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { getReturnsForCustomer } from '../../../aws/RequestsApi';
import { AuthContext } from '../../../aws/AuthProvider';
import { useStyles } from '../../../theme/styles.helpers';
import { UserRequestHistoryStyles } from './UserRequestHistory.Styles';
import { Orders } from '@hellohair/types';
import { CustomDataGrid } from '../../data-grid/CustomDataGrid';
import { GridRowParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

type Props = {
  customerId: string;
};

const CustomerReturns = ({ customerId }: Props) => {
  const { t } = useTranslation();
  const { apiClient } = useContext(AuthContext);
  const styles = useStyles(UserRequestHistoryStyles, {});
  const [returns, setReturns] = useState<Orders.UserReturn[]>([]);
  const [details, setDetails] = useState<Orders.UserReturn | null>(null);
  const [loadingOrderHistory, setLoadingOrderHistory] = useState(true);

  useEffect(() => {
    getReturnsForCustomer(customerId, apiClient)
      .then((res) => {
        setLoadingOrderHistory(false);
        setReturns(res);
      })
      .catch((err) => console.error('err: ', err));
  }, []);

  const showDetails = (row: GridRowParams) => {
    setDetails(returns.find((r) => r.orderNumber === row.id) || null);
  };

  const columns = [
    { field: 'product', headerName: t('Order'), filterable: false, flex: 1 },
    { field: 'date', headerName: t('Date'), type: 'date', filterable: false, flex: 1 },
  ];

  const rows = returns.map((r) => ({
    id: r.orderNumber,
    product: r.items.map((i) => i.description).join(', '),
    date: r.createdAt ? new Date(r.createdAt) : new Date(r.created),
  }));

  if (loadingOrderHistory) {
    return (
      <Box sx={styles.grid}>
        <CircularProgress size={32} sx={styles.spinner} thickness={5} />
      </Box>
    );
  }

  return (
    <Box sx={styles.grid}>
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, width: '50%' }}>
        <CustomDataGrid
          columns={columns}
          rows={rows}
          onRowClick={showDetails}
          getRowId={(row) => row.id}
          isCellEditable={() => false}
          pagination
          autoPageSize
        />
      </Box>

      <Box sx={styles.detailsPane}>
        {details && (
          <Box>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography fontWeight="bold">{t('Order number')}</Typography>
              <Typography>{details.orderNumber}</Typography>
            </Box>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography fontWeight="bold">{t('Date')}</Typography>
              <Typography>{new Date(details.createdAt || details.created).toLocaleDateString()}</Typography>
            </Box>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography fontWeight="bold">{t('Preis')}</Typography>
              <Typography>{(details.total_amount / 100).toFixed(2)}€</Typography>
            </Box>
            <Box display={'flex'} justifyContent={'space-between'} gap={2}>
              <Typography fontWeight="bold">{t('Order')}</Typography>
              <Typography>{details.items.map((i) => i.description).join(', ')}</Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CustomerReturns;
