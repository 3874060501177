import { CSSProperties, useContext } from 'react';
import Box from '@mui/material/Box';
import FormInput from '../../form-components/form-input/FormInput';
import FormSelect from '../../form-components/form-select/FormSelect';
import { Button, SvgIcon, Typography } from '@mui/material';
import { useStyles } from '../../../theme/styles.helpers';
import { Styles } from './styles';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { Studio, Users } from '@hellohair/types';
import { createStudio, updateStudio } from '../../../aws/RequestsApi';
import { AuthContext } from '../../../aws/AuthProvider';
import { DataContext } from '../../../aws/DataProvider';
import { useTranslation } from 'react-i18next';
import { ArrowRight } from '@mui/icons-material';
import { getAllDoctorsFromStudio } from '../../../helpers/requests';

type Props = {
  onModalClose: () => void;
  onSelectMember: (member: Users.BackendUser) => void;
  selectedStudio: Studio | null;
  isStudioManager: boolean;
};

const StudioForm = ({ selectedStudio, onSelectMember, onModalClose, isStudioManager }: Props) => {
  const styles = useStyles(Styles, {});
  const { t } = useTranslation();
  const { apiClient } = useContext(AuthContext);
  const {
    usersState: [users],
    studiosState: [studios, setStudios],
  } = useContext(DataContext);
  const { handleSubmit, control } = useForm<Studio>({
    defaultValues: {
      ...selectedStudio,
    },
  });

  const allDoctorsFromThisStudio = getAllDoctorsFromStudio(users, selectedStudio);
  const doctors = users.filter((user) => user.groups.includes('doctor'));

  // onSubmit
  const onSubmit: SubmitHandler<Studio> = async (data) => {
    if (selectedStudio) {
      const u: Studio = {
        id: selectedStudio.id,
        name: data.name,
        description: data.description,
        managerId: data.managerId,
      };
      updateStudio(u, apiClient)
        .then((id) => {
          const updatedStudio = {
            id: id,
            ...data,
          };

          const updatedStudios = studios.map((studio) => (studio.id === id ? updatedStudio : studio));
          setStudios(updatedStudios);
          onModalClose();
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      createStudio(data, apiClient)
        .then((id) => {
          const newStudio = {
            id: id,
            ...data,
          };

          setStudios([...studios, newStudio]);
          onModalClose();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const onError: SubmitErrorHandler<Users.BackendUser> = (errors) => {
    console.log('[UserDetailView] - onError', errors);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)} style={styles.form as CSSProperties}>
      <Box>
        <FormInput className="w_100 mg_h_20" name="name" label={t('Name')} control={control} />
        <FormInput className="w_100 mg_h_20" name="description" label={t('Description')} control={control} />
        {/* Studio managers can not change managers, only admin can do it */}
        {!isStudioManager && (
          <FormSelect
            className="w_100 mg_h_20"
            name="managerId"
            label={t('Studio manager')}
            control={control}
            defaultValue={'-'}
            options={[
              { value: t('No manager'), id: '-' },
              ...doctors
                // .filter((d) => {
                //   const isAlreadyManager = studios.find((studio) => studio.managerId === d.sub);
                //   // only show doctors that are not already managers
                //   return !isAlreadyManager;
                // })
                .map((d) => ({ id: d.sub, value: `${d.firstName} ${d.lastName}` })),
            ]}
          />
        )}

        <Box mt={2}>
          <Typography mb={1} variant="h3">
            {t('Team')}:
          </Typography>
          {allDoctorsFromThisStudio.map((doctor) => {
            const isManager = doctor.sub === selectedStudio?.managerId;
            return (
              <Box key={doctor.sub} display={'flex'} justifyContent={'space-between'}>
                <Typography>
                  {doctor.firstName + ' ' + doctor.lastName} - ({doctor.email})
                </Typography>
                <Box display="flex" gap={4}>
                  <Box>
                    <Typography>{isManager ? t('Studio manager') : t('User')}</Typography>
                  </Box>
                  <Box onClick={() => onSelectMember(doctor)} display="flex" sx={{ cursor: 'pointer' }}>
                    <Typography>Edit</Typography>
                    <SvgIcon sx={{ color: 'secondary.main' }} component={ArrowRight}></SvgIcon>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Button
        fullWidth
        type="submit"
        sx={{
          marginTop: 3,
        }}
      >
        <Typography variant="h3">{t('Done')}</Typography>
      </Button>
    </form>
  );
};

export default StudioForm;
