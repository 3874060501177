import { createTheme, PaletteOptions } from '@mui/material';

export const paletteOptions: PaletteOptions = {
  mode: 'light',
  primary: {
    main: '#f8ede6',
    light: '#E5E4E0',
    dark: '#C9C7C2',
    contrastText: '#FFF',
  },
  secondary: {
    main: '#9E9C98',
    // main: '#212325',
    light: '#C4C4C4',
    dark: '#51504D',
    contrastText: '#000',
  },
  warning: {
    main: '#EB9516',
  },
  error: {
    main: '#df1b41',
    light: '#FFBBBB',
    dark: '#791616',
  },
  success: {
    main: '#38AA66',
  },
  text: {
    primary: '#000',
    secondary: '#51504D',
  },
  info: {
    main: '#24577D',
    light: '#B9E1FF',
    dark: '#0F202D',
  },
  grey: {
    200: '#CECECE',
    600: '#8F8F8F',
    800: '#6A6A6A',
  },
};

export const palette = createTheme({ palette: paletteOptions }).palette;
