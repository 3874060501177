export enum TreatmentState {
  UNKNOWN = 'UNKNOWN',
  IDLE = 'IDLE',
  STARTED = 'STARTED',
  FINISHED = 'FINISHED',
  SUSPENDED = 'SUSPENDED',
}

export enum TreatmentTransType {
  CUSTOMER = 'CUSTOMER',
  BACKEND = 'BACKEND',
  SCHEDULE = 'SCHEDULE',
}

export type TreatmentTransaction = {
  treatmentTransId: string;
  treatmentId: string;
  name: string;
  eventType: TreatmentTransType;
  createdAt: number;
  consumedAmount: number;
  replacementAmount: number;
  productId: string;
};

export type Treatment = {
  treatmentId: string;
  requestId: string;
  treatmentType: 'SINGLE' | 'MULTIPLE';
  name: string;
  customerId: string;
  createdAt: number;
  expiryDate: number;
  consumableAmount: number;
  consumedAmount: number;
  replacementAmount: number;
  transactions: TreatmentTransaction[];
  state: TreatmentState;
  surveyTag: string;
};

export type ConsumeTreatmentParams = {
  treatmentId: string;
  paymentId: string;
  compoundAmount: number;
  eventType: TreatmentTransType;
  productIds: string[];
};
