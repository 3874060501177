import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRedirectOnAuth } from '../../aws/AuthHooks';
import { AuthContext, AuthState } from '../../aws/AuthProvider';
import { DataContext } from '../../aws/DataProvider';
import { useStyles } from '../../theme/styles.helpers';
import { ROUTE } from '../routes';
import { TemplatesStyles } from './Templates.Styles';
import { Templates as TemplatesTypes, Utils } from '@hellohair/types';
import { useNavigate } from 'react-router-dom';
import { createTemplate, getTemplate } from '../../aws/TemplatesApi';
import { AddButton } from '../../components/button-components/add-button/AddButton';

const Templates: FC = () => {
  const { t } = useTranslation();
  const styles = useStyles(TemplatesStyles, {});

  const {
    formsState: [products],
    formDetailsState: [, setFormDetails],
  } = useContext(DataContext);
  const { apiClient } = useContext(AuthContext);

  useRedirectOnAuth([AuthState.UNAUTHORIZED], ROUTE.LOGIN);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const editTemplate = async (template: Utils.DBItem<TemplatesTypes.SurveyTemplate>) => {
    if (loading) return;

    setLoading(true);
    console.log('Fetching survey');
    try {
      const _survey = await getTemplate(template.templateId, apiClient, 'network-only');

      setFormDetails(_survey);
      navigate(ROUTE.FORM);
    } catch (e) {
      console.error('Error on fetch', e);
      setLoading(false);
    }
  };

  const addTemplate = async () => {
    const res = await createTemplate(
      {
        name: '',
        description: '',
      },
      apiClient
    );
    if (res) editTemplate({ templateId: res } as Utils.DBItem<TemplatesTypes.SurveyTemplate>);
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.header}>
        <Typography variant="h3">{t('Forms')}</Typography>
        <AddButton onClick={addTemplate} />
      </Box>

      <Box sx={styles.container}>
        {products.map((template, index) => (
          <Box
            key={index}
            onClick={() => editTemplate(template)}
            sx={{
              opacity: loading ? 0.5 : 1,
              cursor: 'pointer',
              ...styles.item,
            }}
          >
            <Typography variant="h4">{template.name}</Typography>
            <Typography variant="body2">{new Date(template.createdAt).toLocaleString()}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Templates;
