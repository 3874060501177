import { ComponentStylesWithFns } from '../../theme/styles.helpers';

export const ProfileStyles: ComponentStylesWithFns = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    overscrollBehavior: 'none',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '112px',
    marginX: 'auto',
    width: '90%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  grid: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  gridItem: {
    display: 'inline-block',
    width: 'calc(50% - 10px)',
  },
  gridItem13: {
    display: 'inline-block',
    width: 'calc(20% - 10px)',
  },
  gridItem14: {
    display: 'inline-block',
    width: 'calc(25% - 10px)',
  },
  gridItem23: {
    display: 'inline-block',
    width: 'calc(40% - 10px)',
  },
  gridItem32: {
    display: 'inline-block',
    width: 'calc(80% - 10px)',
  },
  gridItemFull: {
    display: 'inline-block',
    width: '100%',
  },
};
