import { DataGrid, DataGridProps, GridValidRowModel } from '@mui/x-data-grid';
import { useStyles } from '../../theme/styles.helpers';
import { CustomDataGridStyles } from './CustomDataGrid.Styles';

export const CustomDataGrid = <T extends GridValidRowModel>(props: DataGridProps<T>) => {
  const styles = useStyles(CustomDataGridStyles, {});
  const style = { ...styles.dataGrid, ...props.sx };

  return <DataGrid {...props} sx={style} />;
};
