import gql from 'graphql-tag';

export const PRODUCT_THUMBNAIL = gql`
  mutation ProductThumbnail($id: String!, $key: String!) {
    productThumbnail(id: $id, key: $key)
  }
`;

export const CREATE_PRODUCT = gql`
  mutation AddProduct($productInput: ProductAddInput!) {
    addProduct(productInput: $productInput)
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation UpdateProduct($productUpdate: ProductUpdateInput!) {
    updateProduct(productUpdate: $productUpdate)
  }
`;

export const DELETE_PRODUCT = gql`
  mutation DeleteProduct($id: String!) {
    deleteProduct(id: $id)
  }
`;

export const CREATE_REQUEST = gql`
  mutation AddRequest($requestKey: String!) {
    addRequest(requestKey: $requestKey)
  }
`;

export const SEND_NOTIFICATION = gql`
  mutation UpdateRequest($requestId: String!, $action: String!) {
    updateRequest(requestId: $requestId, action: $action)
  }
`;

export const SEND_NOTIFICATION_FOLLOWUP = gql`
  mutation UpdateFollowup($requestId: String!, $action: String!) {
    updateFollowup(requestId: $requestId, action: $action)
  }
`;

export const SEND_COMMENT = gql`
  mutation UpdateRequest($requestId: String!, $action: String!, $comment: String!) {
    updateRequest(requestId: $requestId, action: $action, comment: $comment)
  }
`;

export const SEND_COMMENT_FOLLOWUP = gql`
  mutation UpdateFollowup($requestId: String!, $action: String!, $comment: String!) {
    updateFollowup(requestId: $requestId, action: $action, comment: $comment)
  }
`;

export const DELETE_REQUEST = gql`
  mutation DeleteRequest($id: String!) {
    deleteRequest(id: $id)
  }
`;

export const REGISTER_REQUEST = gql`
  mutation RegisterRequest($code: String!) {
    register(code: $code)
  }
`;

export const CREATE_USER = gql`
  mutation AddUser($userInput: UserAddInput!) {
    addUser(userInput: $userInput)
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($userUpdateInput: UserUpdateInput!) {
    updateUser(userUpdateInput: $userUpdateInput)
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($id: String!) {
    deleteUser(userId: $id)
  }
`;

export const CREATE_CUSTOMER = gql`
  mutation AddCustomer($customerInput: CustomerAddInput!) {
    addCustomer(customerInput: $customerInput)
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer($customerUpdate: CustomerUpdateInput!) {
    updateCustomer(customerUpdate: $customerUpdate)
  }
`;

export const ENABLE_CUSTOMER = gql`
  mutation EnableCustomer($action: String!, $customerId: String!) {
    enableCustomer(action: $action, customerId: $customerId)
  }
`;

export const DISABLE_CUSTOMER = gql`
  mutation DisableCustomer($action: String!, $customerId: String!) {
    disableCustomer(action: $action, customerId: $customerId)
  }
`;

export const DELETE_CUSTOMER = gql`
  mutation DeleteCustomer($id: String!) {
    deleteCustomer(id: $id)
  }
`;

export const S3_SIGN_MUTATION = gql`
  mutation SignS3($filename: String!, $filetype: String!, $category: String!) {
    signS3(filename: $filename, filetype: $filetype, category: $category) {
      url
      path
      signedRequest
    }
  }
`;

export const DELETE_USER_FILE = gql`
  mutation DeleteUserFiles($list: [String!]!) {
    deleteUserFiles(list: $list) {
      path
    }
  }
`;

export const CREATE_APP_STREAM_USER = gql`
  mutation AddAppsUser($appsUserInput: AppsUserAddInput!) {
    addAppsUser(appsUserInput: $appsUserInput)
  }
`;

export const ADD_APP_STREAM_USER_TO_STACK = gql`
  mutation addAppsUserToStack($userStackInput: UserStackInput!) {
    addAppsUserToStack(userStackInput: $userStackInput)
  }
`;

export const REMOVE_APP_STREAM_USER_FROM_STACK = gql`
  mutation removeAppsUserFromStack($userStackInput: UserStackInput!) {
    removeAppsUserFromStack(userStackInput: $userStackInput)
  }
`;

export const INVOKE_APP_STREAM_USER_ACTION = gql`
  mutation invokeAppsUserAction($action: String!, $email: String!) {
    invokeAppsUserAction(action: $action, email: $email)
  }
`;

export const ADD_USER_TO_GROUP = gql`
  mutation addUserToGroup($userGroupInput: UserGroupInput!) {
    addUserToGroup(userGroupInput: $userGroupInput)
  }
`;

export const REMOVE_USER_FROM_GROUP = gql`
  mutation removeUserFromGroup($userGroupInput: UserGroupInput!) {
    removeUserFromGroup(userGroupInput: $userGroupInput)
  }
`;

export const CREATE_TEMPLATE = gql`
  mutation CreateTemplate($templateAddInput: TemplateAddInput!) {
    createTemplate(templateAddInput: $templateAddInput)
  }
`;

export const UPDATE_TEMPLATE = gql`
  mutation UpdateTemplate($templateUpdateInput: TemplateUpdateInput!) {
    updateTemplate(templateUpdateInput: $templateUpdateInput) {
      templateId
      name
      description
      version
      createdAt
      tags
      steps
    }
  }
`;

export const DELETE_TEMPLATE = gql`
  mutation deleteTemplate($templateId: String!) {
    deleteTemplate(templateId: $templateId)
  }
`;

export const CREATE_CONSULTATION_EVENT = gql`
  mutation addConsultation($eventAddInput: ConsultationAddInput!) {
    addConsultation(eventAddInput: $eventAddInput)
  }
`;

export const DELETE_CONSULTATION_EVENT = gql`
  mutation deleteConsultation($eventId: String!) {
    deleteConsultation(eventId: $eventId)
  }
`;

export const PLACE_CONSULTATION_EVENT = gql`
  mutation placeConsultation($dateTime: String!, $duration: Int!) {
    placeConsultation(dateTime: $dateTime, duration: $duration) {
      id
    }
  }
`;

export const BOOK_CONSULTATION_EVENT = gql`
  mutation bookConsultation($eventId: String!) {
    bookConsultation(eventId: $eventId) {
      id
      summary
    }
  }
`;

export const GENERATE_REZEPT = gql`
  mutation generateRezept($data: RezeptInput!) {
    generateRezept(data: $data) {
      members {
        id
        email
        isSigner
      }
    }
  }
`;

export const CREATE_STUDIO = gql`
  mutation createStudio($studio: StudioInput!) {
    createStudio(studio: $studio)
  }
`;

export const UPDATE_STUDIO = gql`
  mutation updateStudio($studio: StudioInput!) {
    updateStudio(studio: $studio)
  }
`;

export const UPDATE_PRESCRIPTION_STATE = gql`
  mutation updatePrescriptionState($customerId: String!, $newState: String!) {
    updatePrescriptionState(customerId: $customerId, newState: $newState)
  }
`;

export const ASSIGN_DOCTOR_ID_TO_CUSTOMER = gql`
  mutation assignDoctorIdToCustomer($customerId: String!, $doctorId: String!) {
    assignDoctorIdToCustomer(customerId: $customerId, doctorId: $doctorId)
  }
`;

export const PAUSE_SUBSCRIPTION = gql`
  mutation pauseSubscription($subscriptionId: String!) {
    pauseSubscription(subscriptionId: $subscriptionId)
  }
`;
