import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import {
  getCustomerPrescriptionState,
  getStripeSubscriptions,
  pauseSubscription,
  updateCustomerPrescriptionState,
} from '../../../aws/RequestsApi';
import { AuthContext } from '../../../aws/AuthProvider';
import { useStyles } from '../../../theme/styles.helpers';
import { UserRequestHistoryStyles } from './UserRequestHistory.Styles';
import { useTranslation } from 'react-i18next';
import { DataContext } from '../../../aws/DataProvider';
import { Users } from '@hellohair/types';

enum PrescriptionState {
  IDLE = 'IDLE',
  AWAITING = 'AWAITING', // Subscriptions
  AWAITING_ONE_TIME = 'AWAITING-ONE-TIME', // One time order
  ACTIVE = 'ACTIVE', // Subscriptions
  ACTIVE_ONE_TIME = 'ACTIVE-ONE-TIME', // One time order
  PAUSED = 'PAUSED',
  FOLLOWUP = 'FOLLOWUP',
}

const PrescriptionStatus = ({ customerId, stripeId }: { customerId: string; stripeId: string }) => {
  const { apiClient } = useContext(AuthContext);
  const { t } = useTranslation();
  const styles = useStyles(UserRequestHistoryStyles, {});
  const [loading, setLoading] = useState<boolean>(true);
  const [confirmReset, setConfirmReset] = useState<boolean>(false);
  const [confirmPause, setConfirmPause] = useState<boolean>(false);
  const {
    prescriptionState: [prescriptionState, setPrescriptionState],
    subscriptionsState: [subscriptionsState, setSubscriptionsState],
  } = useContext(DataContext);

  useEffect(() => {
    if (customerId) {
      getCustomerPrescriptionState(customerId || '', apiClient, 'network-only').then((res) => {
        setPrescriptionState(res as PrescriptionState);
      });
    }
  }, [customerId]);

  useEffect(() => {
    if (stripeId) {
      getStripeSubscriptions(stripeId, apiClient, 'network-only').then((res) => {
        setSubscriptionsState(res as Users.Subscription[]);
      });
    }
  }, [stripeId]);

  useEffect(() => {
    if (subscriptionsState && prescriptionState) {
      setLoading(false);
    }
  }, [subscriptionsState, prescriptionState]);

  const resetPrescriptionState = () => {
    updateCustomerPrescriptionState(customerId, PrescriptionState.IDLE, apiClient).then((res) => {
      if (res) setPrescriptionState(PrescriptionState.IDLE);
      setConfirmReset(false);
    });
  };

  const prescriptionSubscription = subscriptionsState?.find((sub) => !sub.product.freeProduct);

  const handlePauseSubscription = () => {
    if (!prescriptionSubscription) return;

    pauseSubscription(prescriptionSubscription.id, apiClient).then((res) => {
      if (res) setPrescriptionState(PrescriptionState.FOLLOWUP);
      setConfirmPause(false);
    });
  };

  return (
    <Box
      bgcolor={'#4881d7'}
      color={'white'}
      py={2}
      px={4}
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      {loading && <Typography>Loading...</Typography>}
      {prescriptionState && (
        <>
          <Typography>
            {t('Treatment status')}: {prescriptionState}
          </Typography>

          <Box display={'flex'} gap={2}>
            {prescriptionState !== PrescriptionState.IDLE && !confirmReset && !confirmPause && (
              <Button onClick={() => setConfirmReset(true)} sx={styles.button}>
                {t('Set to pending')}
              </Button>
            )}

            {(prescriptionState === PrescriptionState.IDLE || prescriptionState === PrescriptionState.ACTIVE) &&
              prescriptionSubscription &&
              !confirmPause &&
              !confirmReset && (
                <Button onClick={() => setConfirmPause(true)} sx={styles.button}>
                  {t('Pause subscription')}
                </Button>
              )}
          </Box>

          {confirmPause && (
            <Box display={'flex'} gap={2}>
              <Button onClick={handlePauseSubscription} sx={styles.button}>
                {t('Confirm')}
              </Button>
              <Button onClick={() => setConfirmPause(false)} sx={styles.button}>
                {t('Cancel')}
              </Button>
            </Box>
          )}

          {confirmReset && (
            <Box display={'flex'} gap={2}>
              <Button onClick={resetPrescriptionState} sx={styles.button}>
                {t('Confirm')}
              </Button>
              <Button onClick={() => setConfirmReset(false)} sx={styles.button}>
                {t('Cancel')}
              </Button>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default PrescriptionStatus;
