import { TextField } from '@mui/material';
import { ChangeEvent, CSSProperties, HTMLInputTypeAttribute } from 'react';
import { Control, Controller, ControllerProps } from 'react-hook-form';
import { useStyles } from '../../../theme/styles.helpers';
import { FormInputStyles } from './FormInput.Styles';

interface FormInputProps extends Partial<ControllerProps> {
  defaultValue?: string;
  disabled?: boolean;
  label?: string;
  type?: HTMLInputTypeAttribute;
  control?: Control<any, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  className?: string;
  style?: CSSProperties;
  minRows?: number;
  min?: string;
  max?: string;
  minLength?: number;
  maxLength?: number;
  step?: string;
  multiline?: boolean;
  noShrink?: boolean;
  placeholder?: string;
  onChange?: (value: string) => void;
  autocomplete?: string;
  inputMode?: 'search' | 'text' | 'email' | 'tel' | 'url' | 'none' | 'numeric' | 'decimal';
  pattern?: string;
  autoFocus?: boolean;
}

const FormInput: React.FC<FormInputProps> = ({
  name,
  control,
  rules,
  type,
  label,
  disabled,
  minRows = 4,
  multiline,
  className,
  style,
  defaultValue = '',
  min,
  max,
  minLength,
  maxLength,
  step,
  noShrink = false,
  placeholder,
  onChange: _onChange,
  autocomplete,
  inputMode,
  pattern,
  autoFocus,
}) => {
  const styles = useStyles(FormInputStyles, {});

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (value: string) => void
  ) => {
    const {
      target: { value },
    } = event;

    _onChange?.(value);
    onChange(value);
  };

  return (
    <div className="form-input" style={style}>
      <Controller
        name={name ?? ''}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            sx={placeholder ? styles.inputNoHeight : styles.input}
            InputLabelProps={{ shrink: !noShrink, sx: { textIndent: '10px' } }}
            className={className}
            disabled={disabled}
            value={value}
            type={type}
            onChange={(event) => handleChange(event, onChange)}
            label={label}
            placeholder={placeholder ? placeholder : label}
            variant="outlined"
            size={placeholder ? 'medium' : 'small'}
            minRows={minRows}
            multiline={multiline}
            error={error !== undefined}
            helperText={error?.message}
            InputProps={{ inputProps: { min, max, minLength, maxLength, step, pattern } }}
            autoComplete={autocomplete}
            inputMode={inputMode}
            autoFocus={autoFocus}
          />
        )}
      />
    </div>
  );
};

export default FormInput;
