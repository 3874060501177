import { ComponentStylesWithFns } from '../../theme/styles.helpers';

export const NotFoundStyles: ComponentStylesWithFns = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    height: '100vh',
    backgroundColor: 'primary.main',
    alignItems: 'center',
    justifyContent: 'center',
  },
  box: {
    width: '600px',
    backgroundColor: 'primary.light',
    padding: '20px',
    borderRadius: '5px',
    textAlign: 'center',
  },
  spacer: {
    flexGrow: 1,
  },
  dummyLogo: {
    marginY: '36px',
    marginX: '40px',
    width: '240px',
  },
};
