import React, { useContext, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useStyles } from '../../../theme/styles.helpers';
import { UserRequestHistoryStyles } from './UserRequestHistory.Styles';
import { CustomDataGrid } from '../../data-grid/CustomDataGrid';
import { useTranslation } from 'react-i18next';
import { Users } from '@hellohair/types';
import { DataContext } from '../../../aws/DataProvider';

const SubscriptionsHistory = () => {
  const { t } = useTranslation();
  const styles = useStyles(UserRequestHistoryStyles, {});
  const {
    subscriptionsState: [subscriptionsState],
  } = useContext(DataContext);
  const [details, setDetails] = useState<Users.Subscription | null>(null);

  if (!subscriptionsState) {
    return (
      <Box sx={styles.grid} className="OrderDetails-grid">
        <CircularProgress size={32} sx={styles.spinner} thickness={5} />
      </Box>
    );
  }

  const isSubActive = details && details.status === 'active' && !details.paused;

  return (
    <Box sx={styles.grid} className="OrderDetails-grid">
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, width: '50%' }}>
        <CustomDataGrid
          columns={[
            { field: 'product', headerName: t('Subscription'), filterable: false, flex: 1 },
            { field: 'status', headerName: 'Status', filterable: false, flex: 1 },
            { field: 'date', headerName: t('Date'), type: 'date', filterable: false, flex: 1 },
          ]}
          rows={subscriptionsState.map((sub) => ({
            id: sub.id,
            order: sub.product,
            date: new Date(sub.created * 1000),
            product: sub.product.name,
            status: (sub.paused ? t('Paused') : sub.status.split('_').join(' ')).toUpperCase(),
          }))}
          onRowClick={(a) => {
            setDetails(subscriptionsState.find((s) => s.id === a.id) || null);
          }}
          getRowClassName={(params) => (params.row.status !== 'ACTIVE' ? 'inactive' : '')}
          getRowId={(row) => row.id}
          isCellEditable={() => false}
          pagination
          autoPageSize
        />
      </Box>
      <Box sx={styles.detailsPane}>
        {details && (
          <Box>
            <Box display={'flex'} justifyContent={'space-between'} marginBottom={2}>
              <Typography fontWeight="bold">{details.product.name}</Typography>
              {details.paused && <Typography fontWeight="bold">{t('Paused').toUpperCase()}</Typography>}
              {!details.paused && (
                <Typography fontWeight="bold">{details.status.split('_').join(' ').toUpperCase()}</Typography>
              )}
            </Box>

            <Box marginBottom={2}>
              <Typography variant="body2">
                {t('Preis')}: {(details.price.unit_amount / 100).toFixed(2)} {details.price.currency.toUpperCase()}
              </Typography>
              <Typography variant="body2">
                {details.payment_method.type.toUpperCase()} {details.payment_method.identifier}
              </Typography>
            </Box>
            <Box marginBottom={2}>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Typography variant="body2">{t('Creation Date')}:</Typography>
                <Typography variant="body2">{new Date(details.created * 1000).toLocaleString('de-DE')}</Typography>
              </Box>

              {isSubActive && (
                <Box display={'flex'} justifyContent={'space-between'} mb={2}>
                  <Typography variant="body2">{t('Next appointment')}:</Typography>
                  <Typography variant="body2">
                    {new Date(details.current_period_end * 1000).toLocaleString('de-DE')}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SubscriptionsHistory;
