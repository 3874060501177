import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useStyles } from '../../../theme/styles.helpers';
import { UserRequestHistoryStyles } from './UserRequestHistory.Styles';
import { DataContext } from '../../../aws/DataProvider';
import { assignDoctorIdToCustomer } from '../../../aws/RequestsApi';
import { AuthContext } from '../../../aws/AuthProvider';

type Props = {
  customerId: string;
};

const AssignedDoctor = ({ customerId }: Props) => {
  // const { t } = useTranslation();
  const { apiClient } = useContext(AuthContext);
  const styles = useStyles(UserRequestHistoryStyles, {});
  const {
    usersState: [users],
    customersState: [customers, setCustomers],
  } = useContext(DataContext);
  const assignedDoctorId = customers.find((cus) => cus.customerId === customerId)?.doctorId;
  const assignedDoctor = users.find((user) => user.sub === assignedDoctorId);
  const doctors = users.filter((user) => user.groups.includes('doctor'));

  const assignDoctorToCustomer = (doctorId: string) => {
    assignDoctorIdToCustomer(customerId, doctorId, apiClient).then((res) => {
      if (res) {
        const updatedCustomers = customers.map((cus) => {
          if (cus.customerId === customerId) {
            return { ...cus, doctorId };
          }
          return cus;
        });

        setCustomers(updatedCustomers);
      }
    });
  };

  return (
    <Box sx={styles.grid} className="OrderDetails-grid">
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, width: '50%', padding: 4 }}>
        <FormControl fullWidth>
          <InputLabel
            sx={{
              textIndent: '10px',
            }}
            id="doctor-label"
          >
            Arzt auswählen
          </InputLabel>
          <Select
            labelId="doctor-label"
            id="doctor"
            value={assignedDoctorId || '-'}
            label="Arzt"
            onChange={(e) => assignDoctorToCustomer(e.target.value)}
          >
            <MenuItem value="-">Kein Arzt</MenuItem>
            {doctors.map((doctor) => (
              <MenuItem key={doctor.sub} value={doctor.sub}>
                {doctor.firstName} {doctor.lastName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={styles.detailsPane}>
        {assignedDoctor && (
          <>
            <Typography variant="h4"> Angaben zum Arzt::</Typography>

            <Box>
              <Typography variant="body2">ID:</Typography>
              <Typography variant="subtitle1">{assignedDoctor.sub}</Typography>
            </Box>

            <Box>
              <Typography variant="body2">E-Mail:</Typography>
              <Typography variant="subtitle1">{assignedDoctor.email}</Typography>
            </Box>

            <Box>
              <Typography variant="body2">Name:</Typography>
              <Typography variant="subtitle1">{assignedDoctor.firstName + ' ' + assignedDoctor.lastName}</Typography>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default AssignedDoctor;
