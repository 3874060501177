import { ComponentStylesWithFns } from '../../../theme/styles.helpers';

export const UserRequestHistoryStyles: ComponentStylesWithFns = {
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    boxSizing: 'border-box',
    overflowX: 'hidden',
    backgroundColor: 'primary.main',
    marginLeft: 5,
    borderRadius: '10px',
  },
  header: {
    display: 'flex',
    padding: 3,
    backgroundColor: 'white',
    borderBottom: '0.5px solid',
    borderColor: 'grey.200',
    position: 'sticky',
    top: 0,
    zIndex: 100,
  },
  headerTitle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  grid: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
  },
  spinner: {
    color: 'primary.dark',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-16px',
    marginLeft: '-16px',
    zIndex: 100,
  },
  detailsPane: {
    padding: 4,
    width: '50%',
    background: 'white',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
  },
  empty: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },
  button: {
    height: 30,
    paddingY: 0,
    lineHeight: 0,
    fontSize: 16,
  },
};
