import { ComponentStylesWithFns } from '../../../theme/styles.helpers';

export const Styles: ComponentStylesWithFns = {
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    boxSizing: 'border-box',
    overflowX: 'hidden',
    backgroundColor: 'primary.main',
    marginLeft: 5,
    borderRadius: '10px',
  },
  header: {
    display: 'flex',
    padding: 3,
    backgroundColor: 'white',
    borderBottom: '0.5px solid',
    borderColor: 'grey.200',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerTitle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '20px 30px',
    marginBottom: '20px',
    flexGrow: 1,
    maxHeight: 'calc(100% - 60px)',
    overflowY: 'auto',
    scrollbarColor: 'black #f6ebe4',
    scrollbarWidth: 'thin',
  },
  grid: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 3,
  },
};
