import { ComponentStylesWithFns } from '../../theme/styles.helpers';

export const loginStyles: ComponentStylesWithFns = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    height: '100vh',
    backgroundColor: 'primary.main',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    position: 'fixed',
    width: '100%',
    margin: 'auto',
    zIndex: 3,
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    top: '0px',
    overflow: 'hidden',
    padding: '30px 0',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid transparent',
  },
  logoContainer: {
    height: '50px',
    display: 'flex',
    alignItems: 'center',
  },
  dummyLogo: {
    width: '188px',
    marginLeft: '63px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  heading: {
    marginBottom: '20px',
  },
  form: {
    width: '500px',
    maxWidth: '100%',
  },
  link: {
    fontSize: '18px',
    lineHeight: '26px',
    fontWeight: 200,
    paddingBlock: '1px',
    cursor: 'pointer',
  },
  qrCode: {
    borderRadius: '40px',
    padding: '40px',
    background: 'white',
    display: 'flex',
    margin: '20px',
  },
};
