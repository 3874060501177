export enum CustomerAddressType {
  BILLING = 'BILLING',
  DELIVERY = 'DELIVERY',
  // HOME = 'HOME',
}

export type CustomerAddress = {
  customerId: string;
  // customerAddressId: string;
  addressType: CustomerAddressType;
  givenName: string;
  familyName: string;
  streetAddress: string;
  houseNumber: string;
  locality: string;
  postalCode: string;
  country: string;
  // default: boolean;
};

export interface CustomerAddressesResponse {
  [CustomerAddressType.DELIVERY]: CustomerAddress;
  [CustomerAddressType.BILLING]?: CustomerAddress;
}
