import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useRedirectOnAuth } from '../../aws/AuthHooks';
import { AuthState } from '../../aws/AuthProvider';
import { useStyles } from '../../theme/styles.helpers';
import { ROUTE } from '../routes';
import { AnalyticsStyles } from './Analytics.Styles';

const Analytics: FC = () => {
  const { t } = useTranslation();
  const styles = useStyles(AnalyticsStyles, {});
  useRedirectOnAuth([AuthState.UNAUTHORIZED], ROUTE.LOGIN);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.header}>
        <Typography variant="h3">{t('Analytics')}</Typography>
      </Box>
    </Box>
  );
};

export default Analytics;
