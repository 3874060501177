/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, TextField } from '@mui/material';
import { FC } from 'react';
import { AnswerChoiceStyles } from './AnswerChoice.styles';
import { useStyles } from '../../../theme/styles.helpers';

interface AnswerChoiceProps {
  i: number;
  option: any;
  changeAnswer: any;
  changeImage: any;
  className?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AnswerChoice: FC<AnswerChoiceProps> = ({ i, option, changeAnswer, changeImage, className }) => {
  const styles = useStyles(AnswerChoiceStyles, {});

  return (
    <Box className="AnswerChoice-root" sx={styles.root}>
      {option.type === 'SingleSelectOptionImage' && (
        <TextField
          fullWidth
          value={option.imageURL}
          onChange={(e: any) => changeImage(i, e)}
          label="Answer"
          margin="dense"
          size="small"
        ></TextField>
      )}
      <TextField
        fullWidth
        value={option.option}
        onChange={(e: any) => changeAnswer(i, e)}
        label="Answer"
        margin="dense"
        size="small"
      ></TextField>
    </Box>
  );
};
export default AnswerChoice;
