/**
 * handle version string from timestamp
 * @param ts : timestamp
 * @returns
 */
export function HandleVersion(ts: number): string {
  try {
    return new Date(ts).toISOString().split('.')[0];
  } catch (error) {
    throw new Error('FatalError, Wrong timestamp');
  }
}
