export enum ConsultationEventType {
  CONVERSATION = 'CONVERSATION',
  INTERVIEW = 'INTERVIEW',
}

export type ConsultationBase = {
  consultationId: string;
  name: string;
  date: string;
  durationInMin: number;
  customerId: string;
  subject: string;
  eventType: ConsultationEventType;
};

export type ConsultationRequest = ConsultationBase & object;

export type Consultions = ConsultationRequest;
