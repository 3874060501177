import { Box, Chip, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { CSSProperties, useState } from 'react';
import { Control, Controller, ControllerProps } from 'react-hook-form';
import { useStyles } from '../../../theme/styles.helpers';
import { FormMultiSelectStyles } from './FormMultiSelect.Styles';

interface FormMultiSelectProps extends Partial<ControllerProps> {
  options: { id: string; displayName?: string; value: string }[];
  disabled?: boolean;
  defaultValue?: string[];
  label?: string;
  className?: string;
  style?: CSSProperties;
  control: Control<any, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  onChange?: (value: string[]) => void;
}

const FormMultiSelect: React.FC<FormMultiSelectProps> = ({
  name,
  label,
  control,
  options,
  rules,
  disabled = false,
  defaultValue = [],
  className = '',
  style,
  onChange: _onChange,
}) => {
  const styles = useStyles(FormMultiSelectStyles, {});
  const [internalValue, setInternalValue] = useState<string[]>(defaultValue);
  // const { watch } = useFormContext();

  const handleChange = (event: SelectChangeEvent<string[]>, onChange: (value: string[]) => void) => {
    const {
      target: { value },
    } = event;

    if (!value || value === internalValue) return;

    _onChange?.(typeof value === 'string' ? value.split(',') : value);
    onChange(typeof value === 'string' ? value.split(',') : value);
    setInternalValue(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <Box className="FormSelect-root" sx={style}>
      <Controller
        name={name ?? ''}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <FormControl margin="dense" size="small" className={className}>
            <InputLabel sx={{ textIndent: '10px' }} id={`label-multi-select-${name}`}>
              {label}
            </InputLabel>
            <Select
              multiple
              labelId={`label-multi-select-${name}`}
              label={label}
              value={value}
              onChange={(event) => handleChange(event, onChange)}
              size="small"
              disabled={disabled}
              renderValue={(selected) => (
                <Box>
                  {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    selected.map((_value: any) => (
                      <Chip
                        key={_value}
                        label={options.find((_option) => _option.value === _value)?.displayName ?? _value}
                      />
                    ))
                  }
                </Box>
              )}
            >
              {options.map((option) => (
                <MenuItem key={option.id} value={option.value} sx={styles.item}>
                  {option.displayName ?? option.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
    </Box>
  );
};

export default FormMultiSelect;
