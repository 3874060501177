import { ComponentStylesWithFns } from '../../../theme/styles.helpers';

const styles: ComponentStylesWithFns = {
  root: {
    position: 'absolute',
    top: 56,
    zIndex: 1000,
    height: 'calc(80vh - 56px)',
  },
  header: {
    height: 32,
    padding: 2,
    backgroundColor: 'black',
  },
  item: {
    backgroundColor: 'black',
    padding: 2,
    '&:hover': {
      backgroundColor: 'black',
    },
  },
};

export { styles as HistoryListStyles };
