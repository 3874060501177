import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Auth } from 'aws-amplify';
import { FC, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormInput from '../../components/form-components/form-input/FormInput';
import { useStyles } from '../../theme/styles.helpers';
import { setPasswordStyles } from './ForgotPassword.Styles';
import Logo from '../../assets/logo.svg';
import { Link, Typography } from '@mui/material';
import { ROUTE } from '../routes';
import { useNavigate } from 'react-router-dom';

const ForgotPassword: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const styles = useStyles(setPasswordStyles, {});
  const [codeSent, setCodeSent] = useState(false);
  const [error, setError] = useState<unknown>();
  const [mail, setMail] = useState<string>();
  const { control: control, handleSubmit: handleSubmit } = useForm<FieldValues>();
  const { control: codeControl, handleSubmit: codeHandleSubmit } = useForm<FieldValues>();

  const codeHandler: SubmitHandler<FieldValues> = async ({ email }) => {
    try {
      if (await Auth.forgotPassword(email)) {
        setCodeSent(true);
        setMail(email);
        setError(false);
      }
    } catch (e) {
      setError(e);
    }
  };

  const passwordHandler: SubmitHandler<FieldValues> = async ({ code, password, passwordConfirmation }) => {
    if (password !== passwordConfirmation) {
      setError(t('PWMissmatch'));
      return;
    }
    try {
      if (await Auth.forgotPasswordSubmit(mail!, code, password)) {
        setError(false);
        navigate(ROUTE.LOGIN);
      }
    } catch (e) {
      setError(e);
    }
  };

  return (
    <Box className="SetPassword-root" sx={styles.root}>
      <Box sx={styles.header}>
        <Box sx={styles.logoContainer}>
          <Box component="img" className="Dummy-Logo" sx={styles.dummyLogo} src={Logo} />
        </Box>
      </Box>

      <Box sx={styles.content}>
        <Box className="Login-heading" sx={styles.heading}>
          <Typography variant="h2"> {t('Forgot Password')}?</Typography>
        </Box>
        {codeSent ? (
          <Box sx={styles.form}>
            <form onSubmit={handleSubmit(passwordHandler)} key="PW">
              <FormInput
                name="code"
                type="text"
                placeholder="Code"
                control={control}
                className="w_100 mb-2"
                key="inputCode"
                autocomplete="one-time-code"
              ></FormInput>
              <FormInput
                name="password"
                type="password"
                placeholder={t('Set Password')}
                control={control}
                className="w_100 mb-2"
                key="inputPW"
                autocomplete="new-password"
              ></FormInput>
              <FormInput
                name="passwordConfirmation"
                type="password"
                placeholder={t('Confirm Password')}
                control={control}
                className="w_100 mb-2"
                key="inputPWConfirm"
                autocomplete="new-password-confirm"
              ></FormInput>
              <Button type="submit" className="mb-3" fullWidth>
                {t('Set Password')}
              </Button>
            </form>
          </Box>
        ) : (
          <Box sx={styles.form}>
            <form onSubmit={codeHandleSubmit(codeHandler)} key="code">
              <FormInput
                name="email"
                type="text"
                placeholder={t('E-Mail')}
                control={codeControl}
                className="w_100 mb-2"
                key="inputCode"
              ></FormInput>
              <Button type="submit" className="mb-3" fullWidth>
                {t('GetCode')}
              </Button>
            </form>
          </Box>
        )}
        {!!error && (
          <>{typeof error === 'string' ? error : (error as Record<string, unknown>).name ?? 'Unbekannter Fehler'}</>
        )}
        <Link onClick={() => navigate(ROUTE.LOGIN)} sx={styles.link} color="inherit" fontSize={16}>
          {t('Return to login')}
        </Link>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
