import { Components } from '@mui/material/styles';
import { palette } from '../palette';

const MuiTab: Components = {
  MuiTab: {
    styleOverrides: {
      root: {
        color: palette.text.primary,
        '&.Mui-selected': {
          color: palette.text.primary,
          //backgroundColor: palette.primary.light,
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        '.MuiTabs-indicator': {
          backgroundColor: 'black',
        },
      },
    },
  },
};

export default MuiTab;
