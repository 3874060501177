import { Badge, ListItem, ListItemButton, Typography } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTE } from '../../../pages/routes';
import styles from './SidebarListItem.styles';

export interface SidebarListItemProps {
  path: ROUTE;
  roles: string[];
  action?: () => void | Promise<void>;
  label: string;
  badge?: number;
  selected?: boolean;
  disabled?: boolean;
  enabledForStudioManager?: boolean;
}

const SidebarListItem: FC<SidebarListItemProps> = ({ label, badge, path, action, selected, disabled }) => {
  const navigate = useNavigate();

  return (
    <ListItem className="sidebar-listItem" sx={styles.root}>
      <ListItemButton
        color={selected ? 'dark' : 'light'}
        sx={{ marginX: 3 }}
        disabled={disabled}
        onClick={() => (action ? action() : navigate(path))}
      >
        <Typography variant="h4" component="span" color="inherit">
          {label}
        </Typography>
      </ListItemButton>
      {!!badge && <Badge color="secondary" badgeContent={badge} />}
    </ListItem>
  );
};

export default SidebarListItem;
