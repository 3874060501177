import { ComponentStylesWithFns } from '../../theme/styles.helpers';

export const ConsultationsStyles: ComponentStylesWithFns = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    overscrollBehavior: 'none',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '112px',
    marginX: 'auto',
    width: '90%',
  },
  calendar: {
    marginX: 'auto',
    width: '90%',
  },
  statusIcon: {
    fontSize: '1rem',
    '&.red': {
      color: '#FF3030 !important',
    },
  },
  row: {
    '& .MuiDataGrid-row': {
      // backgroundColor: 'rgb(42, 44, 46)',
      height: '45px',
      maxHeight: '45px !important',
      minHeight: '45px !important',
      borderRadius: '4px',
      marginTop: '5px',
      fontWeight: 'normal',
      fontSize: '14px',
      cursor: 'pointer',
      ':hover': {
        bgcolor: 'secondary.main',
        color: 'primary.contrastText',
      },
      '& .MuiDataGrid-cell': {
        border: 'none',
        outline: 'none',
      },
    },
  },
};
