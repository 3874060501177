import Box from '@mui/material/Box';
import { Accordion, AccordionDetails, AccordionSummary, Button, Icon, TextField, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FC, useContext, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import FlagTwoToneIcon from '@mui/icons-material/FlagTwoTone';
import { TabPanel } from '../../Functions/TabPanel';
import { SelectFinishStyles } from './SelectFinish.styles';
import { useStyles } from '../../../../theme/styles.helpers';
import { useLayout, useUpdateLayout } from '../../../../legacy/provider/layoutProvider/LayoutProvider';
import { Templates, Utils } from '@hellohair/types';
import { DataContext } from '../../../../aws/DataProvider';

interface SelectFinishProps {
  survey: Templates.SurveyTemplate;
  index: number;
  className?: string;
}

const SelectFinish: FC<SelectFinishProps> = ({ survey, index }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const styles = useStyles(SelectFinishStyles, {});
  const formattedIndex = index.toLocaleString('de-DE', { minimumIntegerDigits: 2 });

  const expanded = useLayout();
  const setCurrentExpanded = useUpdateLayout();

  const {
    formDetailsState: [, setCurrentSurvey],
  } = useContext(DataContext);

  const { type, question, yes, no, id } = survey.steps[index] as Templates.StepDataSelectFinish;
  const [localQuestion, setLocalQuestion] = useState<string>(question);
  const [localYes, setLocalYes] = useState<string>(yes);
  const [localNo, setLocalNo] = useState<string>(no);

  const [tab, setTab] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const saveElement = () => {
    const newState = [...survey.steps];
    newState[index] = {
      id: id,
      type: type,
      question: localQuestion,
      yes: localYes,
      no: localNo,
    } as Templates.StepDataSelectFinish;
    setCurrentSurvey({ ...survey, steps: newState } as Utils.DBItem<Templates.SurveyTemplate>);
  };

  const deleteElement = () => {
    const newState = [...survey.steps];
    newState.splice(index, 1);
    setCurrentSurvey({ ...survey, steps: newState } as Utils.DBItem<Templates.SurveyTemplate>);
  };

  function a11yProps(_index: number) {
    return {
      id: `simple-tab-${_index}`,
      'aria-controls': `simple-tabpanel-${_index}`,
    };
  }

  return (
    <Draggable draggableId={id.toString()} index={index}>
      {/*eslint-disable-next-line @typescript-eslint/no-explicit-any*/}
      {(provided: any) => (
        <Accordion
          expanded={expanded === index}
          onChange={setCurrentExpanded(index)}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          elevation={0}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Box className="AccordionSummary-index" display="flex" alignItems="center">
              <Typography variant="body2" sx={{ width: '30px' }} fontWeight="bold">
                {formattedIndex}
              </Typography>
              <Icon sx={{ width: '30px', marginRight: '10px' }}>
                <FlagTwoToneIcon />
              </Icon>
              <Box className="AccordionSummary-question" sx={{ width: 'calc(100% - 70px)' }}>
                <Typography
                  variant="body2"
                  sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    display: '-webkit-box',
                    '-webkit-line-clamp': '2',
                    lineClamp: '2',
                    '-webkit-box-orient': 'vertical',
                  }}
                >
                  {question}
                </Typography>
              </Box>
            </Box>
          </AccordionSummary>

          <AccordionDetails>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
                <Tab label={`Edit (ID: ${id})`} {...a11yProps(0)} />
                <Tab label="Logic" {...a11yProps(1)} />
                <Tab label="Settings" {...a11yProps(2)} />
              </Tabs>
            </Box>

            <TabPanel value={tab} index={0}>
              <TextField
                value={localQuestion}
                label="Finish Message"
                fullWidth
                onChange={(e) => setLocalQuestion(e.target.value)}
                size="small"
                margin="dense"
              ></TextField>
            </TabPanel>

            <TabPanel value={tab} index={1}></TabPanel>

            <TabPanel value={tab} index={2}>
              <TextField
                value={localYes}
                label="Button label YES"
                fullWidth
                onChange={(e) => setLocalYes(e.target.value)}
                size="small"
                margin="dense"
              ></TextField>
              <TextField
                value={localNo}
                label="Button label NO"
                fullWidth
                onChange={(e) => setLocalNo(e.target.value)}
                size="small"
                margin="dense"
              ></TextField>
            </TabPanel>

            <Box sx={{ display: 'grid', gap: 1 }}>
              <Button onClick={saveElement} fullWidth>
                <Typography variant="h3">save element</Typography>
              </Button>
              <Button onClick={deleteElement} fullWidth sx={{ background: '#FF3030!important' }}>
                <Typography variant="h3">delete element</Typography>
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
    </Draggable>
  );
};

export default SelectFinish;
