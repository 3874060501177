import { ComponentStylesWithFns } from '../../theme/styles.helpers';

export const TemplatesStyles: ComponentStylesWithFns = {
  root: {
    width: '90%',
    marginX: 'auto',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '112px',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: ['1fr', '1fr', '1fr 1fr', '1fr 1fr 1fr', '1fr 1fr 1fr'],
    gridColumnGap: 24,
    rowGap: 4,
  },
  item: {
    backgroundColor: 'white',
    borderRadius: 4,
    padding: 4,
    display: 'block',
    justifyContent: 'space-between',
    alignItems: 'end',
    cursor: 'pointer',
    height: '192px',
  },
};
