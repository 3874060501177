import React, { useMemo, useState } from 'react';
import { useStyles } from '../../theme/styles.helpers';
import { RequestsShortListStyles } from './RequestsShortListStyles';
import { useTranslation } from 'react-i18next';
import { Orders, Utils } from '@hellohair/types';
import { CustomModal } from '../modal/CustomModal';
import { OrderDetails } from '../modals/order-details/OrderDetails';
import { Box } from '@mui/material';
import { CustomDataGrid } from '../data-grid/CustomDataGrid';
import { GridColDef, GridRowParams } from '@mui/x-data-grid-pro';

interface RequestListProps {
  requests: Utils.DBItem<Orders.OrderRequest>[];
}

interface RequestListRow {
  requestId: string;
  requestDate: string;
  customerName: string;
  productName: string;
  requestType: string;
  requestState: string;
  isFollowup: boolean;
}

const transformToRow: (request: Utils.DBItem<Orders.OrderRequest>) => RequestListRow = (
  item: Utils.DBItem<Orders.OrderRequest>
) => {
  const row: RequestListRow = {
    requestId: item.requestId,
    requestDate: new Date(item.createdAt).toLocaleDateString(),
    customerName: item.customer?.customerName,
    productName: item.product.productName,
    requestState: item.orderState,
    requestType: 'type',
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    isFollowup: (item as any).isFollowup ?? false,
  };
  return row;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const RequestsShortList: React.FC<RequestListProps> = ({ requests }) => {
  const styles = useStyles(RequestsShortListStyles, {});

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { t } = useTranslation();

  const [requestId, setRequestId] = useState<string | undefined>(undefined);
  const [isFollowup, setIsFollowup] = useState<boolean>(false);

  const rows = useMemo(() => requests.map(transformToRow), [requests]);

  const handleRowClicked = (params: GridRowParams) => {
    const { requestId: id, isFollowup: _isFollowup } = params.row as RequestListRow;
    setRequestId(id);
    setIsFollowup(_isFollowup);
    setIsEditModalOpen(true);
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'requestId',
        headerName: t('Request'),
        filterable: false,
        type: 'string',
        flex: 0.8,
        align: 'left',
        headerAlign: 'left',
      },
      { field: 'requestDate', headerName: t('Date'), filterable: false, flex: 0.7 },
      { field: 'customerName', headerName: t('Customer'), filterable: false, flex: 3 },
      // { field: 'customerId', headerName: t('Customer'), filterable: false, flex: 1 },
      { field: 'productName', headerName: t('Product'), filterable: false, flex: 0.8 },
      {
        field: 'requestState',
        headerName: t('Status'),
        filterable: false,
        flex: 0.8,
        align: 'center',
        renderCell: ({ value }) => {
          const style = {
            width: '10px',
            height: '10px',
            borderRadius: '5px',
            backgroundColor:
              value === Orders.OrderRequestState.IDLE
                ? '#4881d7'
                : value === Orders.OrderRequestState.ALLOWED
                  ? 'green'
                  : '#df1b41',
          };
          return <div style={style} />;
        },
      },
    ],
    []
  );

  return (
    <Box sx={styles.container}>
      <CustomDataGrid
        columnHeaderHeight={0}
        columns={columns}
        rows={rows || []}
        onRowClick={handleRowClicked}
        getRowId={(row) => row.requestId}
        isCellEditable={() => false}
        isRowSelectable={() => false}
        pagination
        // autoPageSize
        initialState={{}}
        sx={styles.row}
      />

      <CustomModal variant="large" isModalOpen={isEditModalOpen} onModalClose={() => setIsEditModalOpen(false)}>
        <OrderDetails onModalClose={() => setIsEditModalOpen(false)} requestId={requestId} isFollowup={isFollowup} />
      </CustomModal>
    </Box>
  );
};
