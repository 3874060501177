import gql from 'graphql-tag';

/**
 * Get list of all presets unfiltered
 */

export const LIST_TEMPLATES = gql`
  query ListTemplates {
    listTemplates {
      templateId
      name
      description
      version
      createdAt
      tags
    }
  }
`;

export const GET_TEMPLATE = gql`
  query GetTemplate($id: String!) {
    getTemplate(id: $id) {
      templateId
      name
      description
      version
      createdAt
      tags
      steps
    }
  }
`;

export const LIST_REQUESTS = gql`
  query ListOrderRequests {
    listRequests {
      requestId
      name
      customer {
        customerId
        customerName
        doctorId
      }
      assignedDoctor {
        doctorId
        factor
        factor2
        fee
        fee2
      }
      product {
        productId
        productName
      }
      orderState
      templateId
      createdAt
      isFollowup
    }
  }
`;

export const LIST_ORDER_HISTORY_FOR_USER = gql`
  query listOrderHistoryForUser($customerId: String!) {
    listOrderHistoryForUser(customerId: $customerId) {
      orderId
      items {
        productId
        description
      }
      createdAt
      history {
        event
        time
      }
    }
  }
`;

export const GET_ORDER_HISTORY_DETAILS = gql`
  query getOrderHistoryDetails($customerId: String!, $orderId: String!) {
    getOrderHistoryDetails(customerId: $customerId, orderId: $orderId) {
      amount
      isSubscription
      subscriptionStatus
      billbeeState
      shippingIds {
        trackingUrl
        shippingId
      }
      history {
        event
        time
      }
    }
  }
`;

export const GET_RETURNS_FOR_CUSTOMER = gql`
  query GetReturnsForCustomer($customerId: String!) {
    getReturnsForCustomer(customerId: $customerId) {
      createdAt
      orderNumber
      total_amount
      items {
        amount
        description
        productId
      }
    }
  }
`;

export const LIST_CONSULTATIONS = gql`
  query listConsultations {
    listConsultations {
      id
      start {
        dateTime
      }
      end {
        dateTime
      }
      description
      eventType
      summary
    }
  }
`;

export const GET_REQUEST = gql`
  query GetRequest($requestId: String!) {
    getRequest(requestId: $requestId) {
      requestId
      name
      comment
      assets {
        category
        size
        key
        thumbnail
      }
      qanda {
        question
        answer
        type
      }
      customer {
        customerId
        customerName
        gender
        givenName
        familyName
        email
        birthDate
      }
      product {
        productId
        productName
        unit
        amount
      }
      isSubscription
      orderState
      templateId
      createdAt
    }
  }
`;

export const GET_FOLLOWUP = gql`
  query GetFollowup($requestId: String!) {
    getFollowup(requestId: $requestId) {
      requestId
      name
      comment
      assets {
        category
        size
        key
        thumbnail
      }
      qanda {
        question
        answer
        type
      }
      product {
        productId
        productName
        unit
        amount
      }
      customer {
        customerId
        customerName
        gender
        givenName
        familyName
        email
        birthDate
      }
      isSubscription
      orderState
      templateId
      createdAt
    }
  }
`;

export const GET_SUBSCRIPTIONS = gql`
  query GetSubscriptions($stripeId: String!) {
    getSubscriptions(stripeId: $stripeId) {
      id
      status
      current_period_end
      created
      paused
      payment_method {
        type
        identifier
      }
      payment_intent {
        status
      }
      product {
        id
        name
        freeProduct
      }
      price {
        id
        unit_amount
        currency
      }
    }
  }
`;

export const GET_CUSTOMER_PRESCRIPTION_STATE = gql`
  query GetCustomerPrescriptionState($customerId: String!) {
    getCustomerPrescriptionState(customerId: $customerId)
  }
`;

export const LIST_STATISTICS = gql`
  query ListStatitsics($year: Int!) {
    getStatsByYear(year: $year) {
      date
      forms
      requests
      customers
      consultations
    }
  }
`;

export const LIST_USERS = gql`
  query ListUsers {
    listUsers {
      sub
      email
      phoneNumber
      title
      firstName
      lastName
      job
      gender
      address {
        street
        houseNumber
        zip
        city
      }
      groups
      isActive
      factor
      factor2
      studio
    }
  }
`;

// export const LIST_CUSTOMERS = gql`
//   query ListCustomers {
//     listCustomers {
//       customerId
//       name
//       description
//       type
//       users
//       requests
//       licenses
//       isActive
//     }
//   }
// `;

export const LIST_CUSTOMERS = gql`
  query ListCustomers {
    listCustomers {
      customerId
      firstName
      lastName
      email
      gender
      dateCreated
      doctorId
      stripeId
      studioSource
    }
  }
`;
// email;
// gender;
// firstName;
// lastName;
// name;
// phoneNumber;
// groups;
// isActive;

export const SIGN_S3_GET_OBJECT = gql`
  query SignS3GetObject($filename: String!) {
    signS3GetObject(filename: $filename) {
      url
      path
      signedRequest
    }
  }
`;

export const LIST_APP_STREAM_STACKS = gql`
  query ListAppStreamStacks {
    listAppStreamStacks {
      arn
      name
      description
      displayName
      createdTime
    }
  }
`;

export const LIST_APP_STREAM_USERS = gql`
  query ListAppStreamUsers {
    listAppStreamUsers {
      email
      firstName
      lastName
      stacks
      enabled
      status
    }
  }
`;

export const LIST_USER_GROUPS = gql`
  query ListUserGroups {
    listUserGroups {
      name
      description
    }
  }
`;

export const LIST_EXPORT_LOGS = gql`
  query ListExportLogs {
    listExportLogs {
      durationInMs
      itemsAdded
      itemsUpdated
      state
      timestamp
    }
  }
`;

export const EVALUATE_REQUEST_CODE = gql`
  query EvaluateCode($code: String!) {
    evaluateCode(code: $code)
  }
`;

export const LIST_STUDIOS = gql`
  query ListStudios {
    listStudios {
      name
      id
      description
      managerId
      createdAt
    }
  }
`;

export const GET_STUDIO = gql`
  query GetStudio($id: String!) {
    getStudio(id: $id) {
      name
      id
      description
      managerId
      createdAt
    }
  }
`;
