import { ComponentStylesWithFns } from '../../theme/styles.helpers';

export const TemplateStyles: ComponentStylesWithFns = {
  root: {
    '.Products-heading': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '5px',
      height: '112px',
      marginX: 'auto',
      width: '90%',

      '.Buttons': {
        display: 'flex',
        gap: '5px',
      },
    },

    '.Form-form': {
      display: 'flex',
      marginX: 'auto',
      width: '90%',
      flexGrow: 1,
    },
  },
};
