import { TextField } from '@mui/material';
import { CSSProperties } from 'react';
import { Control, Controller, ControllerProps } from 'react-hook-form';

interface FormTextAreaProps extends Partial<ControllerProps> {
  defaultValue?: string;
  disabled?: boolean;
  label?: string;
  type?: string;
  control: Control<any, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  className?: string;
  style?: CSSProperties;
  minRows?: number;
  maxRows?: number;
}

const FormTextArea: React.FC<FormTextAreaProps> = ({
  name,
  control,
  rules,
  type,
  label,
  disabled,
  minRows = 4,
  maxRows = 4,
  className,
  style,
  defaultValue = '',
}) => {
  return (
    <div className="form-input" style={style}>
      <Controller
        name={name ?? ''}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <TextField
            sx={{ padding: '0px' }}
            InputLabelProps={{ shrink: true, sx: { textIndent: '10px' } }}
            InputProps={{ sx: { lineHeight: '30px' } }}
            className={className}
            disabled={disabled}
            value={value}
            type={type}
            onChange={onChange}
            label={label}
            placeholder={label}
            variant="outlined"
            size="small"
            minRows={minRows}
            maxRows={maxRows}
            multiline
          />
        )}
      />
    </div>
  );
};

export default FormTextArea;
