import { Button, CircularProgress, Grid, SvgIcon, TextField, Typography, Box } from '@mui/material';
import { CSSProperties, FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../aws/AuthProvider';
import { DataContext } from '../../../aws/DataProvider';
import {
  generateRezept,
  getOrderFollowupRequest,
  getOrderRequest,
  handleComment,
  handleCommentFollowup,
  handleFollowup,
  handleRequest,
  listOrderRequests,
} from '../../../aws/RequestsApi';
import { useStyles } from '../../../theme/styles.helpers';
import { OrderDetailsStyles } from './OrderDetails.Styles';
import { OrderDetailsCustomer } from './OrderDetailsCustomer';
import { OrderDetailsSurvey } from './survey/OrderDetailsSurvey';
import { OrderDetailsProduct } from './OrderDetailsProduct';
import { Close, History, ToggleOn } from '@mui/icons-material';
import HistoryList from '../../history/history-list/HistoryList';
import { Utils, Orders } from '@hellohair/types';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

interface OrderDetailsProps {
  onModalClose: () => void;
  requestId: string | undefined;
  isFollowup: boolean;
}

export const OrderDetails: FC<OrderDetailsProps> = ({ onModalClose, requestId, isFollowup }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { apiClient } = useContext(AuthContext);
  const styles = useStyles(OrderDetailsStyles, {});
  const { t } = useTranslation();

  // TODO: update type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [userOrderHistory, setUserOrderHistory] = useState<any>();
  const [historyOpen, setHistoryOpen] = useState<boolean>(false);
  const [orderDetails, setOrderDetails] =
    useState<Utils.DBItem<Orders.OrderRequestDetails | Orders.OrderFollowupRequestDetails>>();
  const [loading, setLoading] = useState(true);
  const [acceptOpen, setAcceptOpen] = useState(false);
  const [denyOpen, setDenyOpen] = useState(false);
  const [comment, setComment] = useState('');

  const {
    ordersState: [requests, setRequests],
  } = useContext(DataContext);

  useEffect(() => {
    if (requestId) {
      setLoading(true);
      getOrderDetails(requestId || '');
    }
  }, [requestId]);

  useEffect(() => {
    const _userId = requests.find((item) => item.requestId === requestId)?.customer.customerId;
    const _userRequests = requests.filter((item) => item.customer.customerId === _userId);
    setUserOrderHistory(_userRequests);
  }, [requests]);

  const getOrderDetails = async (reqId: string) => {
    try {
      const _order = await (isFollowup
        ? getOrderFollowupRequest(reqId || '', apiClient, 'network-only')
        : getOrderRequest(reqId || '', apiClient, 'network-only'));
      setOrderDetails(_order);
      setComment(_order.comment);

      setLoading(false);

      console.log('[OrderDetailsView]  - successfully fetched order', _order);
    } catch (error) {
      console.log('[OrderDetailsView] - error:', error);
    }
  };

  const onHandleRequest = async (requestOrderId: string, action: string) => {
    try {
      setLoading(true);
      if (isFollowup) await handleFollowup(requestOrderId, action, apiClient);
      else await handleRequest(requestOrderId, action, apiClient);
      const _orders = await listOrderRequests(apiClient, 'network-only');
      setRequests(_orders);
      await getOrderDetails(requestOrderId);
      setAcceptOpen(false);
      setDenyOpen(false);
    } catch (error) {
      console.log('[onSendEmail] - error:', error);
    }
  };

  const onHandleAllow = async (productId: string, _requestId: string, customerId: string) => {
    try {
      setLoading(true);
      //FIXME: PRODUCT ID
      const response = await generateRezept(productId, _requestId, customerId, false, isFollowup, apiClient);
      console.log('REZEPT', response);
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      const doc = response.members.find((m: any) => m.isSigner);
      window.open(`https://sign.sproof.io/#/editor/${doc?.id}`, '_blank')?.focus();
      const _orders = await listOrderRequests(apiClient, 'network-only');
      setRequests(_orders);
      await getOrderDetails(_requestId);
      setAcceptOpen(false);
      setDenyOpen(false);
      onModalClose();
    } catch (error) {
      console.log('[onHandleAllow] - error:', error);
    }
  };

  return (
    <Box sx={styles.root} className="OrderDetails-root" style={{ pointerEvents: loading ? 'none' : 'all' }}>
      {loading && <CircularProgress size={32} sx={styles.spinner} thickness={5} />}
      <Box sx={styles.container}>
        <Box sx={styles.header}>
          <History sx={{ cursor: 'pointer' }} onClick={() => setHistoryOpen(!historyOpen)} />
          <Box sx={styles.headerTitle}>
            <Typography variant="h4">{t('Request')}</Typography>
          </Box>
          {historyOpen && (
            <HistoryList
              historyData={userOrderHistory}
              onItemClick={(reqId) => {
                setOrderDetails(undefined);
                setHistoryOpen(false);
                setLoading(true);
                getOrderDetails(reqId);
              }}
            />
          )}
        </Box>

        <Box sx={styles.grid} className="OrderDetails-grid">
          <Grid container height={'100%'} className="OrderDetails-container">
            {/* Left Container */}
            <Grid item xs={12} md={7} className="OrderDetails-leftContainer">
              {orderDetails !== undefined && (
                <Box padding={4}>
                  <OrderDetailsCustomer customer={orderDetails.customer} />
                  <OrderDetailsSurvey
                    templateId={orderDetails.templateId}
                    assets={orderDetails.assets}
                    qanda={orderDetails.qanda}
                  />
                </Box>
              )}
            </Grid>

            {/* Right Container */}
            <Grid item xs={12} md={5} sx={styles.orderContainerWrapper} className="OrderDetails-rightContainer">
              {orderDetails !== undefined && (
                <Box sx={styles.orderContainer}>
                  {'product' in orderDetails && (
                    <OrderDetailsProduct
                      product={orderDetails.product}
                      orderDate={orderDetails.createdAt}
                      isSubscription={orderDetails.isSubscription}
                    />
                  )}

                  {/*
                TODO: send comment data with POST request
                */}
                  <TextField
                    label={t('Comment')}
                    multiline
                    color="primary"
                    variant="outlined"
                    rows={4}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    inputProps={{
                      style: styles.comment as CSSProperties,
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        padding: 0,
                      },
                    }}
                  />
                  <Button
                    onClick={() =>
                      isFollowup
                        ? handleCommentFollowup(orderDetails.requestId, comment, apiClient)
                        : handleComment(orderDetails.requestId, comment, apiClient)
                    }
                  >
                    <Typography variant="h3">{t('Send Comment')}</Typography>
                  </Button>

                  {orderDetails.orderState === Orders.OrderRequestState.ALLOWED && (
                    <Box>
                      <Grid item xs={12} display="flex" alignItems={'center'} gap={1}>
                        <ToggleOn fontSize="large" color="success" />
                        <Typography variant="body2">Rezept verschickt</Typography>
                      </Grid>
                    </Box>
                  )}

                  {/* <Box flexGrow={1} /> */}

                  <Box sx={styles.buttonContainer}>
                    {orderDetails.orderState === Orders.OrderRequestState.IDLE && !acceptOpen && !denyOpen && (
                      <Grid container spacing={1} gap={2}>
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            fullWidth
                            onClick={() => setAcceptOpen(true)}
                            sx={{
                              backgroundColor: '#4881d7',
                              '&:hover': {
                                backgroundColor: '#4881d7',
                              },
                            }}
                          >
                            {t('Accept Treatment')}
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            fullWidth
                            onClick={() => setDenyOpen(true)}
                            sx={{
                              backgroundColor: 'error.main',
                              '&:hover': {
                                backgroundColor: 'error.main',
                              },
                            }}
                          >
                            {t('Reject Treatment')}
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                    {(acceptOpen || denyOpen) && (
                      <Grid container spacing={1}>
                        <Grid item xs={12} sx={{ marginBottom: 3 }}>
                          {acceptOpen && (
                            <Typography variant="h5" textAlign="center">
                              {t('AcceptTreatmentConfirm')}
                            </Typography>
                          )}
                          {denyOpen && (
                            <Typography variant="h5" textAlign="center">
                              {t('DenyTreatmentConfirm')}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <Button
                            variant="contained"
                            fullWidth
                            onClick={() => {
                              if (acceptOpen)
                                onHandleAllow(
                                  orderDetails.product?.productId,
                                  orderDetails.requestId,
                                  orderDetails.customer.customerId
                                );
                              if (denyOpen) onHandleRequest(orderDetails.requestId, 'DENY');
                            }}
                          >
                            {t('Yes')}
                          </Button>
                        </Grid>

                        <Grid item xs={6}>
                          <Button
                            variant="outlined"
                            fullWidth
                            onClick={() => {
                              setAcceptOpen(false);
                              setDenyOpen(false);
                            }}
                          >
                            {t('No')}
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                    <Box display="flex" alignItems="center">
                      {orderDetails.orderState === Orders.OrderRequestState.ALLOWED && (
                        <>
                          <CheckCircleIcon />
                          <Typography variant="body2">{t('Accepted Treatment')}</Typography>
                        </>
                      )}
                      {orderDetails.orderState === Orders.OrderRequestState.DENIED && (
                        <>
                          <CancelIcon />
                          <Typography variant="body2">{t('Rejected Treatment')}</Typography>
                        </>
                      )}
                    </Box>
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Closing Icon */}
      <Box onClick={onModalClose} sx={{ marginLeft: 2, cursor: 'pointer' }}>
        <SvgIcon sx={{ color: 'primary.main' }} component={Close}></SvgIcon>
      </Box>
    </Box>
  );
};
