import { ComponentStylesWithFns } from '../../../theme/styles.helpers';
import { theme } from '../../../theme/theme';

export const OrderDetailsStyles: ComponentStylesWithFns = {
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    boxSizing: 'border-box',
    overflowX: 'hidden',
    backgroundColor: 'primary.main',
    marginLeft: 5,
    borderRadius: '10px',
  },
  header: {
    display: 'flex',
    padding: 3,
    backgroundColor: 'white',
    borderBottom: '0.5px solid',
    borderColor: 'grey.200',
    position: 'sticky',
    top: 0,
    zIndex: 100,
  },
  headerTitle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  grid: {
    display: 'flex',
    gap: 3,
    width: '100%',
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: '20px',
    gridTemplateRows: '80px auto',
    gridRowGap: '20px',
    gridTemplateAreas: `"Customer Order"
    "Main Main Main"
    ". . ."`,
    width: '80%',
    margin: 'auto',
    marginBottom: '14px',
  },
  customerBox: {
    height: '100%',

    gridArea: 'Customer',
    backgroundColor: 'primary.light',
    borderRadius: 1,
    padding: 4,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
    overflow: 'clip',
  },
  orderBox: {
    gridArea: 'Order',
    backgroundColor: 'e8eff9',
    borderRadius: '5px',
    padding: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
    overflow: 'clip',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    margin: '30px',
  },
  detailsHeader: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0px',
    backgroundColor: 'f8f8f8',
  },
  detailsOrder: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0px',
    backgroundColor: 'e8eff9',
  },
  transactions: {
    display: 'flex',
    flexDirection: 'column',
    margin: '30px',
    flexGrow: 1,
  },
  thumbnail: {
    display: 'block',
    margin: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  icons: {
    [theme.breakpoints.up('md')]: {
      height: '50%',
      justifyContent: 'center',
    },
    justifyContent: 'left',
  },
  spinner: {
    color: 'primary.dark',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-16px',
    marginLeft: '-16px',
    zIndex: 100,
  },
  orderContainer: {
    backgroundColor: 'white',
    borderLeft: '0.5px solid',
    borderColor: 'grey.200',
    height: '100%',
    padding: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    position: 'relative',
  },
  orderContainerWrapper: {
    position: 'sticky',
    height: '100vh',
    minHeight: '500px',
    top: '58.5px',
  },
  buttonContainer: {
    bottom: 'calc(10% + 24px)',
    maxWidth: '425px',
    [theme.breakpoints.down('md')]: { position: 'relative', width: '100%', bottom: 0 },
  },
  comment: {
    lineHeight: 'normal',
  },
};
