import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { CSSProperties, useState } from 'react';
import { Control, Controller, ControllerProps } from 'react-hook-form';
import { useStyles } from '../../../theme/styles.helpers';
import { FormSelectStyles } from './FormSelect2.styles';

interface FormSelectProps extends Partial<ControllerProps> {
  options: { id: string; value: string }[];
  disabled?: boolean;
  defaultValue?: string;
  label?: string;
  className?: string;
  style?: CSSProperties;
  control?: Control<any, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  onChange?: (value: string) => void;
}

const FormSelect: React.FC<FormSelectProps> = ({
  name,
  label,
  control,
  options,
  rules,
  disabled = false,
  defaultValue = '',
  className = '',
  style,
  onChange: _onChange,
}) => {
  const styles = useStyles(FormSelectStyles, {});
  const [internalValue, setInternalValue] = useState<string>(defaultValue);
  // const { watch } = useFormContext();

  const handleChange = (event: SelectChangeEvent<string>, onChange: (value: string) => void) => {
    const {
      target: { value },
    } = event;

    if (!value || value === internalValue) return;

    _onChange?.(value);
    onChange(value);
    setInternalValue(value);
  };

  // useEffect(() => {
  //   if (!name) return;
  //   const subscription = watch((value, { name: valueName, type }) => {
  //     if (name !== valueName || !value[valueName]) return;
  //     const change = typeof value[valueName] === 'string' ? value[valueName].split(',') : value[valueName];
  //     if (internalValue === change) return;
  //     setInternalValue(change);
  //   });
  //   return () => subscription.unsubscribe();
  // }, [watch, name]);

  return (
    <Box className="FormSelect-root" sx={style}>
      <Controller
        name={name ?? ''}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <FormControl margin="dense" size="small" className={className}>
            <InputLabel
              sx={{
                textIndent: '10px',
                lineHeight: '2.5',
              }}
              id={`label-select-${name}`}
            >
              {label}
            </InputLabel>
            <Select
              labelId={`label-select-${name}`}
              label={label}
              value={value}
              onChange={(event) => handleChange(event, onChange)}
              disabled={disabled}
              fullWidth
              size="small"
            >
              {options.map((option) => (
                <MenuItem key={option.id} value={option.id} sx={styles.item}>
                  {option.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
    </Box>
  );
};

export default FormSelect;
