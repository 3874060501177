import { ComponentStylesWithFns } from '../../../../theme/styles.helpers';

export const OrderDetailsSurveyStyles: ComponentStylesWithFns = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    marginY: '50px',
  },
  imgContainer: {
    width: 'calc(25% - 20px)',
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    borderRadius: '10px',
    cursor: 'pointer',
  },
};
