import { ComponentStylesWithFns } from '../../theme/styles.helpers';

export const footerStyles: ComponentStylesWithFns = {
  footer: {
    color: 'black',
    marginBottom: '20px',

    a: {
      marginLeft: '5px',
      marginRight: '5px',
      color: 'black',
      fontSize: '12px',

      p: {
        display: 'inline-block',
      },
    },
  },
};
