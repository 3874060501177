import { Box, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import preval from 'preval.macro';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRedirectOnAuth } from '../../aws/AuthHooks';
import { AuthState } from '../../aws/AuthProvider';
import { useStyles } from '../../theme/styles.helpers';
import { ROUTE } from '../routes';
import { SettingsStyles } from './Settings.Styles';

const Settings = () => {
  const styles = useStyles(SettingsStyles, {});

  useRedirectOnAuth([AuthState.UNAUTHORIZED], ROUTE.LOGIN);

  const { t, i18n } = useTranslation();

  const [currentLanguage, setCurrentLanguage] = useState<string>('');

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    setCurrentLanguage(lng);
  };
  return (
    <Box sx={styles.container}>
      <Box sx={styles.header}>
        <Typography variant="h3">{t('Settings')}</Typography>
      </Box>

      <Box sx={styles.main}>
        <Box sx={styles.setting}>
          <Typography variant="h4">{t('Language')}</Typography>
          <ToggleButtonGroup
            sx={styles.group}
            value={currentLanguage}
            exclusive
            onChange={(_, value) => {
              if (value !== null) changeLanguage(value);
            }}
          >
            <ToggleButton value="de">
              <Typography variant="h3">DE</Typography>
            </ToggleButton>
            <ToggleButton value="en">
              <Typography variant="h3">EN</Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box className="sidebar-buildDate" sx={styles.buildDate}>
          <Typography variant="h4" color="black">
            {t('Build Date')}:{' '}
            {preval`module.exports = new Date().toLocaleString('de-DE', {timeZone: 'Europe/Berlin'});`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Settings;
