import { SxProps, Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

export type GetStyleFn<P extends StyleStates = Record<string, unknown>> = (
  props: P
) => Array<boolean | SystemStyleObject<Theme>>;

export type StyleKeys = 'root' | string;

export type StyleStates = Record<string, unknown>;

export type ComponentStylesWithFns<
  P extends StyleStates = Record<string, unknown>,
  K extends StyleKeys = StyleKeys,
> = Record<K, SystemStyleObject<Theme> | GetStyleFn<P>>;
export type ComponentStyles<K extends StyleKeys = StyleKeys> = Record<K, SxProps<Theme>>;

function isGetStyleFn<P extends StyleStates>(style: SxProps<Theme> | GetStyleFn<P>): style is GetStyleFn<P> {
  return typeof style === 'function';
}

/**
 * Hook to get sx Props out of component styles with functions
 * @param stylesWithFns {@link ComponentStylesWithFns}
 * @param states {@link StyleStates}
 */
export function useStyles<P extends StyleStates = Record<string, unknown>, K extends StyleKeys = StyleKeys>(
  stylesWithFns: ComponentStylesWithFns<P, K>,
  states: P
) {
  const styles: Partial<ComponentStyles<K>> = {};
  for (const key of Object.keys(stylesWithFns)) {
    const style = stylesWithFns[key as K] as SxProps<Theme> | GetStyleFn<P>;
    styles[key as K] = isGetStyleFn<P>(style) ? style(states) : style;
  }

  return styles;
}
