import { GraphQLResult } from '@aws-amplify/api';
import FetchPolicy from 'apollo-client/core/watchQueryOptions';
import AWSAppSyncClient from 'aws-appsync';
import { NormalizedCacheObject } from './aws.config';
import { CREATE_TEMPLATE, UPDATE_TEMPLATE, DELETE_TEMPLATE } from './graphql/mutations';
import { GET_TEMPLATE, LIST_TEMPLATES } from './graphql/queries';
import { Templates, Utils } from '@hellohair/types';

export async function listTemplates(
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>,
  fetchPolicy: FetchPolicy.FetchPolicy = 'cache-first'
): Promise<Utils.DBItem<Templates.SurveyTemplate>[]> {
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }
  try {
    const _products = await apiClient.query<{ listTemplates: Utils.DBItem<Templates.SurveyTemplate>[] }>({
      query: LIST_TEMPLATES,
      fetchPolicy: fetchPolicy,
    });
    return _products?.data?.listTemplates;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.log('[TemplatesApi] listTemplates errors', error);
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }
    throw error;
  }
}

export async function getTemplate(
  templateId: string,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>,
  fetchPolicy: FetchPolicy.FetchPolicy = 'cache-first'
): Promise<Utils.DBItem<Templates.SurveyTemplate>> {
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }
  try {
    const _products = await apiClient.query<{ getTemplate: Utils.DBItem<Templates.SurveyTemplate> }>({
      query: GET_TEMPLATE,
      variables: { id: templateId },
      fetchPolicy: fetchPolicy,
    });
    console.log('[TemplatesApi] getTemplate ', _products);
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      _products.data.getTemplate.steps = JSON.parse(_products.data.getTemplate.steps as any);
    } catch {
      console.warn('Probably already an object');
    }
    return _products?.data?.getTemplate;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.log('[TemplatesApi] getTemplate errors', JSON.stringify(error));
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }
    throw error;
  }
}

export async function createTemplate(
  templateAddInput: Templates.TemplateAddInput,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>
): Promise<string> {
  console.log('[TemplatesApi] - createTemplate: ', templateAddInput);
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    const result = (await apiClient.mutate({
      mutation: CREATE_TEMPLATE,
      variables: { templateAddInput },
    })) as GraphQLResult<{ createTemplate: string }>;
    console.log('[TemplatesApi] - templateInput - result:', templateAddInput, result);

    return result?.data?.createTemplate || '';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }
    throw error;
  }
}

export async function updateTemplate(
  templateUpdate: Templates.TemplateUpdateInput,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>
): Promise<Templates.SurveyTemplate | undefined> {
  console.log('[TemplatesApi] - updateTemplate: ', templateUpdate);
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    const result = (await apiClient.mutate({
      mutation: UPDATE_TEMPLATE,
      variables: { templateUpdateInput: templateUpdate },
    })) as GraphQLResult<{ updateTemplate: Templates.SurveyTemplate }>;
    console.log('[TemplatesApi] - templateUpdate - result:', templateUpdate, result);

    return result?.data?.updateTemplate;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }
    throw error;
  }
}

export async function deleteTemplate(
  templateId: string,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>
): Promise<boolean> {
  console.log('[TemplatesApi] - deleteTemplate: ', templateId);
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    const result = (await apiClient.mutate({
      mutation: DELETE_TEMPLATE,
      variables: { id: templateId },
    })) as GraphQLResult<{ deleteTemplate: boolean }>;
    console.log('[ProductApi] - deleteTemplate - result:', templateId, result);

    return result?.data?.deleteTemplate || false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }
    throw error;
  }
}
