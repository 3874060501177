import { Button, SvgIcon, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../../../theme/styles.helpers';
import { ConsultationFormStyles } from './ConsultationForm.Styles';
import { EventClickArg } from '@fullcalendar/core';
import { Close } from '@mui/icons-material';
import { removeConsultation } from '../../../aws/ConsultationApi';
import { AuthContext } from '../../../aws/AuthProvider';

interface ConsultationDeleteFormProps {
  event: EventClickArg;
  removeEvent: (id: string) => void;
  onModalClose: () => void;
}

const ConsultationDeleteForm: FC<ConsultationDeleteFormProps> = ({ event, removeEvent, onModalClose }) => {
  const { t } = useTranslation();

  //MUI Styles
  const styles = useStyles(ConsultationFormStyles, {});

  const { apiClient } = useContext(AuthContext);

  console.log(event);

  return (
    <Box sx={styles.root}>
      <Box sx={styles.container}>
        <Box sx={styles.header}>
          <Typography variant="h4">{t('DeleteEventQuestion', { name: event.event.title })}</Typography>
        </Box>
        <Box sx={styles.delete}>
          <Typography variant="h5" textAlign="center">
            {event.event.extendedProps.summary === 'C:FREE'
              ? t('DeleteEventConfirm', {
                  event: event.event.start?.toLocaleDateString('de', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                  }),
                })
              : t('Event cannot be deleted')}
          </Typography>
          {event.event.extendedProps.summary === 'C:FREE' && (
            <Box sx={styles.grid}>
              <Button
                sx={styles.gridItem}
                onClick={async () => {
                  const res = await removeConsultation(event!.event.id, apiClient);
                  if (res) {
                    removeEvent(event!.event.id);
                    onModalClose();
                  }
                }}
              >
                {t('Yes')}
              </Button>
              <Button sx={styles.gridItem} variant="outlined" onClick={onModalClose}>
                {t('No')}
              </Button>
            </Box>
          )}
        </Box>
      </Box>

      {/* Closing Icon */}
      <Box onClick={onModalClose} sx={{ marginLeft: 2, cursor: 'pointer' }}>
        <SvgIcon sx={{ color: 'primary.main' }} component={Close}></SvgIcon>
      </Box>
    </Box>
  );
};

export default ConsultationDeleteForm;
