import { SvgIcon, Typography, Box, Tabs, Tab } from '@mui/material';
import React, { FC, useState } from 'react';
import { useStyles } from '../../../theme/styles.helpers';
import { UserRequestHistoryStyles } from './UserRequestHistory.Styles';
import { Close } from '@mui/icons-material';
import OrderHistory from './OrderHistory';
import SubscriptionsHistory from './SubscriptionsHistory';
import PrescriptionStatus from './PrescriptionStatus';
import AssignedDoctor from './AssignedDoctor';
import CustomerReturns from './CustomerReturns';

interface OrderDetailsProps {
  onModalClose: () => void;
  customerId: string;
  firstName: string;
  lastName: string;
  stripeId: string;
}

export const UserRequestHistory: FC<OrderDetailsProps> = ({
  onModalClose,
  customerId,
  firstName,
  lastName,
  stripeId,
}) => {
  const styles = useStyles(UserRequestHistoryStyles, {});
  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={styles.root} className="OrderDetails-root">
      <Box sx={styles.container}>
        <Box sx={styles.header}>
          <Box sx={styles.headerTitle}>
            <Typography variant="h4">{`${firstName} ${lastName}`}</Typography>
          </Box>
        </Box>
        <PrescriptionStatus customerId={customerId} stripeId={stripeId} />
        <Tabs value={selectedTab} onChange={handleTabChange} variant="fullWidth">
          <Tab label="Bestellhistorie" />
          <Tab label="Abonnements" />
          <Tab label="Rücksendungen" />
          <Tab label="Arzt" />
        </Tabs>
        {selectedTab === 0 && <OrderHistory customerId={customerId} />}
        {selectedTab === 1 && <SubscriptionsHistory />}
        {selectedTab === 2 && <CustomerReturns customerId={customerId} />}
        {selectedTab === 3 && <AssignedDoctor customerId={customerId} />}
      </Box>

      {/* Closing Icon */}
      <Box onClick={onModalClose} sx={{ marginLeft: 2, cursor: 'pointer' }}>
        <SvgIcon sx={{ color: 'primary.main' }} component={Close}></SvgIcon>
      </Box>
    </Box>
  );
};
