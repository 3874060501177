export type Product = {
  //   OldId: string;
  Id?: string;
  Title: string;
  Weight: number;
  SKU: string;
  //   SkuOrId: string;
  //   IsDigital: true;
  // Images: [
  //   {
  //     Url: string
  //     IsDefaultImage: true,
  //     Position: 0,
  //     ExternalId: string
  //   },
  // ],
  //   EAN: string;
  PlatformData?: string;
  //   TARICCode: string;
  //   CountryOfOrigin: string;
  //   BillbeeId: 0;
  //   Type: 0;
};

export type Attribute = {
  Id: string;
  Name: string;
  Value: string;
  Price: number;
};

export type OrderItem = {
  //   BillbeeId: 0;
  //   TransactionId: string;
  Product: Product;
  Quantity: number;
  TotalPrice: number;
  //   TaxAmount: 0;
  //   TaxIndex: 0;
  //   Discount: 0;
  //   Attributes: Attribute[];
  //   GetPriceFromArticleIfAny: true;
  //   IsCoupon: true;
  ShippingProfileId: string;
  //   DontAdjustStock: true;
  //   UnrebatedTotalPrice: 0;
  //   SerialNumber: string;
  //   InvoiceSKU: string;
};

export type OrderAddress = {
  //   BillbeeId: number;
  FirstName: string;
  LastName: string;
  //   Company: string;
  //   NameAddition: string;
  Street: string;
  HouseNumber: string;
  Zip: string;
  City: string;
  CountryISO2: string;
  Country: string;
  //   Line2: string;
  //   Email: string;
  //   State: string;
  //   Phone: string;
};

export type Customer = {
  Id: number;
  //   Name: string
  //   Email: string
  //   Tel1: string
  //   Tel2: string
  //   Number: 0;
  //   PriceGroupId: 0;
  //   LanguageId: 0;
  //   DefaultMailAddress: {
  //     Id: 0;
  //     TypeId: 0;
  //     TypeName: string
  //     SubType: string
  //     Value: string
  //   };
  //   DefaultCommercialMailAddress: {
  //     Id: 0;
  //     TypeId: 0;
  //     TypeName: string
  //     SubType: string
  //     Value: string
  //   };
  //   DefaultStatusUpdatesMailAddress: {
  //     Id: 0;
  //     TypeId: 0;
  //     TypeName: string
  //     SubType: string
  //     Value: string
  //   };
  //   DefaultPhone1: {
  //     Id: 0;
  //     TypeId: 0;
  //     TypeName: string
  //     SubType: string
  //     Value: string
  //   };
  //   DefaultPhone2: {
  //     Id: 0;
  //     TypeId: 0;
  //     TypeName: string
  //     SubType: string
  //     Value: string
  //   };
  //   DefaultFax: {
  //     Id: 0;
  //     TypeId: 0;
  //     TypeName: string
  //     SubType: string
  //     Value: string
  //   };
  //   VatId: string
  //   Type: 0;
  //   MetaData: [
  //     {
  //       Id: 0;
  //       TypeId: 0;
  //       TypeName: string
  //       SubType: string
  //       Value: string
  //     },
  //   ];
  //   ArchivedAt: '2023-11-01T07:14:55.129Z';
  //   RestoredAt: '2023-11-01T07:14:55.129Z';
};

export type Payment = {
  //   BillbeeId: 0;
  //   TransactionId: string
  //   PayDate: '2023-11-01T07:14:55.129Z';
  //   PaymentType: 0;
  //   SourceTechnology: string
  //   SourceText: string
  //   PayValue: 0;
  //   Purpose: string
  Name: string;
};

export const TestAddress = {
  // BillbeeId: number;
  FirstName: 'Vorname',
  LastName: 'Nachname',
  //   Company: string;
  //   NameAddition: string;
  Street: 'Strasse',
  HouseNumber: '12',
  Zip: '11231',
  City: 'Berlin',
  CountryISO2: 'DE',
  Country: 'Deutschland',
  //   Line2: string;
  //   Email: string;
  //   State: string;
  //   Phone: string;
};

// export const TestOrderItem: OrderItem = {
//   Product: {
//     Id: 'p111',
//     Title: 'product name',
//     Weight: 100,
//   },
//   Quantity: 1,
//   TotalPrice: 122,
//   ShippingProfileId: 'PRESCRIPTION',
// };

// export const testOrder: Order = {
//   Id: 'id123',
//   OrderNumber: 'on456',
//   CreatedAt: '2023-11-01T07:14:55.129Z',
//   PayedAt: '2023-11-01T07:14:55.129Z',
//   SellerComment: 'seller comment',
//   InvoiceDate: '2023-11-01T07:14:55.129Z',
//   InvoiceAddress: {
//     FirstName: 'Vorname',
//     LastName: 'Nachname',
//     Street: 'Strasse',
//     HouseNumber: '12',
//     Zip: '11231',
//     City: 'Berlin',
//     CountryISO2: 'DE',
//     Country: 'Deutschland',
//   },
//   ShippingAddress: {
//     FirstName: 'Vorname',
//     LastName: 'Nachname',
//     Street: 'Strasse',
//     HouseNumber: '12',
//     Zip: '11231',
//     City: 'Berlin',
//     CountryISO2: 'DE',
//     Country: 'Deutschland',
//   },
//   ShippingCost: 3.5,
//   TotalCost: 122,
//   OrderItems: [
//     {
//       Product: {
//         Id: 'p111',
//         Title: 'product name',
//         Weight: 100,
//       },
//       Quantity: 1,
//       TotalPrice: 122,
//       ShippingProfileId: 'PRE',
//     },
//   ],
//   Currency: 'EUR',
//   PaidAmount: 125.5,
//   PaymentMethod: 63,
//   CustomerNumber: 'cusnumb123',
//   Customer: { Id: 300000156284427 },
//   IsFromBillbeeApi: true,
// };

export type Order = {
  Id: string;
  OrderNumber: string;
  //   State: number;
  //   VatMode: number;
  CreatedAt: string; //'2023-11-01T07:14:55.129Z',
  //   ShippedAt: string; //'2023-11-01T07:14:55.129Z',
  //   ConfirmedAt: string; //'2023-11-01T07:14:55.129Z',
  PayedAt: string; //'2023-11-01T07:14:55.129Z',
  SellerComment: string;
  InvoiceDate: string; //'2023-11-01T07:14:55.129Z',
  InvoiceAddress: OrderAddress;
  ShippingAddress: OrderAddress;
  PaymentMethod: number; // 63
  ShippingCost: number;
  TotalCost: number;
  OrderItems: OrderItem[];
  Currency: string;
  //   UpdatedAt: string; //'2023-11-01T07:14:55.129Z';
  //   TaxRate1: number;
  //   TaxRate2: number;
  //   BillBeeOrderId: number;
  //   BillBeeParentOrderId: number;
  //   VatId: string;
  //   Tags: string[];
  //   ShipWeightKg: number;
  //   LanguageCode: string;
  PaidAmount: number;
  //   PaymentTransactionId: string;
  //   DistributionCenter: string;
  //   DeliverySourceCountryCode: string;
  //   CustomInvoiceNote: string;
  CustomerNumber: string;
  //   PaymentReference: string;
  Customer: Customer;
  //   Payments: Payment[];
  IsFromBillbeeApi: boolean;
  Tags?: string[];
  State?: number;
};

export const example: Order = {
  // "RebateDifference": 0,
  // "ShippingIds": [
  //   {
  //     "BillbeeId": 0,
  //     "ShippingId": "string",
  //     "Shipper": "string",
  //     "Created": "2023-11-01T07:14:55.129Z",
  //     "TrackingUrl": "string",
  //     "ShippingProviderId": 0,
  //     "ShippingProviderProductId": 0,
  //     "ShippingCarrier": 0,
  //     "ShipmentType": 0
  //   }
  // ],
  // "AcceptLossOfReturnRight": true,
  Id: 'string',
  OrderNumber: 'string',
  State: 1,
  //   VatMode: 0,
  CreatedAt: '2023-11-01T07:14:55.129Z',
  //   ShippedAt: '2023-11-01T07:14:55.129Z',
  //   ConfirmedAt: '2023-11-01T07:14:55.129Z',
  PayedAt: '2023-11-01T07:14:55.129Z',
  SellerComment: 'string',
  // "Comments": [
  //   {
  //     "Id": 0,
  //     "FromCustomer": true,
  //     "Text": "string",
  //     "Name": "string",
  //     "Created": "2023-11-01T07:14:55.129Z"
  //   }
  // ],
  //   InvoiceNumberPrefix: 'string',
  //   InvoiceNumberPostfix: 'string',
  //   InvoiceNumber: 0,
  InvoiceDate: '2023-11-01T07:14:55.129Z',
  InvoiceAddress: {
    // BillbeeId: 0,
    FirstName: 'string',
    LastName: 'string',
    // Company: 'string',
    // NameAddition: 'string',
    Street: 'string',
    HouseNumber: 'string',
    Zip: 'string',
    City: 'string',
    CountryISO2: 'string',
    Country: 'string',
    // Line2: 'string',
    // Email: 'string',
    // State: 'string',
    // Phone: 'string',
  },
  ShippingAddress: {
    // BillbeeId: 0,
    FirstName: 'string',
    LastName: 'string',
    // Company: 'string',
    // NameAddition: 'string',
    Street: 'string',
    HouseNumber: 'string',
    Zip: 'string',
    City: 'string',
    CountryISO2: 'string',
    Country: 'string',
    // Line2: 'string',
    // Email: 'string',
    // State: 'string',
    // Phone: 'string',
  },
  PaymentMethod: 1,
  ShippingCost: 0,
  TotalCost: 0,
  //   AdjustmentCost: 0,
  //   AdjustmentReason: 'string',
  OrderItems: [
    {
      //   BillbeeId: 0,
      //   TransactionId: 'string',
      Product: {
        // OldId: 'string',
        Id: 'string',
        Title: 'string',
        Weight: 0,
        SKU: 'string',
        // SkuOrId: 'string',
        // IsDigital: true,
        // Images: [
        //   {
        //     Url: 'string',
        //     IsDefaultImage: true,
        //     Position: 0,
        //     ExternalId: 'string',
        //   },
        // ],
        // EAN: 'string',
        PlatformData: 'string',
        // TARICCode: 'string',
        // CountryOfOrigin: 'string',
        // BillbeeId: 0,
        // Type: 0,
      },
      Quantity: 0,
      TotalPrice: 0,
      //   TaxAmount: 0,
      //   TaxIndex: 0,
      //   Discount: 0,
      //   Attributes: [
      //     {
      //       Id: 'string',
      //       Name: 'string',
      //       Value: 'string',
      //       Price: 0,
      //     },
      //   ],
      //   GetPriceFromArticleIfAny: true,
      //   IsCoupon: true,
      ShippingProfileId: 'string',
      //   DontAdjustStock: true,
      //   UnrebatedTotalPrice: 0,
      //   SerialNumber: 'string',
      //   InvoiceSKU: 'string',
    },
  ],
  Currency: 'string',
  //   Seller: {
  //     Platform: 'string',
  //     BillbeeShopName: 'string',
  //     BillbeeShopId: 0,
  //     Id: 'string',
  //     Nick: 'string',
  //     FirstName: 'string',
  //     LastName: 'string',
  //     FullName: 'string',
  //     Email: 'string',
  //   },
  //   Buyer: {
  //     Platform: 'string',
  //     BillbeeShopName: 'string',
  //     BillbeeShopId: 0,
  //     Id: 'string',
  //     Nick: 'string',
  //     FirstName: 'string',
  //     LastName: 'string',
  //     FullName: 'string',
  //     Email: 'string',
  //   },
  //   UpdatedAt: '2023-11-01T07:14:55.129Z',
  //   TaxRate1: 0,
  //   TaxRate2: 0,
  //   BillBeeOrderId: 0,
  //   BillBeeParentOrderId: 0,
  //   VatId: 'string',
  Tags: ['string'],
  //   ShipWeightKg: 0,
  //   LanguageCode: 'string',
  PaidAmount: 0,
  //   ShippingProfileId: 'string',
  //   ShippingProviderId: 0,
  //   ShippingProviderProductId: 0,
  //   ShippingProviderName: 'string',
  //   ShippingProviderProductName: 'string',
  //   ShippingProfileName: 'string',
  //   PaymentInstruction: 'string',
  //   IsCancelationFor: 'string',
  //   PaymentTransactionId: 'string',
  //   DistributionCenter: 'string',
  //   DeliverySourceCountryCode: 'string',
  //   CustomInvoiceNote: 'string',
  CustomerNumber: 'string',
  //   PaymentReference: 'string',
  //   ShippingServices: [
  //     {
  //       DisplayName: 'string',
  //       DisplayValue: 'string',
  //       RequiresUserInput: true,
  //       ServiceName: 'string',
  //       typeName: 'string',
  //       PossibleValueLists: [
  //         {
  //           key: 'string',
  //           value: [
  //             {
  //               key: 0,
  //               value: 'string',
  //             },
  //           ],
  //         },
  //       ],
  //       CanBeConfigured: true,
  //     },
  //   ],
  Customer: {
    Id: 0,
    // Name: 'string',
    // Email: 'string',
    // Tel1: 'string',
    // Tel2: 'string',
    // Number: 0,
    // PriceGroupId: 0,
    // LanguageId: 0,
    // DefaultMailAddress: {
    //   Id: 0,
    //   TypeId: 0,
    //   TypeName: 'string',
    //   SubType: 'string',
    //   Value: 'string',
    // },
    // DefaultCommercialMailAddress: {
    //   Id: 0,
    //   TypeId: 0,
    //   TypeName: 'string',
    //   SubType: 'string',
    //   Value: 'string',
    // },
    // DefaultStatusUpdatesMailAddress: {
    //   Id: 0,
    //   TypeId: 0,
    //   TypeName: 'string',
    //   SubType: 'string',
    //   Value: 'string',
    // },
    // DefaultPhone1: {
    //   Id: 0,
    //   TypeId: 0,
    //   TypeName: 'string',
    //   SubType: 'string',
    //   Value: 'string',
    // },
    // DefaultPhone2: {
    //   Id: 0,
    //   TypeId: 0,
    //   TypeName: 'string',
    //   SubType: 'string',
    //   Value: 'string',
    // },
    // DefaultFax: {
    //   Id: 0,
    //   TypeId: 0,
    //   TypeName: 'string',
    //   SubType: 'string',
    //   Value: 'string',
    // },
    // VatId: 'string',
    // Type: 0,
    // MetaData: [
    //   {
    //     Id: 0,
    //     TypeId: 0,
    //     TypeName: 'string',
    //     SubType: 'string',
    //     Value: 'string',
    //   },
    // ],
    // ArchivedAt: '2023-11-01T07:14:55.129Z',
    // RestoredAt: '2023-11-01T07:14:55.129Z',
  },
  //   History: [
  //     {
  //       Created: '2023-11-01T07:14:55.129Z',
  //       EventTypeName: 'string',
  //       Text: 'string',
  //       EmployeeName: 'string',
  //       TypeId: 0,
  //     },
  //   ],
  //   Payments: [
  //     {
  //       //   BillbeeId: 0,
  //       //   TransactionId: 'string',
  //       //   PayDate: '2023-11-01T07:14:55.129Z',
  //       //   PaymentType: 0,
  //       //   SourceTechnology: 'string',
  //       //   SourceText: 'string',
  //       //   PayValue: 0,
  //       //   Purpose: 'string',
  //       Name: 'string',
  //     },
  //   ],
  //   LastModifiedAt: '2023-11-01T07:14:55.129Z',
  //   ArchivedAt: '2023-11-01T07:14:55.129Z',
  //   RestoredAt: '2023-11-01T07:14:55.129Z',
  //   ApiAccountId: 0,
  //   ApiAccountName: 'string',
  //   MerchantVatId: 'string',
  //   CustomerVatId: 'string',
  IsFromBillbeeApi: true,
  //   WebUrl: 'string',
};
